import { Image, Text, View } from '@react-pdf/renderer'
import InvoiceTableHeader from './invoiceTableHeader'
import InvoiceTableRow from './invoiceTableRow'
// import InvoiceTableRow from './tablerow'
import { getTotalQty } from 'components/other/calculation'
import { HIDE_PRICES } from '../utils'
import { has_multiple_units } from '../export_condition'
import InvoiceHsnTable from '../invoiceHsnTable'
import InvoiceTableBlankSpace from './invoiceTableBlankSpace'
import InvoiceTableFooter from './invoiceTableFooter'
import { styles as getStyles } from './styles'

const tableRowsCount = 7

const InvoiceItemsTable = ({ invoice, type }) => {
  var styles = getStyles(invoice)

  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  let currency_code = invoice.company.currency_code
  const is_export = invoice.is_export == 1
  if (invoice.is_export == 1 || invoice.is_multi_currency == 1) {
    if (invoice.invoice_settings.show_both_currencies != true) {
      currency_symbol = invoice.export_details[0].currency_symbol
      conversion_factor = invoice.export_details[0].conversion_factor
    }
    currency_code = invoice.export_details[0].currency_code
  }
  const has_multiple_unit_products = has_multiple_units(invoice.items)
  const show_3_decimals = invoice.invoice_settings.qty_decimals_3 == 1
  return (
    <>
      <View
        style={{
          ...styles.invoiceItemsTable_tableContainer,
          ...(invoice.invoice_settings.full_page == true &&
            styles.invoiceItemsTable_tableContainerGrow),
        }}
      >
        <InvoiceTableHeader invoice={invoice} type={type} />
        <InvoiceTableRow items={invoice.items} data={invoice} invoice={invoice} type={type} />
        <View style={invoice.invoice_settings.full_page == true && { flexGrow: 1 }}>
          <InvoiceTableBlankSpace
            rowsCount={tableRowsCount - invoice.items.length}
            items={invoice.tax}
            netAmount={invoice.net_amount}
            invoice={invoice}
            total={invoice.total}
            type={type}
          />
        </View>

        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <>
            {invoice.document_type != 'estimates' && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
          </>
        )}
      </View>

      <View style={invoice.invoice_settings.full_page == false && styles.globalContainer}>
        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <View>
            {invoice.document_type != 'estimates' && (
              <View>
                <Text style={styles.invoiceItemsTable_amountinwords}>
                  Amount Chargeable (in words):
                  {'\u00A0'}
                  {invoice.total_amount_in_words}. E & O.E
                </Text>
              </View>
            )}

            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <View>
                <Text style={styles.invoiceItemsTable_amountinwords}>
                  Total amount (in words):
                  {'\u00A0'}
                  {invoice.total_amount_in_words}. E & O.E
                </Text>
              </View>
            )}
          </View>
        )}

        {invoice.invoice_settings.show_hsn_details == 1 &&
          invoice.is_export == 0 &&
          invoice.is_multi_currency == 0 &&
          invoice.with_tax == 1 &&
          invoice.hide_totals == 0 &&
          type != 'Delivery Challan' &&
          type != HIDE_PRICES && <InvoiceHsnTable invoice={invoice} type={type} />}

        {(type == 'Delivery Challan' || type == HIDE_PRICES) &&
          !has_multiple_unit_products &&
          invoice.invoice_settings.hide_qty == 0 && (
            <View style={styles.invoiceItemsTable_borderTop}>
              <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
                Total Items / Qty : {invoice.items.length} /{' '}
                {getTotalQty(invoice.items).toLocaleString('en-IN', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: show_3_decimals ? 3 : 0,
                })}{' '}
              </Text>
            </View>
          )}

        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <View style={styles.invoiceItemsTable_borderTop}>
            {(invoice.document_type == 'sales' || invoice.document_type == 'pos') &&
              invoice.amount_pending > 0 && (
                <>
                  <View style={styles.invoiceItemsTable_block}>
                    <Text style={styles.invoiceItemsTable_labels}>Amount Payable:</Text>
                    <Text style={styles.invoiceItemsTable_info}>
                      {currency_symbol}
                      {Number(
                        (invoice.amount_pending / conversion_factor).toFixed(2),
                      ).toLocaleString('en-IN', options)}
                    </Text>
                  </View>

                  {invoice.amount_paid > 0 &&
                    invoice.amount_paid < invoice.total[0].amount &&
                    invoice.is_tds != 1 && (
                      <View style={styles.invoiceItemsTable_block}>
                        <Text style={styles.invoiceItemsTable_labels}>Amount Paid:</Text>
                        <Text style={styles.invoiceItemsTable_info}>
                          {currency_symbol}
                          {'\u00A0'}
                          {Number(
                            (invoice.amount_paid / conversion_factor).toFixed(2),
                          ).toLocaleString('en-IN', options)}
                        </Text>
                      </View>
                    )}
                </>
              )}

            {/* Amount Fully PAID */}
            {(invoice.document_type == 'sales' ||
              invoice.document_type == 'purchases' ||
              invoice.document_type == 'sales_returns' ||
              invoice.document_type == 'purchase_returns') &&
              invoice.amount_paid == invoice.total[0].amount && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginHorizontal: 'auto',
                    marginRight: 0,
                    marginBottom: 1.8,
                  }}
                >
                  <Image
                    style={styles.invoiceItemsTable_image}
                    src={{
                      uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/checkmark.png',
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    crossorigin="anonymous"
                  />
                  <Text
                    style={[styles.invoiceItemsTable_amountPaid, styles.invoiceItemsTable_bold]}
                  >
                    Amount Paid
                  </Text>
                </View>
              )}

            {/* Show Payments */}
            {invoice.invoice_settings.show_payments != 0 &&
              invoice.payments != '' &&
              invoice.payments.map(
                (item, i) =>
                  item.payment_mode != 'TDS' && (
                    <View key={i} style={styles.invoiceItemsTable_block}>
                      <Text style={styles.invoiceItemsTable_info}></Text>
                      <Text style={styles.invoiceItemsTable_payments}>
                        <Text>
                          <Text style={[styles.invoiceItemsTable_bold]}>
                            {currency_symbol}
                            {item.settled_amount / conversion_factor} Paid via {item.payment_mode}{' '}
                            on {item.payment_date}
                            {'\n'}
                          </Text>
                          <Text style={{ color: '#343435', fontSize: 8.1 }}>
                            {item.notes != '' && ' (' + item.notes + ')'}
                          </Text>
                        </Text>
                      </Text>
                    </View>
                  ),
              )}

            {/* Show Net Balance */}
            {invoice.is_export == 0 &&
              invoice.is_multi_currency == 0 &&
              invoice.invoice_settings.show_net_balance != 0 &&
              invoice.customer.balance + invoice.customer.opening_balance < 0 && (
                <View style={[styles.invoiceItemsTable_balance]}>
                  {/*<View style={styles.invoiceItemsTable_block}>
                  <Text style={styles.invoiceItemsTable_labels2}>Previous dues:</Text>
                  <Text style={{ ...styles.invoiceItemsTable_info2 }}>
                    {invoice.customer.balance +
                      invoice.customer.opening_balance +
                      invoice.amount_pending >=
                    0
                      ? 'Nil'
                      : currency_symbol +
                        '\u00A0' +
                        Math.abs(
                          (invoice.customer.balance +
                            invoice.customer.opening_balance +
                            invoice.amount_pending) /
                            conversion_factor,
                        )
                          .toFixed(2)
                          .toLocaleString('en-IN', options)}
                  </Text>
                </View>*/}
                  {/*<View style={styles.invoiceItemsTable_block}>
                  <Text style={styles.invoiceItemsTable_labels2}>Current due:</Text>
                  <Text style={{ ...styles.invoiceItemsTable_info2 }}>
                    {invoice.amount_pending == 0
                      ? 'Nil'
                      : currency_symbol +
                        '\u00A0' +
                        (invoice.amount_pending / conversion_factor)
                          .toFixed(2)
                          .toLocaleString('en-IN', options)}
                  </Text>
                </View>*/}
                  <View style={styles.invoiceItemsTable_block}>
                    <View style={styles.invoiceItemsTable_borderBlock}></View>
                    <View
                      style={{
                        ...styles.invoiceItemsTable_borderBlock2,
                        borderTopWidth: 0.5,
                      }}
                    ></View>
                  </View>
                  <View style={styles.invoiceItemsTable_block}>
                    <Text style={styles.invoiceItemsTable_labels2}>Total Balance due:</Text>
                    <Text style={styles.invoiceItemsTable_info2}>
                      {currency_symbol}
                      {'\u00A0'}
                      {Math.abs(
                        (
                          (invoice.customer.balance + invoice.customer.opening_balance) /
                          conversion_factor
                        ).toFixed(2),
                      ).toLocaleString('en-IN', options)}
                    </Text>
                  </View>
                </View>
              )}
          </View>
        )}
      </View>
    </>
  )
}

export default InvoiceItemsTable
