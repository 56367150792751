import { Button, Card, Drawer, Form, Input, InputNumber, Select, Space, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import { getPincodeDetails } from 'components/other/utility'
import { ChangeEvent, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { getCountryInfo } from 'redux/countries/actions'
import { State } from 'redux/types'
import { getAPI } from 'services/jwt'
import store from 'store'

const { Option } = Select

type Address = {
  line1?: string
  line2?: string
  addr_id: number
  state?: string
  country?: string
}

type PartyDetails = {
  party_id: number
  party_name: string
  party_type: string
  is_party_edit: boolean
}

type AddressFormProps = {
  onFinish: (values: any, addressType: string, addr_id: number, isexportCustomer: boolean) => void
}
const AddressForm = forwardRef((props: AddressFormProps, ref) => {
  const countries = useSelector((state: State) => state.document.countries)
  const states = useSelector((state: State) => state.document.states)
  const country_info = useSelector((state: State) => state.countries.info)
  const user = useSelector((state: State) => state.user)
  const dispatch = useDispatch()

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const formAdressRef = useRef<any | null>()

  const subscriptionPlanRef = useRef<any>()

  const [loading, setLoading] = useState(false)
  const [addressDetails, setAddressDetails] = useState({
    addr_id: -1,
  })
  const [addressType, setAddressType] = useState('Shipping')
  const [visible, setVisible] = useState(false)
  const onCloseAdressDrawer = () => {
    setAddressDetails({ addr_id: -1 })
    setVisible(false)
  }
  const [isexportCustomer, setIsExportCustomer] = useState(false)
  const [selected_country_code, setSelectedCountryCode] = useState(
    user?.selectedCompany?.country_code,
  )
  const [partyDetails, setPartyDetails] = useState({
    party_id: -1,
    party_name: '',
    party_type: '',
    is_party_edit: false,
  })
  const getCustomerDetails = async (party_id: number, party_type: string) => {
    if (!party_id) return
    try {
      const data = await getAPI(`v2/${party_type}`, 'get_details', { id: party_id })
      if (data) {
        setPartyDetails(prevDetails => ({
          ...prevDetails,
          party_name:
            party_type == 'customer'
              ? data.customer_details[0]?.name
              : data.vendor_details[0]?.name,
        }))
      }
    } catch (error) {
      return null
    }
  }

  const showAddressDrawer = (
    address_type: string,
    selectedAddress: Address,
    party_details: PartyDetails,
  ) => {
    const current_country =
      selectedAddress.addr_id == -1 ? user?.selectedCompany?.country : selectedAddress.country
    const address = {
      ...selectedAddress,
      state1: selectedAddress.state ? selectedAddress.state : undefined,
      country: current_country,
    }

    const userSelectedCountry = user?.selectedCompany?.country
    let isExportCustomer = false
    if (userSelectedCountry && current_country) {
      isExportCustomer = userSelectedCountry != current_country && store.get('is_export') == 1
    }

    setAddressDetails(address)
    setPartyDetails(party_details)
    setAddressType(address_type)
    setVisible(true)
    setIsExportCustomer(isExportCustomer)

    // load states according to selected country
    var filteredCountry = countries?.filter(item => item.name == current_country)
    var filteredCountryCode = user?.selectedCompany?.country_code
    if (filteredCountry.length > 0) {
      filteredCountryCode = filteredCountry[0].code
    }
    setSelectedCountryCode(filteredCountryCode)
    if (filteredCountryCode) {
      dispatch(
        getCountryInfo({
          country_code: filteredCountryCode,
        }),
      )
    }

    if (party_details.party_id !== -1) {
      getCustomerDetails(party_details.party_id, party_details.party_type)
    }
  }

  const onClose = () => {
    setAddressDetails({ addr_id: -1 })
    setVisible(false)
    setLoading(false)
    setIsExportCustomer(false)
  }

  useImperativeHandle(ref, () => ({
    showAddressDrawer,
    onClose,
  }))

  const onWritingPincode = async (value: string | null) => {
    if (selected_country_code != 'IN') return

    const [success, data] = (await getPincodeDetails(value, states, countries)) as [boolean, any]

    if (success) {
      formAdressRef?.current?.setFieldsValue({
        state1: data.state,
        city: data.city,
        country: data.country,
      })
    }
  }

  const onSelectCountry = (countryCode: string) => {
    if (countryCode != selected_country_code) {
      formAdressRef.current?.setFieldsValue({
        state1: undefined,
      })
    }

    var payload = {
      country_code: countryCode,
    }

    const userCountryCode = user?.selectedCompany?.country_code
    let isExportCustomer = false
    if (userCountryCode && countryCode) {
      isExportCustomer = userCountryCode != countryCode && store.get('is_export') == 1
    }
    setIsExportCustomer(isExportCustomer)
    if (user?.selectedCompany?.country_code == 'IN') {
      if (user?.selectedCompany?.country_code != countryCode) {
        formAdressRef.current?.setFieldsValue({
          state1: '97-OTHER TERRITORY',
        })
      }
    } else {
      dispatch(getCountryInfo(payload))
    }
    setSelectedCountryCode(countryCode)
  }

  const AddressValidation = async (values: any) => {
    if (values.state1 == undefined && !isexportCustomer) {
      message.error('State is Required')
      setLoading(false)
      return false
    }
    if (values.line1 == undefined || values.line1 == '') {
      message.error('Address Line 1 is Required')
      setLoading(false)
      return false
    }

    return true
  }

  const onAddressFinish = async (values: any) => {
    setLoading(true)

    if (!AddressValidation(values)) {
      return
    }
    let pattern = /^\d{6}$/

    if (selected_country_code == 'IN' && values.pincode && !pattern.test(values.pincode)) {
      message.error('Pincode should be 6 digits number')
      setLoading(false)
      return
    }

    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key] === undefined) {
        values[key] = ''
      }
    }
    values = { ...values, state: values.state1 }

    if (partyDetails.is_party_edit) {
      let isOldAddress = addressDetails.addr_id >= 0

      let req = {
        addr_id: addressDetails.addr_id,
        export_customer: isexportCustomer,
        ...values,
      }

      let API_ROUTE = ''
      let API_ACTION = ''

      if (partyDetails.party_type == 'customer') {
        API_ROUTE = 'v2/customer'
        req['customer_id'] = partyDetails.party_id
      } else {
        API_ROUTE = 'v2/vendor'
        req['vendor_id'] = partyDetails.party_id
      }

      API_ACTION = isOldAddress
        ? addressType === 'billing'
          ? 'edit_billing_address'
          : 'edit_shipping_address'
        : addressType === 'billing'
        ? 'add_billing_address'
        : 'add_shipping_address'

      var data = await getAPI(API_ROUTE, API_ACTION, req)
      if (data && data.success) {
        message.success(data.message)
        props.onFinish(
          { ...values, state: values.state1 },
          addressType,
          isOldAddress ? addressDetails.addr_id : data.addr_id,
          isexportCustomer,
        )
      }
    } else {
      props.onFinish(
        { ...values, state: values.state1 },
        addressType,
        addressDetails.addr_id,
        isexportCustomer,
      )
    }

    onClose()
  }

  return (
    <Drawer
      title={
        <span>
          <span className="lh-normal">
            <span className="font-weight-bolder mb-0">
              {addressDetails.addr_id !== -1 ? 'Update' : 'Add'}{' '}
              <span className="capitalize">{addressType}</span> Address
            </span>
          </span>

          {partyDetails.party_name && (
            <p className="font-size-16 text-muted font-weight-medium mt-0 mb-0 lh-normal">
              <span>{partyDetails.party_name}</span>
            </p>
          )}
        </span>
      }
      width={isMobile ? '100%' : '50%'}
      height={isMobile ? '90%' : '100%'}
      placement={isMobile ? 'top' : 'right'}
      maskClosable={false}
      closable={true}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      onClose={onCloseAdressDrawer}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      destroyOnClose={true}
      extra={
        <Space>
          <Button
            onClick={() => formAdressRef.current?.submit()}
            type="primary"
            loading={loading}
            className="font-weight-bold"
          >
            Save and Update
          </Button>
        </Space>
      }
      footer={
        <>
          <Button
            onClick={() => formAdressRef.current?.submit()}
            type="primary"
            loading={loading}
            className="font-weight-bold"
          >
            Save and Update
          </Button>
        </>
      }
    >
      <Form
        layout="vertical"
        {...formItemLayout}
        labelAlign="left"
        ref={formAdressRef}
        name="customerForm"
        onFinish={values => {
          onAddressFinish(values)
        }}
        initialValues={{
          ...addressDetails,
        }}
      >
        <Card bordered={false} className="border-radius-small mb-4">
          <div>
            <Form.Item name={'country'} label="Country" className="pb-2">
              <Select
                showSearch
                placeholder="Select Country"
                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                optionFilterProp="children"
                style={{ width: '100%' }}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSelect={(value, option) => {
                  onSelectCountry(option.code)
                }}
                value={selected_country_code}
              >
                {countries?.map((item, i) => (
                  <Option key={i} value={item.name} code={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {addressType == 'shipping' && (
              <Form.Item
                name={'title'}
                label="Title"
                extra={
                  formAdressRef.current?.getFieldsValue()['company_name'] != '' && (
                    <span
                      onClick={() => {
                        formAdressRef?.current?.setFieldsValue({
                          title: formAdressRef.current?.getFieldsValue()['company_name'],
                        })
                      }}
                      className="cursor-pointer pl-2"
                    >
                      Auto fill company name
                    </span>
                  )
                }
              >
                <Input placeholder="Title" />
              </Form.Item>
            )}

            <Form.Item
              name={'line1'}
              label="Address Line 1"
              className=""
              rules={[{ required: true, message: 'Fill Address Line 1' }]}
            >
              <Input placeholder="Address Line 1" />
            </Form.Item>

            <Form.Item name={'line2'} label="Address Line 2">
              <Input placeholder="Address Line 2" />
            </Form.Item>

            <Form.Item name={'pincode'} label={user?.selectedCompany?.labels?.pincode}>
              {selected_country_code == 'IN' ? (
                <InputNumber
                  placeholder={user?.selectedCompany?.labels?.pincode}
                  style={{ width: '100%' }}
                  maxLength={6}
                  onChange={onWritingPincode}
                />
              ) : (
                <Input
                  type="text"
                  placeholder={user?.selectedCompany?.labels?.pincode}
                  maxLength={20}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onWritingPincode(event.target.value)
                  }
                />
              )}
            </Form.Item>

            <Form.Item name={'city'} label={'City'}>
              <Input placeholder={isexportCustomer ? 'eg. San Fransisco, California' : 'City'} />
            </Form.Item>

            <Form.Item
              name={'state1'}
              label={user?.selectedCompany?.labels?.state}
              extra={
                addressType == 'billing' && selected_country_code == 'IN'
                  ? 'Billing State (like 36-Telangana) is responsible for deciding CGST + SGST/UTGST or IGST calculation on the invoice. Please ignore this, if you do not have GST.'
                  : ''
              }
              rules={[
                {
                  required: selected_country_code == 'IN',
                  message: user?.selectedCompany?.labels?.state + ' is required',
                },
              ]}
            >
              <Select
                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                showSearch
                placeholder={`Select ${user?.selectedCompany?.labels?.state}`}
                optionFilterProp="children"
                style={{ width: '100%' }}
                filterOption={(input: any, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={user?.selectedCompany?.country_code == 'IN' && isexportCustomer}
              >
                {selected_country_code &&
                  country_info?.[selected_country_code]?.states?.map((item, i) => (
                    <Option key={i} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {addressType == 'shipping' && (
              <Form.Item label="Notes" name={'notes'}>
                <TextArea
                  rows={4}
                  placeholder="Add Notes, Contact Person Details,etc..."
                  maxLength={200}
                />
              </Form.Item>
            )}
          </div>
        </Card>
      </Form>

      <SubscriptionPlanModal ref={subscriptionPlanRef} />
    </Drawer>
  )
})
export default AddressForm
