import { Document, Font, Image, Page, View } from '@react-pdf/renderer'
import { registerFont } from 'components/fonts'
import { get_invoice_title, get_invoice_type } from '../export_condition'
import BillTo from './billTo'
import CompanyDetails from './company'
import InvoiceBankDetails from './invoiceBankDetails'
import InvoiceItemsTable from './invoiceItemsTable'
import InvoiceNotes from './invoiceNotes'
import InvoiceTitle from './invoiceTitle'
import { styles as getStyles } from './styles'
import InvoiceFooter from './invoiceFooter'
import Banner from '../banner'

export default function Invoice({ invoice }) {
  const hyphenationCallback = word => {
    return word.split('')
  }

  registerFont(invoice)

  Font.registerHyphenationCallback(hyphenationCallback)

  var styles = getStyles(invoice)

  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <Document
      title={`${invoice.serial_number}_${invoice.company.company_name}`}
      author={invoice.company.company_name}
      creator={invoice.company.company_name}
      producer={invoice.company.company_name}
      pdfVersion="1.7"
    >
      {invoice.type.map((item, index) => (
        <Page
          orientation={invoice.invoice_settings.pdf_orientation}
          wrap
          size={invoice.invoice_settings.paper_size}
          key={index}
          style={{
            ...styles.index_page,
            paddingTop:
              24 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
            paddingBottom:
              48 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            paddingLeft: !!invoice.invoice_settings.margin_left
              ? invoice.invoice_settings.margin_left
              : 10,
            paddingRight: !!invoice.invoice_settings.margin_right
              ? invoice.invoice_settings.margin_right
              : 10,
          }}
        >
          {invoice.invoice_settings.invoice_header_image != '' && (
            <View style={styles.index_brandingLogo}>
              <Image
                style={styles.index_banner}
                src={{
                  uri: invoice.invoice_settings.invoice_header_image,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </View>
          )}

          <InvoiceTitle
            title={get_invoice_title(
              invoice.document_type,
              invoice.document_title,
              invoice.with_tax,
              invoice.company.gstin,
              item,
              invoice,
            )}
            type={get_invoice_type(invoice.document_type, item)}
            color={invoice.company.color}
            invoice={invoice}
          />
          <CompanyDetails invoice={invoice} type={item} />
          <BillTo invoice={invoice} />
          <Banner invoice={invoice} count={1} />
          <InvoiceItemsTable invoice={invoice} type={item} />
          <Banner invoice={invoice} count={2} />
          <InvoiceBankDetails invoice={invoice} type={item} />
          <InvoiceNotes invoice={invoice} type={item} />
          <InvoiceFooter invoice={invoice} />
        </Page>
      ))}
    </Document>
  )
}
