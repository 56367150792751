import { SET_LEAD_OPTIONS, SET_LEAD_OPTIONS_LOADING } from './types'

const initialState = {
  agentsList: [],
  leadStatusList: [],
  intrestedInList: [],
  campaignsList: [],
  languagesList: [],
  resourcesList: [],
  featureScreenList: [],
  businessTypesList: [],
  isLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LEAD_OPTIONS:
      return {
        ...state,
        agentsList: action.payload.leads_agents,
        leadStatusList: action.payload.leads_status,
        intrestedInList: action.payload.leads_features,
        campaignsList: action.payload.leads_campaigns,
        languagesList: action.payload.languages,
        resourcesList: action.payload.leads_resources,
        featureScreenList: action.payload.feature_screen,
        businessTypesList: action.payload.business_types,
        isLoading: false,
      }
    case SET_LEAD_OPTIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}
