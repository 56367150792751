import { Text, View } from '@react-pdf/renderer'
import { HIDE_PRICES } from '../utils'
import { styles as getStyles } from './styles'

const borderColor = '#142328'

const InvoiceTableHeader = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const hide_hsn = invoice.invoice_settings.hide_hsn
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 8.2 : 9
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.7 : no_of_default_cols
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 5.8
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 0.8 : no_of_with_out_tax_cols
  let no_of_with_out_tax_cols_gst = invoice.invoice_settings.hide_qty ? 5 : 6
  no_of_with_out_tax_cols_gst = hide_hsn
    ? no_of_with_out_tax_cols_gst - 1
    : no_of_with_out_tax_cols_gst
  //discount column
  const show_discount_col =
    invoice?.invoice_settings?.hide_discount === 0 &&
    invoice?.invoice_settings?.show_discount_col === 1
  no_of_default_cols = show_discount_col ? no_of_default_cols + 1 : no_of_default_cols
  no_of_with_out_tax_cols = show_discount_col
    ? no_of_with_out_tax_cols + 1
    : no_of_with_out_tax_cols
  no_of_with_out_tax_cols_gst = show_discount_col
    ? no_of_with_out_tax_cols_gst + 1
    : no_of_with_out_tax_cols_gst

  var dc_col =
    (1 * 100) / ((invoice.invoice_settings.hide_qty ? 2 : 3) + invoice.custom_col_names.length)
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var single_col3 = (1 * 100) / (no_of_with_out_tax_cols_gst + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 8)) / (9 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (10 * 15)) / (15 + invoice.custom_col_names.length)
  const column_labels = invoice.column_labels

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View style={styles.invoiceTableHeader_container}>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                width: `${dc_col * 0.3}%`,
              }}
            >
              {column_labels.id}
            </Text>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                width: `${dc_col * 1.7}%`,
              }}
            >
              {column_labels.product_name}
            </Text>
            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  width: `${dc_col}%`,
                }}
              >
                {item}
              </Text>
            ))}
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  borderRight: 'none',
                  paddingRight: 4.5,
                  textAlign: 'right',
                  width: `${dc_col}%`,
                }}
              >
                Qty
              </Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {invoice.company.gstin.length == 15 ? (
            <>
              {invoice.with_tax == 0 ? (
                <View
                  style={{
                    ...styles.invoiceTableHeader_container,
                    fontSize: font_size2,
                    borderBottomColor: '#1d1d1f',
                  }}
                >
                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'left',
                      width: `${0.2 * single_col2}%`,
                    }}
                  >
                    {column_labels.id}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'left',
                      width: `${2 * single_col2}%`,
                    }}
                  >
                    {column_labels.product_name}
                  </Text>

                  {!hide_hsn && (
                    <Text
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${0.8 * single_col2}%`,
                      }}
                    >
                      {invoice.invoice_settings.labels.hsn_code}
                    </Text>
                  )}

                  {invoice.custom_col_names.map((item, index) => (
                    <Text
                      key={index}
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${single_col2}%`,
                      }}
                    >
                      {item}
                    </Text>
                  ))}

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      width: `${single_col2}%`,
                    }}
                  >
                    {column_labels.unit_price}
                  </Text>
                  {show_discount_col && (
                    <Text
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${single_col2}%`,
                      }}
                    >
                      {'Disc (%)'}
                    </Text>
                  )}

                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${0.8 * single_col2}%`,
                      }}
                    >
                      {column_labels.qty}
                    </Text>
                  )}

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      paddingRight: 4.5,
                      width: `${single_col2}%`,
                    }}
                  >
                    {column_labels.total_amount}
                  </Text>
                </View>
              ) : (
                <View
                  style={{
                    ...styles.invoiceTableHeader_container,
                    fontSize: font_size,
                    borderBottomColor: '#1d1d1f',
                  }}
                >
                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'left',
                      width: `${0.4 * single_col}%`,
                    }}
                  >
                    {column_labels.id}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'left',
                      width: `${2.5 * single_col}%`,
                    }}
                  >
                    {column_labels.product_name}
                  </Text>

                  {invoice.custom_col_names.map((item, index) => (
                    <Text
                      key={index}
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${single_col}%`,
                      }}
                    >
                      {item}
                    </Text>
                  ))}

                  {!hide_hsn && (
                    <Text
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${0.7 * single_col}%`,
                      }}
                    >
                      {invoice.invoice_settings.labels.hsn_code}
                    </Text>
                  )}

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      width: `${1.5 * single_col}%`,
                    }}
                  >
                    {column_labels.unit_price}
                  </Text>
                  {show_discount_col && (
                    <Text
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${single_col}%`,
                      }}
                    >
                      {'Disc (%)'}
                    </Text>
                  )}

                  {invoice.invoice_settings.hide_qty == 0 && (
                    <Text
                      style={{
                        ...styles.invoiceTableHeader_cell,
                        textAlign: 'right',
                        width: `${0.8 * single_col}%`,
                      }}
                    >
                      {column_labels.qty}
                    </Text>
                  )}

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      width: `${single_col}%`,
                    }}
                  >
                    {column_labels.net_amount}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      width: `${1.1 * single_col}%`,
                    }}
                  >
                    {column_labels.tax_amount}
                  </Text>

                  <Text
                    style={{
                      ...styles.invoiceTableHeader_cell,
                      textAlign: 'right',
                      paddingRight: 4.5,
                      width: `${single_col}%`,
                    }}
                  >
                    {column_labels.total_amount}
                  </Text>
                </View>
              )}
            </>
          ) : (
            <View
              style={{
                ...styles.invoiceTableHeader_container,
                fontSize: font_size2,
                borderBottomColor: '#1d1d1f',
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${0.2 * single_col3}%`,
                }}
              >
                {column_labels.id}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${1.8 * single_col3}%`,
                }}
              >
                {column_labels.product_name}
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    width: `${single_col3}%`,
                    textAlign: 'right',
                    fontSize: font_size2,
                  }}
                >
                  {item}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  {invoice.invoice_settings.labels.hsn_code}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  fontSize: font_size2,
                  width: `${single_col3}%`,
                }}
              >
                {column_labels.unit_price}
              </Text>
              {show_discount_col && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  {'Disc (%)'}
                </Text>
              )}

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    textAlign: 'right',
                    fontSize: font_size2,
                    width: `${single_col3}%`,
                  }}
                >
                  {column_labels.qty}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  fontSize: font_size2,
                  width: `${single_col3}%`,
                }}
              >
                {column_labels.total_amount}
              </Text>
            </View>
          )}
        </>
      )}
    </>
  )
  return <View fixed>{rows}</View>
}

export default InvoiceTableHeader
