import { Text, View, Image } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../utils'
import { styles as getStyles } from './styles'
import RichText from '../RichText'
import ItemBatchDetails from '../itemBatchDetails'

const DescriptionRow = ({ invoice, type, item, locale }) => {
  var styles = getStyles(invoice)
  const invoice_settings = invoice.invoice_settings
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 8 : 9
  let no_of_dc_cols = invoice.invoice_settings.hide_qty ? 2 : 3
  let no_of_with_out_tax_cols = invoice.invoice_settings.hide_qty ? 5 : 6
  const hide_hsn = invoice.invoice_settings.hide_hsn
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.8 : no_of_default_cols
  no_of_with_out_tax_cols = hide_hsn ? no_of_with_out_tax_cols - 0.8 : no_of_with_out_tax_cols

  //discount column
  const show_discount_col =
    invoice?.invoice_settings?.hide_discount === 0 &&
    invoice?.invoice_settings?.show_discount_col === 1
  no_of_default_cols = show_discount_col ? no_of_default_cols + 1 : no_of_default_cols
  no_of_with_out_tax_cols = show_discount_col
    ? no_of_with_out_tax_cols + 1
    : no_of_with_out_tax_cols

  var FONT_FACTOR = invoice.invoice_settings.font_size
  var single_col = ((1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)).toFixed(2)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var dc_col = (1 * 100) / (no_of_dc_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 9)) / (10 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (16 + invoice.custom_col_names.length)

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View style={[styles.invoiceTableRow_row]}>
            <Text
              style={{
                ...styles.invoiceTableDescriptionRow_cell,
                width: `${0.2 * dc_col}%`,
              }}
            ></Text>
            <View
              style={{
                ...styles.invoiceTableDescriptionRow_cell,
                textAlign: 'left',
                width: `${1.8 * dc_col}%`,
              }}
            >
              <ItemBatchDetails item={item} invoice={invoice} />

              {item.description != '' &&
                item.description &&
                invoice.show_description &&
                item.description != '<p><br></p>' && (
                  <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                )}

              {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  {item.product_images.map((image, index) => (
                    <Image
                      style={styles.invoiceTableRow_image}
                      src={{
                        uri: image.url,
                        method: 'GET',
                        headers: {
                          'Cache-Control': 'no-cache',
                        },
                      }}
                      crossorigin="anonymous"
                    />
                  ))}
                </View>
              )}
            </View>
            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  width: `${dc_col}%`,
                }}
              ></Text>
            ))}
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  ...styles.invoiceTableHeader_med,
                  width: `${1 * dc_col}%`,
                  borderRight: 'none',
                }}
              ></Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View style={[styles.invoiceTableRow_row]}>
              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${0.2 * single_col2}%`,
                }}
              >
                {''}
              </Text>

              <View
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  textAlign: 'left',
                  fontSize: font_size2,
                  width: `${2 * single_col2}%`,
                }}
              >
                <ItemBatchDetails item={item} invoice={invoice} />

                {item.description != '' &&
                  item.description &&
                  invoice.show_description &&
                  item.description != '<p><br></p>' && (
                    <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                  )}

                {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    {item.product_images.map((image, index) => (
                      <Image
                        style={styles.invoiceTableRow_image}
                        src={{
                          uri: image.url,
                          method: 'GET',
                          headers: {
                            'Cache-Control': 'no-cache',
                          },
                        }}
                        crossorigin="anonymous"
                      />
                    ))}
                  </View>
                )}
              </View>

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${0.8 * single_col2}%`,
                  }}
                ></Text>
              )}

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${single_col2}%`,
                  }}
                ></Text>
              ))}

              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  width: `${single_col2}%`,
                }}
              ></Text>
              {show_discount_col && (
                <Text
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${single_col2}%`,
                  }}
                ></Text>
              )}

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${single_col2}%`,
                  }}
                ></Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  borderRight: 'none',
                  width: `${single_col2}%`,
                }}
              ></Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View style={[styles.invoiceTableRow_row]}>
              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  width: `${0.4 * single_col}%`,
                }}
              ></Text>

              <View
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  fontSize: font_size,
                  textAlign: 'left',
                  width: `${2.6 * single_col}%`,
                }}
              >
                <ItemBatchDetails item={item} invoice={invoice} />

                {item.description != '' &&
                  item.description &&
                  invoice.show_description &&
                  item.description != '<p><br></p>' && (
                    <RichText locale={locale} note={item.description} FONT_FACTOR={font_size} />
                  )}

                {invoice.invoice_settings.show_images == 1 && item.product_images[0].is_empty == 0 && (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    {item.product_images.map((image, index) => (
                      <Image
                        style={styles.invoiceTableRow_image}
                        src={{
                          uri: image.url,
                          method: 'GET',
                          headers: {
                            'Cache-Control': 'no-cache',
                          },
                        }}
                        crossorigin="anonymous"
                      />
                    ))}
                  </View>
                )}
              </View>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${single_col}%`,
                  }}
                ></Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${0.8 * single_col}%`,
                  }}
                ></Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  width: `${1.2 * single_col}%`,
                }}
              ></Text>
              {show_discount_col && (
                <Text
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${single_col}%`,
                  }}
                ></Text>
              )}

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableDescriptionRow_cell,
                    width: `${0.9 * single_col}%`,
                  }}
                ></Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  width: `${single_col}%`,
                }}
              ></Text>

              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  textAlign: 'right',
                  width: `${1.1 * single_col}%`,
                }}
              ></Text>

              <Text
                style={{
                  ...styles.invoiceTableDescriptionRow_cell,
                  borderRight: 'none',
                  width: `${single_col}%`,
                }}
              ></Text>
            </View>
          )}
        </>
      )}
    </>
  )
  return <Fragment>{rows}</Fragment>
}

export default DescriptionRow
