import React, { Fragment } from 'react'
import { Text, View, Image, Font } from '@react-pdf/renderer'
import { extraChargesAmount, getTotalQty } from 'components/other/calculation'
import { has_multiple_units } from '../export_condition'
import RichText from '../RichText'
import { styles as getStyles } from './styles'

const InvoiceTableFooter = ({ items, total, netAmount, invoice }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const tax_options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  const hide_hsn = invoice.invoice_settings.hide_hsn
  const hide_discount = invoice.invoice_settings.hide_discount
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  const show_3_decimals = invoice.invoice_settings.qty_decimals_3 == 1

  const has_multiple_unit_products = has_multiple_units(invoice.items)

  let rows = items.map(
    (item, i) =>
      invoice.company.gstin.length == 15 &&
      item.tax_amount != 0 &&
      invoice.with_tax != 0 && (
        <View key={i} style={styles.invoiceTableFooter_row}>
          <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
            {invoice.rcm == 1 && '*'}
            {item.tax_type}
            {'\u00A0'}
            {item.tax_percent}
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {currency_symbol}
            {(item.tax_amount / conversion_factor).toLocaleString('en-IN', tax_options)}
          </Text>
        </View>
      ),
  )
  {
    invoice.cess_amount - invoice.cess_on_qty_value > 0 &&
      invoice.company.gstin.length == 15 &&
      invoice.with_tax != 0 &&
      rows.push(
        <View style={styles.invoiceTableFooter_row}>
          <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
            Cess
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {currency_symbol}
            {Number(
              ((invoice.cess_amount - invoice.cess_on_qty_value) / conversion_factor).toFixed(2),
            ).toLocaleString('en-IN', options)}
          </Text>
        </View>,
      )
  }

  {
    invoice.cess_on_qty_value > 0 &&
      invoice.company.gstin.length == 15 &&
      invoice.with_tax != 0 &&
      rows.push(
        <View style={styles.invoiceTableFooter_row}>
          <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
            Cess on Qty
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {currency_symbol}
            {Number((invoice.cess_on_qty_value / conversion_factor).toFixed(2)).toLocaleString(
              'en-IN',
              options,
            )}
          </Text>
        </View>,
      )
  }
  return (
    <Fragment>
      <View
        wrap={false}
        style={invoice.invoice_settings.full_page == false && styles.globalContainer}
      >
        <View style={styles.invoiceTableFooter_row3}>
          <Text style={{ fontSize: 7.5, textAlign: 'left', width: '30%', paddingLeft: 4.5 }}>
            {!has_multiple_unit_products && invoice.invoice_settings.hide_qty == 0 && (
              <>
                {' '}
                Total Items / Qty : {invoice.items.length} /{' '}
                {parseFloat(getTotalQty(invoice.items)).toLocaleString('en-IN', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: show_3_decimals ? 3 : 0,
                })}{' '}
              </>
            )}
          </Text>
        </View>

        {invoice.document_custom_additional_charges.map((item, index) => (
          <>
            {item.total_amount != 0 && (
              <View style={styles.invoiceTableFooter_row}>
                <Text
                  style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}
                >
                  {item['name']}
                  {'\n'}
                  {item['sac_code'] != '' && !hide_hsn && (
                    <View>
                      <RichText
                        locale={locale}
                        FONT_FACTOR={0.8 * 8.5 * FONT_FACTOR}
                        note={'SAC: ' + item['sac_code']}
                      />
                    </View>
                  )}
                </Text>
                <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                  {item.type == -1 ? '(-) ' : ''}
                  {currency_symbol}
                  {Number(item.net_amount / conversion_factor).toFixed(2)}
                </Text>
              </View>
            )}
          </>
        ))}

        {invoice.company.gstin.length == 15 &&
          invoice.with_tax != 0 &&
          invoice.invoice_settings.hide_taxable_amount == 0 && (
            <>
              <View style={styles.invoiceTableFooter_row}>
                <Text
                  style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}
                >
                  Taxable Amount
                </Text>
                <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                  {currency_symbol}
                  {Number(
                    (
                      (netAmount +
                        extraChargesAmount(
                          invoice.document_custom_additional_charges,
                          'net_amount',
                        )) /
                      conversion_factor
                    ).toFixed(2),
                  ).toLocaleString('en-IN', options)}
                </Text>
              </View>
            </>
          )}

        {rows}
        {/* has_extra_charges */}

        {invoice.extra_discount != 0 && invoice.extra_discount != null && hide_discount != 1 && (
          <View style={styles.invoiceTableFooter_row}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              Discount
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              -{currency_symbol}
              {'\u00A0'}
              {Number(invoice.extra_discount.toFixed(2)).toLocaleString('en-IN', options)}
            </Text>
          </View>
        )}

        {invoice.roundoff == 1 &&
          invoice.roundoff_value != 0 &&
          invoice.is_export == 0 &&
          invoice.is_multi_currency == 0 &&
          invoice.invoice_settings.show_round_off == 1 && (
            <View style={styles.invoiceTableFooter_row}>
              <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
                Round Off
              </Text>
              <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                {(-1 * Number(invoice.roundoff_value.toFixed(2))).toLocaleString('en-IN', options)}
              </Text>
            </View>
          )}
        {invoice.is_tcs == 1 && (
          <View style={styles.invoiceTableFooter_row}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              TCS @ {invoice.tcs_details[0].tax}% {invoice.tcs_details[0].section}
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              {'\u20B9'}
              {'\u00A0'}
              {invoice.tcs_details[0].tcs_amount}
            </Text>
          </View>
        )}

        <View style={styles.invoiceTableFooter_block}></View>
        {/*<View style={styles.invoiceTableFooter_block2}></View>*/}

        <View style={styles.invoiceTableFooter_row1}>
          <Text
            style={[
              styles.invoiceTableFooter_description,
              styles.invoiceTableFooter_bold,
              { fontSize: '12px' },
            ]}
          >
            {total[0].name}
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {currency_symbol}
            {Number((total[0].amount / conversion_factor).toFixed(2)).toLocaleString(
              'en-IN',
              options,
            )}
          </Text>
        </View>
        {(invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
          invoice.invoice_settings.show_both_currencies == true && (
            <View style={styles.invoiceTableFooter_row3}>
              <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
                {total[0].name}({invoice.export_details[0].currency_code})
              </Text>
              <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
                {invoice.export_details[0].currency_symbol}
                {Number(
                  (total[0].amount / invoice.export_details[0].conversion_factor).toFixed(2),
                ).toLocaleString('en-IN', options)}
              </Text>
            </View>
          )}

        {invoice.total_discount != 0 && invoice.total_discount != null && hide_discount != 1 && (
          <View style={styles.invoiceTableFooter_row3}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              Total Discount
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              {currency_symbol}
              {Number((invoice.total_discount / conversion_factor).toFixed(2)).toLocaleString(
                'en-IN',
                options,
              )}
            </Text>
          </View>
        )}

        {invoice.is_tds == 1 && (
          <View style={styles.invoiceTableFooter_row3}>
            <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
              TDS @ {invoice.tds_details[0].tax}% {invoice.tds_details[0].section}
            </Text>
            <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
              {'\u20B9'}
              {'\u00A0'}
              {invoice.tds_details[0].tds_amount}
            </Text>
          </View>
        )}
      </View>
    </Fragment>
  )
}

export default InvoiceTableFooter
