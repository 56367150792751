import { Text, View } from '@react-pdf/renderer'
import { getTotalQty } from 'components/other/calculation'
import React, { Fragment } from 'react'
import { has_multiple_units } from '../export_condition'
import { styles as getStyles } from './styles'

const InvoiceTableFooter = ({ items, total, netAmount, invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 7.1 : 8

  const show_discount_col =
    invoice?.invoice_settings?.hide_discount === 0 &&
    invoice?.invoice_settings?.show_discount_col === 1
  no_of_default_cols = show_discount_col ? no_of_default_cols + 1 : no_of_default_cols

  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9.9 * 8)) / (8 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (15 + invoice.custom_col_names.length)
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }

  const has_multiple_unit_products = has_multiple_units(invoice.items)
  const show_3_decimals = invoice.invoice_settings.qty_decimals_3 == 1

  return (
    <Fragment>
      {type != 'Delivery Challan' && (
        <View
          style={{
            ...styles.invoiceTableFooter_row,
            ...(invoice.invoice_settings.full_page == false && styles.globalContainer),
            fontSize: font_size,
            borderBottomColor: '#1d1d1f',
          }}
        >
          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              textAlign: 'left',
              fontSize: font_size,
              width: `${0.3 * single_col}%`,
            }}
          >
            {'  '}
          </Text>

          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              ...styles.invoiceTableFooter_italic,
              fontSize: font_size,
              textAlign: 'right',
              width: `${3.1 * single_col}%`,
            }}
          >
            {' Total'}
          </Text>

          {invoice.custom_col_names.map((item, index) => (
            <Text
              key={index}
              style={{
                ...styles.invoiceTableFooter_cell,
                width: `${single_col}%`,
                fontSize: font_size,
              }}
            >
              {'  '}
            </Text>
          ))}

          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${1.2 * single_col}%`,
            }}
          >
            {'  '}
          </Text>

          {!has_multiple_unit_products
            ? invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableFooter_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {parseFloat(getTotalQty(invoice.items)).toLocaleString('en-IN', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: show_3_decimals ? 3 : 0,
                  })}
                </Text>
              )
            : invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableFooter_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${single_col}%`,
            }}
          >
            {'  '}
          </Text>
          {show_discount_col && (
            <Text
              style={{
                ...styles.invoiceTableFooter_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${single_col}%`,
              }}
            >
              {'  '}
            </Text>
          )}

          {/* <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${0 * single_col}%`,
            }}
          >
            {'  '}
          </Text>*/}

          {/*  <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              fontSize: font_size,
              textAlign: 'right',
              width: `${1.1 * single_col}%`,
            }}
          >
            {'  '}
          </Text>*/}

          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              ...styles.invoiceTableFooter_bold,
              textAlign: 'right',
              paddingRight: 4.5,
              borderRight: 'none',
              fontSize: font_size,
              width: `${1.5 * single_col}%`,
            }}
          >
            {' '}
            {currency_symbol}
            {Number((total[0].amount / conversion_factor).toFixed(2)).toLocaleString(
              'en-IN',
              options,
            )}
            {'\n'}
            {invoice.is_export == 1 && invoice.invoice_settings.show_both_currencies == true && (
              <>
                <Text
                  style={{
                    ...styles.invoiceTableFooter_cell,
                    ...styles.invoiceTableFooter_bold,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    borderRight: 'none',
                    fontSize: font_size,
                    width: `${1.5 * single_col}%`,
                  }}
                >
                  {invoice.export_details[0].currency_symbol}
                  {Number(
                    (total[0].amount / invoice.export_details[0].conversion_factor).toFixed(2),
                  ).toLocaleString('en-IN', options)}
                </Text>
              </>
            )}
          </Text>
        </View>
      )}
      {invoice.is_tds == 1 && (
        <View
          style={[
            styles.invoiceTableFooter_row3,
            invoice.invoice_settings.full_page == false && styles.globalContainer,
          ]}
        >
          <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
            TDS @ {invoice.tds_details[0].tax}% {invoice.tds_details[0].section}
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {'\u20B9'}
            {'\u00A0'}
            {invoice.tds_details[0].tds_amount}
          </Text>
        </View>
      )}
      {/* {invoice.is_tcs == 1 && (
        <View style={styles.invoiceTableFooter_row3}>
          <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
            TCS @ {invoice.tcs_details[0].tax}% {invoice.tcs_details[0].section}
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {'\u20B9'}
            {'\u00A0'}
            {invoice.tcs_details[0].tcs_amount}
          </Text>
        </View>
      )} */}
    </Fragment>
  )
}

export default InvoiceTableFooter
