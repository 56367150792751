import { GoogleOAuthProvider } from '@react-oauth/google'
import BingAd from 'components/BingAdSignUpScript'
import NProgress from 'nprogress'
import queryString from 'query-string'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import store from 'store'
import ReloadIfOldVisit from '../components/ReloadIfOldVisit'
import SoftwareSuggest from '../components/SoftwareSuggest'
import TokenListener from '../components/TokenListener'
import AgentLayout from './Agents'
import AuthLayout from './Auth'
import FeatureLayout from './Feature'
import MainLayout from './Main'
import OnlineStoreLayout from './Onlinestore'
import PaymentLinkLayout from './PaymentLink'
import Plain from './Plain'
import PublicLayout from './Public'

const clientId = '616020831931-pil0omm0mnjfucpri40kqel2eg0lktj0.apps.googleusercontent.com'
const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  onlinestore: OnlineStoreLayout,
  paymentlink: PaymentLinkLayout,
  feature: FeatureLayout,
  agents: AgentLayout,
  plain: Plain,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  var isPublic = false
  var isAgent = false
  // Layout Rendering

  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/\/packingList\//.test(pathname)) {
      return 'plain'
    }
    if (/^\/swipeteam(?=\/|$)/i.test(pathname)) {
      isAgent = true
      return 'agents'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    if (/^\/store(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    // if (/^\/notfound(?=\/|$)/i.test(pathname)) {
    //   return 'onlinestore'
    // }
    // if (/^\/user(?=\/|$)/i.test(pathname)) {
    //   return 'public'
    // }
    if (/^\/pricing(?=\/|$)/i.test(pathname)) {
      return 'onlinestore'
    }
    if (/^\/reports(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/view(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/l\/view(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/l$/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/l\/create\/payments(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/privacy(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/terms(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    if (/^\/refund(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }

    if (/^\/vyapar_import(?=\/|$)/i.test(pathname)) {
      return 'plain'
    }
    if (/^\/bulk_import(?=\/|$)/i.test(pathname)) {
      return 'plain'
    }
    if (/^\/pos(?=\/|$)/i.test(pathname)) {
      return 'plain'
    }
    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return 'plain'
    }
    if (/^\/create(?=\/|$)/i.test(pathname)) {
      return 'feature'
    }
    if (/^\/duplicate(?=\/|$)/i.test(pathname)) {
      return 'feature'
    }
    if (/^\/create2(?=\/|$)/i.test(pathname)) {
      return 'feature'
    }
    if (/^\/edit(?=\/|$)/i.test(pathname)) {
      return 'feature'
    }
    if (/^\/convert(?=\/|$)/i.test(pathname)) {
      return 'feature'
    }
    // for drive shared files
    if (/^\/share\/drive(?=\/|$)/i.test(pathname)) {
      isPublic = true
      return 'public'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'
  const isAgentAuthorized = store.get('agentAccessKey') != null
  // console.log(isAgent)

  const BootstrappedLayout = () => {
    // If public layout render public layout
    if (isPublic) {
      return <Container>{children}</Container>
    }
    if ((isAgent && isAgentAuthorized) || (isAgent && pathname.includes('/swipeteam/auth/login'))) {
      return (
        <Container>
          {children}
          <TokenListener />
        </Container>
      )
    }
    if (isAgent && !isAgentAuthorized) {
      return <Redirect to="/swipeteam/auth/login" />
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }

    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      if (/^\/auth\/gmail_login(?=\/|$)/i.test(pathname)) {
        return <Redirect to="/auth/gmail_login" />
      }
      const redirectParam =
        currentPath?.length > 1 ? '?' + queryString.stringify({ redirect: currentPath }) : ''
      return <Redirect to={'/auth/login' + redirectParam} />
    }
    // in other case render previously set layout
    return (
      <Container>
        {children}
        <TokenListener />
        <SoftwareSuggest />
        <BingAd />
        <ReloadIfOldVisit />
      </Container>
    )
  }

  return (
    <Fragment>
      <Helmet titleTemplate="%s" title="Home" />
      <GoogleOAuthProvider clientId={clientId}>{BootstrappedLayout()}</GoogleOAuthProvider>
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
