import React, { useState, useRef } from 'react'
import { Modal, Button, Form, Input, message, ButtonType, Checkbox } from 'antd'
import { getAPI } from 'services/jwt'
import BizBadge from 'components/badges/biz'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import store from 'store'
import { useSelector } from 'react-redux'
import { ADMIN } from 'utils/constants'

interface AddPriceListProps {
  type?: ButtonType
  block?: boolean
  icon?: React.ReactNode
  buttonText?: string
  buttonClassName?: string
  textClassName?: string
  slimButton?: boolean
  onPriceListAdded?: () => void
}

const AddPriceList: React.FC<AddPriceListProps> = ({
  type,
  block,
  icon,
  buttonText,
  buttonClassName,
  textClassName,
  slimButton,
  onPriceListAdded,
}) => {
  const user = useSelector((state: any) => state.user)
  const permissions = useSelector((state: any) => state.permissions)
  const is_branch = user?.selectedCompany?.is_branch
  const is_admin = permissions?.permission?.role == ADMIN
  const default_visibility = permissions?.productSettings?.price_list_visibility ?? false

  const [loading, setLoading] = useState<boolean>(false)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [priceListName, setPriceListName] = useState('')
  const [visibility, setVisibility] = useState<boolean>(default_visibility)
  const formRef = useRef<any>(null)
  const subscriptionPlanRef = useRef<any>(null)

  const addPriceList = async (priceListName: string) => {
    setLoading(true)
    const data = await getAPI('product', 'add_price_list', {
      price_list_name: priceListName,
      visibility,
    })
    if (data && data.success) {
      message.success('Price List added')
      handleClose()
      if (onPriceListAdded) {
        onPriceListAdded()
      }
    }
    setLoading(false)
  }

  const handleClick = () => {
    if (store.get('price_list') === 1) {
      addPriceList(priceListName)
    } else {
      subscriptionPlanRef.current.openModal('price_list')
    }
  }

  const handleClose = () => {
    setShowAddModal(false)
    formRef.current.resetFields()
    setPriceListName('')
    setVisibility(false)
  }

  return (
    <>
      <SubscriptionPlanModal ref={subscriptionPlanRef} />

      {slimButton ? (
        <a onClick={() => setShowAddModal(true)} className="font-weight-bold mr-2">
          <i className="fa fa-plus-circle mr-2" />
          Price List
          {store.get('price_list') === 0 && <BizBadge />}
        </a>
      ) : (
        <Button
          type={type}
          onClick={() => setShowAddModal(true)}
          className={buttonClassName}
          block={block}
          icon={icon}
        >
          <span className={textClassName}>{buttonText}</span>
          {store.get('price_list') === 0 && <BizBadge />}
        </Button>
      )}

      <Modal
        open={showAddModal}
        footer={
          <div className="flex justify-between items-center">
            {is_branch && is_admin && (
              <Checkbox defaultChecked={visibility} onChange={e => setVisibility(e.target.checked)}>
                Add in all branches
              </Checkbox>
            )}
            <Button type="primary" onClick={handleClick} loading={loading}>
              Add Price List
              {store.get('price_list') === 0 && <BizBadge />}
            </Button>
          </div>
        }
        onCancel={handleClose}
      >
        <Form labelAlign="left" ref={formRef} name="productForm" layout="vertical">
          <Form.Item
            name="price_list_name"
            label="Name"
            rules={[{ required: true, message: 'Please fill price list name' }]}
          >
            <Input
              placeholder="Enter Price List Name"
              autoFocus
              onChange={e => setPriceListName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

AddPriceList.defaultProps = {
  type: 'primary',
  block: false,
  icon: null,
  buttonText: 'Add Price List',
  buttonClassName: 'mb-5',
  textClassName: '',
  slimButton: false,
  onPriceListAdded: () => {},
}

export default AddPriceList
