interface Props {
  iconClass: string
  message: string
  onClickHandler: () => void
}

export default function({ iconClass, message, onClickHandler }: Props) {
  return (
    <div
      className="p-2 bg-yellow-100 border rounded-md hover:bg-yellow-200 cursor-pointer"
      onClick={onClickHandler}
    >
      <i className={`${iconClass} mr-2`}></i>
      {message}
    </div>
  )
}
