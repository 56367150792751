import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Plan',
  'topBar.profileMenu.startDate': 'Start Date',
  'topBar.profileMenu.endDate': 'End Date',
  'topBar.profileMenu.validity': 'Plan Validity',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Settings',
  'topBar.profileMenu.quickSupport': 'Quick support apps',
  'topBar.profileMenu.logout': 'Logout',

  'menuLeft.sales': 'Invoices',
  'menuLeft.invoice': 'Sales',
  'menuLeft.invoices': 'Invoices',
  'menuLeft.purchases': 'Purchases',
  'menuLeft.purchase_orders': 'Purchase Orders',
  'menuLeft.estimates': 'Quotations',
  'menuLeft.delivery_challans': 'Delivery Challans',
  'menuLeft.pro_forma_invoices': 'Pro Forma Invoices',
  'menuLeft.sales_returns': 'Credit Notes',
  'menuLeft.invoices': 'Invoices',
  'menuLeft.purchase_returns': 'Debit Notes',
  'menuLeft.gstr2b': 'GSTR-2B',
  'menuLeft.payments': 'Payments',
  'menuLeft.payments_timeline': 'Timeline',
  'menuLeft.journals': 'Journals',
  'menuLeft.payment_links': 'Payment Links',
  'menuLeft.bank_reconciliation': 'Bank Reconciliation',
  'menuLeft.inventory': 'Inventory',
  'menuLeft.products': 'Products & Services',
  'menuLeft.vendors': 'Vendors',
  'menuLeft.customers': 'Customers',
  'menuLeft.expenses': 'Expenses',
  'menuLeft.expenses_menu': 'Expenses+',
  'menuLeft.indirect_income': 'Indirect Income',
  'menuLeft.invite_users': 'Add Users',
  'menuLeft.analytics': 'Insights',
  'menuLeft.sales_summary': 'Sales Summary',
  'menuLeft.reports': 'Reports',
  'menuLeft.onlineorders': 'OnlineStore',
  'menuLeft.settings': 'Settings',
  'menuLeft.tutorials': 'Tutorials',
  'menuLeft.ewaybills': 'E-way Bills',
  'menuLeft.ledgers': 'Ledgers',
  'menuLeft.settlements': 'Settlements',
  'menuLeft.subscriptions': 'Subscriptions',
  'menuLeft.campaigns': 'Campaigns',
  'menuLeft.mydrive': 'My Drive',
  'menuLeft.einvoices': 'E-Invoices',
  'menuLeft.warehouses': 'Warehouses',
  'menuLeft.inventory_timeline': 'Timeline',
  'menuLeft.coupons': 'Coupons',
  'menuLeft.packing_lists': 'Packing Lists',
  'menuLeft.b2b_recon': 'GSTR-2B',

  'table.id': 'Id #',
  'table.shopifyOrderNumber': 'Shopify Order Number',
  'table.item': 'Item',
  'table.quantity': 'Qty',
  'table.amount': 'Amount',
  'table.status': 'Status',
  'table.type': 'Type',
  'table.createdDate': 'Date/Created Time',
  'table.send': 'Send',
  'table.action': 'Actions',
  'table.customer': 'Customer',
  'table.category': 'Category',
  'table.vendor': 'Vendor',
  'table.description': 'Description',
  'table.expenseId': 'Expense #',
  'table.mode': 'Mode',
  'table.sellingPrice': 'Selling Price (Disc %)',
  'table.purchasePrice': 'Purchase Price',
  'table.salePrice': 'Sale Price',
  'table.tax': 'Tax (%)',
  'table.product': 'Product',
  'table.price': 'Price',
  'table.priceWithTax': 'Price With Tax',
  'table.variant_name': 'Variant Name',
  'table.discount': 'Disc (%)',
  'table.name': 'Name',
  'table.barcode': 'Barcode',
  'table.showOnline': 'Show Online',
  'table.image': 'Image',
  'table.order': 'Order',
  'table.date': 'Date',
  'table.createdTime': 'Created Time',
  'table.closingBalance': 'Closing Balance',
  'table.lastUpdated': 'Last Updated',
  'table.actions': 'Actions',
  'table.remainingQuantity': 'Remaining Quantity',
  'table.expiryDate': 'Expiry Date',
  'table.stock': 'Stock',
  'table.stockIn': 'Stock In',
  'table.stockOut': 'Stock Out',
  'table.remarks': 'Remarks',
  'table.date': 'Date',
  'table.invoice': 'Invoice #',
  'table.bill': 'Bill #',
  'table.amountPaid': 'Amount Paid',
  'table.amountPending': 'Amount Pending',
  'table.createdBy': 'Created By',
  'table.transactionType': 'Transaction Type',
  'table.saleQty': 'Sale Qty',
  'table.saleAmount': 'Sale Amount',
  'table.purchaseAmount': 'Purchase Amount',
  'table.netProfit': 'Net Profit',
  'table.hsnCode': 'HSN Code',
  'table.netAmount': 'Net Amount',
  'table.netQty': 'Net Qty',
  'table.igst': 'IGST',
  'table.cgst': 'CGST',
  'table.sgst': 'SGST',
  'table.utgst': 'UTGST',
  'table.gstIn': 'GST IN',
  'table.state': 'State',
  'table.credit': 'Credit',
  'table.debit': 'Debit',
  'table.contactInfo': 'Contact Info',
  'table.noOfCredits': 'No. Of Credits',
  'table.count': 'No. Of Invoices',
  'table.bank': 'Bank',
  'table.partyName': 'Party Name',
  'table.documentType': 'Document Type',
  'table.youGave': 'You Gave',
  'table.youReceived': 'You Received',
  'table.bankDetails': 'Bank Details',
  'table.signatureName': 'Signature Name',
  'table.subscriptionId': 'Subscription Id',
  'table.endDate': 'End Date',
  'table.upcoming': 'Upcoming',
  'table.createdFrom': 'Created From',
  'table.lastCreated': 'Last Created',
  'table.startDate': 'Start Date',
  'table.paymentId': 'Payment Id',
  'table.source': 'Source',
  'table.batch': 'Batch Number',
  'table.pendingAmount': 'Pending Amount',

  'button.download': 'Download Data',
  'button.downloads': 'Download',
  'button.createInvoice': 'Create Invoice',
  'button.createExpense': 'Create Expense',
  'button.createIncome': 'Add Income',
  'button.createJournal': 'New Journal',
  'button.sales': 'Create Invoice',
  'button.invoice': 'Invoice',
  'button.sales_returns': 'Create Sales Return/Credit Note',
  'button.purchases': 'Create Purchase',
  'button.purchase_orders': 'Create Purchase Order',
  'button.purchase_returns': 'Create Purchase Return/Debit Note',
  'button.estimates': 'Create Quotation',
  'button.pro_forma_invoices': 'Create Pro Forma Invoice',
  'button.delivery_challans': 'Create Delivery Challan',
  'button.expense_report': 'Expense Report',
  'button.income_report': 'Income Report',
  'button.documentSettings': 'Document Settings',
  'button.settings': 'Settings',
  'button.goToPos': 'POS Billing',
  'button.variants': 'Variants',
  'button.batches': 'Batches',
  'button.bulkUpload': 'Bulk Upload Items',
  'button.downloadItems': 'Download Items',
  'button.addPriceList': 'Pricelists',
  'button.addNewItem': 'New Item',
  'button.addNewProduct': 'New Product',
  'button.actions': 'Actions',
  'button.confirm': 'Confirm',
  'button.cancel': 'Cancel',
  'button.close': 'Close',
  'button.view': 'View',
  'button.send': 'Send',
  'button.restore': 'Restore',
  'button.recordPayment': 'Record Payment',
  'button.deliveryChallan': 'Delivery Challan',
  'button.thermalPrint': 'Thermal Print',
  'button.shippingLabel': 'Shipping Label',
  'button.edit': 'Edit',
  'button.youGave': 'You Gave',
  'button.youGot': 'You Got',
  'button.copyLink': 'Copy Link',
  'button.share': 'Share',
  'button.pdf': 'Download as PDF',
  'button.excel': 'Download as Excel',
  'button.export': 'Export Excel',
  'button.duplicate': 'Duplicate',
  'button.settlements': 'Settlements',
  'button.paymentTimeline': 'Payments Timeline',
  'button.addNewCustomer': 'New Customer',
  'button.addNewVendor': 'New Vendor',
  'button.stockIn': 'Stock In',
  'button.stockOut': 'Stock Out',
  'button.Low Stock': 'Low Stock',
  'button.Positive Stock': 'Positive Stock',
  'button.Stock Value Sales Price': 'Stock Value Sales Price',
  'button.Stock Value with Purchase Price': 'Stock Value With Purchase Price',
  'button.bulkUploadStockIn': 'Bulk Upload Stock In',
  'button.bulkUploadStockOut': 'Bulk Upload Stock Out',
  'button.downloadStockReport': 'Download Stock Report',
  'button.back': 'Back',
  'button.exportToTallyExcel': 'Export to Tally',
  'button.gstr1Report': 'GSTR1 Excel',
  'button.gstr1JSON': 'GSTR1 JSON',
  'button.salesReport': 'Sales Report',
  'button.purchaseReport': 'Purchase Report',
  'button.quotationReport': 'Quotation Report',
  'button.downloadExcel': 'Download Excel',
  'button.downloadLowstockPDF': 'Download Low Stock PDF',
  'button.downloadLowStockReport': 'Download low stock report',
  'button.asPDF': 'As PDF',
  'button.asExcel': 'As Excel',
  'button.downloadTemplate': 'Download Template',
  'button.downloadCustomers': 'Download Customers',
  'button.downloadVendors': 'Download Vendors',
  'button.import': 'Import',
  'button.sendSms': 'Send SMS',
  'button.recharge': 'Recharge',
  'button.moreInfo': 'More Info',
  'button.visitStore': 'Visit Store',
  'button.downloadOrders': 'Download Orders',
  'button.storeSettings': 'Online Store Settings',
  'button.enableNotifications': 'Enable Notifications',
  'button.pending': 'pending',
  'button.completed': 'completed',
  'button.accepted': 'accepted',
  'button.cancelled': 'cancelled',
  'button.viewInvoice': 'Invoice',
  'button.complete': 'Complete',
  'button.accept': 'Accept',
  'button.convertToSale': 'Convert to Sale',
  'button.ewaybill': 'Connect to E-way Bill Portal',
  'button.einvoicing': 'Connect to E-Invoicing Portal',
  'button.ledger': 'Ledger',
  'button.save&update': 'Save & Update',
  'button.save&close': 'Save & Close',
  'button.save': 'Save',
  'button.upload': 'Upload',
  'button.delete': 'Delete',
  'button.copytoShipping': 'Copy to Shipping',
  'button.shippingAddress': 'Shipping Address',
  'button.billingAddress': 'Billing Address',
  'button.addNewBankDetails': 'New Bank Details',
  'button.shareBankDetails': 'Share Bank Details',
  'button.updateSettings': 'Update Settings',
  'button.addColumn': 'Add Column',
  'button.addNewSignature': 'Add New Signature',
  'button.reports': 'Reports',
  'button.delete': 'Delete',
  'button.deleteAll': 'Delete All',
  'button.convert': 'Convert to',
  'button.createSubscription': 'Create Subscription',
  'button.subscriptions': 'Create Subscription',
  'button.createCampaign': 'Create Campaign',
  'button.addReminder': 'Add Reminder',
  'button.bom': 'Create Group',
  'button.batches': 'Batches',
  'button.packingLists': 'Create Packing List',

  'text.debit': 'Debit',
  'text.credit': 'Credit',
  'text.name': 'Name',
  'text.phone': 'Phone',
  'text.email': 'Email',
  'text.gstin': 'GSTIN',
  'text.company': 'Company',
  'text.billingAddress': 'Billing Address',
  'text.notes': 'Notes',
  'text.openingBalance': 'Opening Balance',
  'text.vendorPaysYou': 'Vendor Pays You',
  'text.youPayTheVendor': 'You pay the Vendor',
  'text.basicDetails': 'Basic Details',
  'text.companyDetails': 'Company Details',
  'text.optionalDetails': 'Optional Details',
  'text.balance': 'Balance',
  'text.optional': 'Optional',
  'text.withTax': 'incl. tax',
  'text.withoutTax': 'excl. tax',
  'text.productStockDetails': 'Product Stock Details',
  'text.noTransactions': 'No Transactions Available for selected Item',
  'text.selectItem': 'Select Item to get the report',
  'text.noTransaction': 'No Transactions',
  'text.addCustomer': 'Add a Customer Now',
  'text.addVendor': 'Add a Vendor Now',
  'text.totalSales': 'Total Sales',
  'text.totalPurchases': 'Total Purchases',
  'text.totalExpenses': 'Total Expenses',
  'text.totalIncome': 'Total Income',
  'text.income': 'Income',
  'text.noSales': 'No Sales',
  'text.purchases': 'Purchases',
  'text.expenses': 'Expenses',
  'text.income': 'Indirect Income',
  'text.noPurchases': 'No Purchases',
  'text.noExpenses': 'No Expenses',
  'text.noIncome': 'No Indirect Income',
  'text.total': 'Total',
  'text.netBalance': 'Net Balance',
  'text.invoiceThemes': 'Invoice Templates',
  'text.otpSentText': 'OTP will be sent to email as well.',
  'text.sameAsBilling': 'Same as Billing Address',
  'text.bankText': 'Create dynamic QR codes on Invoices at',
  'text.bankText1': 'Transaction charge!',
  'text.bankText2':
    'Link your UPI ID with the Bank Account now to generate dynamic QR codes on Invoices and Bills.',
  'text.bankText3':
    'Your UPI ID is an address that identifies you on UPI (typically yourname@bankname).',
  'text.bankText4': 'Payments will be settled to your bank account linked with UPI ID instantly.',
  'text.setDefault': 'Set as Default',
  'text.userNote': 'Note: Notes and Terms here will come by default on all documents.',
  'text.yourSignature': 'Your Signature',
  'text.yourAuthority': 'Your Authority',
  'text.importedSuccessfully': 'Imported Successfully',
  'text.failed': 'Failed',

  'sales.tab.invoices': 'Invoices',
  'sales.tab.pos': 'POS',
  'sales.heading': 'Sales',
  'sales.tab.allTransactions': 'All Transactions',
  'sales.tab.paid': 'Paid',
  'sales.tab.pending': 'Pending',
  'sales.tab.cancelled': 'Cancelled',
  'sales.tab.subscriptions': 'Subscriptions',
  'sales.tab.refund': 'Refund',
  'sales.tab.returns': 'Credit Note (Returns)',
  'sales.tab.drafts': 'Drafts',

  'subscriptions.tab.allSubscriptions': 'All Subscriptions',
  'subscriptions.tab.active': 'Active',
  'subscriptions.tab.cancelled': 'Cancelled',
  'subscriptions.tab.expired': 'Expired',
  'subscriptions.tab.error': 'Error',

  'expenses.heading': 'Expenses',
  'expenses.addExpense': 'Add an Expense Now',
  'income.heading': 'Indirect Income',
  'income.addIncome': 'Add an Income Now',
  'journals.heading': 'Journals',
  'journals.addJournal': 'Add an Journal Now',

  'products.heading': 'Products & Services',
  'products.tab.items': 'Items',
  'products.tab.categories': 'Categories',
  'products.tab.groups': 'Groups',
  'products.tab.deleted': 'Deleted',
  'products.addText': 'Add your Products and Services to get started!',
  'products.addCategory': 'Add Category',
  'products.createGroup': 'Create Group',

  'inventory.heading': 'Inventory',
  'inventory.timeline.heading': 'Inventory Timeline',
  'inventory.tab.warehouse': 'Warehouse',
  'inventory.tab.timeline': 'Timeline',

  'payments.heading': 'Payments & Ledgers',
  'payments.tab.customers': 'Customers',
  'payments.tab.vendors': 'Vendors',
  'payments.text.youPay': 'You Pay',
  'payments.text.youCollect': 'You Collect',
  'paymentlinks.heading': 'Payment Links',

  'reports.heading': 'Reports',
  'reports.tab.itemReportByCustomer': 'Item Report By Customer',
  'reports.tab.customerReportByItem': 'Customer Report By Item',
  'reports.tab.hsnWiseSaleReport': 'HSN Wise Sale Report',
  'reports.tab.gstrReport': 'GSTR Report',
  'reports.tab.dailySaleReport': 'Daily Sale Report',
  'reports.tab.dailyStockReport': 'Daily Stock Report',

  'customers.heading': 'Customers',
  'customers.tab.all': 'All Customers',
  'customers.tab.deleted': 'Deleted',
  'vendors.heading': 'Vendors',
  'vendors.tab.all': 'All Vendors',
  'vendors.tab.deleted': 'Deleted',

  'analytics.heading': 'Insights',
  'analytics.subheading': 'Current Balance',
  'analytics.reportsOverview': 'Reports Overview',
  'analytics.profitAndLoss': 'Profit and Loss Statement',
  'analytics.payments': 'Payments',
  'analytics.topProducts': 'Top 5 Products by sale',
  'analytics.topCustomers': 'Top 5 Customers by sale',
  'analytics.highestSales': 'Which products have highest sales?',
  'analytics.highestCustomerSales': 'Which Customers have highest sales?',
  'analytics.segmentWiseSales': 'Segment wise sales by Quantity',
  'analytics.highestCategorySales': 'Which type of categories have highest sales?',
  'analytics.paymentIn': 'Payment In',
  'analytics.paymentsReceived': 'How you have received the payments?',
  'analytics.paymentOut': 'Payment Out',
  'analytics.paymentsPaid': 'How you have paid?',
  'analytics.expenseModes': 'Expenses by Payment Modes',
  'analytics.expensesPaid': 'How you have paid your expenses?',
  'analytics.expenseByCategory': 'Expenses by Categories',
  'analytics.expenseCategories': 'What are your expense categories?',

  'ewaybills.heading': 'E-way Bills',
  'ewaybills.tab.allEwayBills': 'All',
  'ewaybills.tab.success': 'Success',
  'ewaybills.tab.cancelled': 'Cancelled',

  'einvoicing.heading': 'E-Invoicing',
  'einvoicing.tab.allEInvoices': 'All E-Invoices',
  'einvoicing.tab.success': 'Success',
  'einvoicing.tab.cancelled': 'Cancelled',

  'onlinestore.copyLink': 'Copy Store Link',
  'onlinestore.tab.allOrders': 'All Orders',
  'onlinestore.tab.pending': 'Pending',
  'onlinestore.tab.accepted': 'Accepted',
  'onlinestore.tab.completed': 'Completed',
  'onlinestore.tab.cancelled': 'Cancelled',

  'settings.heading': 'Settings',
  'settings.tab.userProfile': 'User Profile',
  'settings.tab.companyDetails': 'Company Details',
  'settings.tab.bankDetails': 'Bank Details',
  'settings.tab.invoiceSettings': 'Invoice Settings',
  'settings.tab.notesAndTerms': 'Notes & Terms',
  'settings.tab.PosSettings': 'POS Settings',
  'settings.tab.barcodeSettings': 'Barcode Settings',
  'settings.tab.signatures': 'Signatures',
  'settings.tab.swipeWallet': 'Swipe Wallet',
  'settings.tab.paymentGateway': 'Payment Gateway',
  'settings.tab.allUsers': 'All Users',
  'settings.tab.roleSettings': 'Role Settings',
  'settings.tab.resellers': 'Resellers',

  'tab.invoiceSettings.documentSettings': 'Document Settings',
  'tab.invoiceSettings.customColumns': 'Custom Columns',

  'paymentstimeline.heading': 'Payments Timeline',

  'swipe_to_tally.heading': 'Swipe to Tally',
  'tally.logs': 'Logs',
  'tally.log_description': ' (These Logs contains the date of the import performed in the Tally.)',

  'api_integration.heading': 'API Integration',

  'wallet.heading': 'Swipe Wallet',
  'wallet.credits': 'Credits',
  'wallet.creditUsage': 'Wallet Credit Usage',
  'wallet.addCredits': 'Add Wallet Credits',
  'wallet.history': 'History',

  'campaigns.tab.allCampaigns': 'All Campaigns',

  'form.field.name': 'Name',
  'form.field.email': 'Email',
  'form.field.phone': 'Phone Number',
  'form.field.address': 'Address',
  'form.field.city': 'City',
  'form.field.state': 'State',
  'form.field.country': 'Country',
  'form.field.pincode': 'Pincode',
  'form.field.zip': 'Zip',
  'form.field.companyName': 'Company Name',
  'form.field.companyPhone': 'Company Phone',
  'form.field.companyEmail': 'Company Email',
  'form.field.gst': 'GSTIN',
  'form.field.panNumber': 'PAN Number',
  'form.field.bankName': 'Bank Name',
  'form.field.accountNumber': 'Account No',
  'form.field.selectupi': 'Select UPI ID to show QR Code',
  'form.field.confirmAccountNumber': 'Confirm Bank Account No',
  'form.field.ifscCode': 'IFSC Code',
  'form.field.branchName': 'Branch Name',
  'form.field.accountType': 'Account Type',
  'form.field.upi': 'UPI',
  'form.field.openingBalance': 'Opening Balance',
  'form.field.upiNumber': 'UPI Number',
  'form.field.logo': 'Company Logo',
  'form.field.profileImage': 'Profile Image',
  'form.field.billing': 'Billing Details',
  'form.field.shipping': 'Shipping Details',
  'form.field.trade/brand': 'Trade/Brand Name',
  'form.field.alternativeContact': 'Alternative Contact Number',
  'form.field.website': 'Website',
  'form.field.fetch': 'Fetch Details',
  'form.field.companyShippingTitle': 'Title',
  'form.field.addressLine1': 'Address Line 1',
  'form.field.addressLine2': 'Address Line 2',
  'form.field.invoicePrefix': 'Invoice Prefix',
  'form.field.purchasePrefix': 'Purchase Prefix',
  'form.field.purchaseInvoicePrefix': 'Purchase Invoice Prefix',
  'form.field.quotationPrefix': 'Quotation Prefix',
  'form.field.salesReturnPrefix': 'Sales Return Prefix',
  'form.field.documentColor': 'Document Color (Default: #276EF1)',
  'form.field.invoiceFooter': 'Invoice/PO/Estimate Footer',
  'form.field.thermalPrintFooter': 'Thermal Print Footer',
  'form.field.documentMarginTop': 'Document Margin Top',
  'form.field.documentMarginBottom': 'Document Margin Bottom',
  'form.field.customerNotes': 'Customer Notes',
  'form.field.customerTermsAndConditions': 'Customer Terms & Conditions',
  'form.field.vendorNotes': 'Vendor Notes',
  'form.field.vendorTermsAndConditions': 'Vendor Terms & Conditions',
  'form.field.quotationNotes': 'Quotation Notes',
  'form.field.quotationTermsAndConditions': 'Quotation Terms & Conditions',
  'form.field.invoiceWhatsappMessageLine1': 'Invoice Whatsapp Message Line 1',
  'form.field.invoiceWhatsappMessageLine2': 'Invoice Whatsapp Message Line 2',
  'form.field.defaultValue': 'Default Value',
  'form.field.posDiscount': 'POS Discount',
  'form.field.showTaxableAmount': 'Show Taxable Amount',
  'form.field.showCompanyDetails': 'Show Company Details',
  'form.field.showQRCodeOnBills': 'Show QR Code On Bills',
  'form.field.showItemDescription': 'Show Item Description',
  'form.field.companyNameFontSize': 'Company Name Font Size',
  'form.field.organizationNameFontSize': 'Organization Name Font Size',
  'form.field.selectPrinter': 'Select Printer',
  'form.field.showPackageDate': 'Show Package Date',
  'form.field.showMrp': 'Show MRP',
  'form.field.productNameFontSize': 'Product Name Font Size',
  'form.field.mrpFontSize': 'MRP Font Size',
  'form.field.signatureImage': 'Signature Image',
  'form.field.showTenderedCash': 'Show Tendered Cash',
  'form.field.customBarcodeQty': 'Add Quantity Manually on Barcode Scan',
  'form.field.showGoogleReviewsQR': 'Show Google Reviews QR',
  'form.field.priceEdit': 'Allow Price Edit',

  'form.header.invoices': 'For Invoices',
  'form.header.purchases': 'For Purchases',
  'form.header.quotations': 'For Quotations/Estimates and ProForma Invoices',
  'form.header.whatsappInvoice': 'Whatsapp Invoice Custom message!',
  'form.header.signatureDetails': 'Signature Details',

  'form.errors.name': 'Please Enter Name',
  'expenses.title': 'All your expenses at one place.',
  'expenses.leadText':
    'Simplify your business expenses to gain valuable insights and make better decisions.',
  'expenses.image': '/resources/placeholders/Expenses.png',
  'expenses.points': [
    'Track all of your business expenses in one place.',
    'Easily record your expenses across various categories.',
    'Discover business insights through expense report.',
  ],
  'expenses.buttonText': 'Record your expenses',
  'expenses.help_link': 'add_expense',

  'invoice.title': 'Creating invoices have never been so easy.',
  'invoice.leadText': 'Get comprehensive summary of all your sales transactions.',
  'invoice.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'invoice.points': [
    "Discover templates that's perfect for your business.",
    'Create invoices in 10 seconds & share them with customers.',
    'Keep track of your day-to-day transactions.',
  ],
  'invoice.buttonText': 'Create your first invoice.',
  'invoice.buttonLink': '/create/invoice',
  'invoice.help_link': 'create_invoice',

  'purchase.title': 'Purchase invoices made easy',
  'purchase.leadText': 'Turn purchase orders into invoices, share with ease, and stay organized.',
  'purchase.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'purchase.points': [
    'Convert purchase orders to invoices.',
    'Share invoices with vendors directly.',
    'Organize all your purchase orders & invoices in one place.',
  ],
  'purchase.buttonText': 'Add your purchase bill',
  'purchase.buttonLink': '/create/purchase',
  'purchase.help_link': 'create_purchase',

  'estimate.title': 'Make quotations that win clients!',
  'estimate.leadText':
    'Instantly send professional, detailed, customizable quotations to your customers.',
  'estimate.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'estimate.points': [
    'Make quotations that showcase your brand.',
    'Easily manage and track the status of Pro-forma invoices.',
    'Send proof of goods with delivery challan.',
  ],
  'estimate.buttonText': 'Send your first quotation.',
  'estimate.buttonLink': 'https://www.youtube.com/watch?v=JOXLbQHcJng',
  'estimate.help_link': 'create_quotation',

  'salesreturn.title': 'Creating credit note have never been so easy.',
  'salesreturn.leadText': 'Get comprehensive summary of all your sales returns transactions.',
  'salesreturn.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'salesreturn.points': [
    "Discover templates that's perfect for your business.",
    'Create sales return in 10 seconds & share them with customers.',
    'Keep track of your day-to-day transactions.',
  ],
  'salesreturn.buttonText': 'Create your first Credit note.',
  'salesreturn.buttonLink': '/create/sales_return',
  'salesreturn.help_link': 'create_invoice',

  'purchasereturn.title': 'Creating debit note have never been so easy.',
  'purchasereturn.leadText': 'Get comprehensive summary of all your purchase returns transactions.',
  'purchasereturn.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'purchasereturn.points': [
    "Discover templates that's perfect for your business.",
    'Create purchase return in 10 seconds & share them with customers.',
    'Keep track of your day-to-day transactions.',
  ],
  'purchasereturn.buttonText': 'Create your first Debit note.',
  'purchasereturn.buttonLink': '/create/purchasereturn',
  'purchasereturn.help_link': 'create_invoice',

  'purchaseorder.title': 'Creating purchase order have never been so easy.',
  'purchaseorder.leadText': 'Get comprehensive summary of all your purchase order transactions.',
  'purchaseorder.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'purchaseorder.points': [
    "Discover templates that's perfect for your business.",
    'Create purchase order in 10 seconds & share them with customers.',
    'Keep track of your day-to-day transactions.',
  ],
  'purchaseorder.buttonText': 'Create your first purchase order.',
  'purchaseorder.buttonLink': 'https://www.youtube.com/watch?v=2_36SR3r0dw',
  'purchaseorder.help_link': 'create_invoice',

  'products.title': 'Organize and streamline all your prodcuts.',
  'products.leadText':
    'Seamlessly manage your products and services using features like bulk upload, batching, and price lists.',
  'products.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'products.points': [
    'Define your products better.',
    'Upload multiple products at once.',
    'Showcase variants of your products.',
  ],
  'products.buttonText': 'Add your Products',
  'products.buttonLink': 'https://www.youtube.com/watch?v=vMA2QqihTFU',
  'products.help_link': 'add_product',

  'inventory.title': 'Manage inventory end to end',
  'inventory.leadText':
    'Efficiently monitor your inventory to ensure it remains well-stocked at all times.',
  'inventory.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'inventory.points': [
    'Analyize your stocking in and out easily.',
    'Enhance efficiency with automated inventory.',
    'Say goodbye to stockouts with low-stock reminders.',
  ],
  'inventory.buttonText': 'Manage your inventory',
  'inventory.buttonLink': '',
  'inventory.help_link': '',

  'payments.title': 'Receive lighting fast payments.',
  'payments.leadText':
    'Easily receive payments from your customer via 100+ modes with just a single tap.',
  'payments.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'payments.points': [
    'Keep track of your payments easily.',
    'Choose the right payment mode for your customer.',
    'Get paid on time with payment reminders.',
  ],
  'payments.buttonText': 'Track your payments',
  'payments.buttonLink': 'https://www.youtube.com/watch?v=SCLUU6vb4As',
  'payments.help_link': 'delete_payment',

  'customers.title': 'View your customer data - Anytime, Anywhere!',
  'customers.leadText':
    'Effortlessly manage customer details, payments & invoices to ensure your business runs smoothly.',
  'customers.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'customers.points': '',
  'customers.buttonText': 'Add your customers',
  'customers.buttonLink': 'https://www.youtube.com/watch?v=2_KecE9mews',
  'customers.help_link': 'add_customer',

  'vendors.title': 'All your vendors in one place',
  'vendors.leadText': 'Manage numerous vendor details and ledgers with ease.',
  'vendors.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'vendors.points': '',
  'vendors.buttonText': 'Add your vendors',
  'vendors.buttonLink': 'https://www.youtube.com/watch?v=76NgA94sBsE',
  'vendors.help_link': 'add_vendor',

  'analytics.title': 'Understand your business better.',
  'analytics.leadText':
    'Analyze data, track performance, and identify trends, for your business strategies.',
  'analytics.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'analytics.points': '',
  'analytics.buttonText': 'Check business overview',
  'analytics.buttonLink': '',
  'analytics.help_link': '',

  'reports.title': 'Make insightful business decisions.',
  'reports.leadText': 'Dive deep into the integrated details of your business.',
  'reports.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'reports.points': '',
  'reports.buttonText': 'View business insights',
  'reports.buttonLink': '',
  'reports.help_link': '',

  'ewaybills.title': 'Hassle free transportation with E-way bills',
  'ewaybills.leadText':
    'Create E-way bills effortlessly in Swipe Billing. Simplify your logistics and stay compliant with ease.',
  'ewaybills.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'ewaybills.points': '',
  'ewaybills.buttonText': 'Connect to E-way bill Portal',
  'ewaybills.buttonLink': 'https://www.youtube.com/watch?v=9bLtXRDKuk8',
  'ewaybills.help_link': '',

  'onlinestore.title': 'Build your online presence with Online store.',
  'onlinestore.leadText':
    'No matter where you are, your business goes with you. Stay connected to your business & your customers at all times.',
  'onlinestore.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'onlinestore.points': '',
  'onlinestore.buttonText': 'Start building your Store',
  'onlinestore.buttonLink': 'https://www.youtube.com/watch?v=qMQLRWSJO-I',
  'onlinestore.help_link': 'setup_online_store',

  'campaigns.title': 'Build connections that count.',
  'campaigns.leadText': 'Reach your customers with our SMS & E-mail campaigns.',
  'campaigns.image': 'https://getswipe.in/static/img/brand_logo.svg',
  'campaigns.points': '',
  'campaigns.buttonText': 'Reach your customers',
  'campaigns.buttonLink': 'https://www.youtube.com/watch?v=ScXy7yMVLXA',
  'campaigns.help_link': 'create_sms_campaign',

  // due dates text
  'dueDate.invoice_title': 'Due Date',
  'dueDate.purchase_return_title': 'Due Date',
  'dueDate.pro_forma_invoice_title': 'Due Date',
  'dueDate.purchase_title': 'Payment by',
  'dueDate.sales_return_title': 'Payment by',
  'dueDate.purchase_order_title': 'Payment by',
  'dueDate.estimate_title': 'Validity',
  'dueDate.delivery_challan_title': 'Delivery by',
  'dueDate.invoice':
    'The date by which you expect to receive payment from your customer after sending them an invoice.',
  'dueDate.purchase_return':
    'The date by which you expect to receive payment from your vendor after sending them an purchase return.',
  'dueDate.pro_forma_invoice':
    'The date by which you expect to receive payment from your customer after sending them an pro forma invoice.',
  'dueDate.purchase': 'The deadline by which you are expected to make the payment to your vendor.',
  'dueDate.purchase_order':
    'The deadline by which you are expected to make the payment to your vendor.',
  'dueDate.sales_return':
    'The deadline by which you are expected to make the payment to your customer',
  'dueDate.estimate':
    'Specifies how long an estimate or quote remains valid before the terms or prices might change or the offer expires.',
  'dueDate.delivery_challan':
    'It indicates when the goods or services are expected to be delivered to the customer. This helps in setting expectations for receipt of the goods or services.',
  totalAmount: 'Total',
  paid: 'Paid',
  pending: 'Pending',

  // Login
  welcome: 'Welcome',
  '10digit': '10 digit mobile number',
  email: 'abc@gmail.com',
  willSendOTP: 'We will be sending an OTP to this number',
  willSendOTPToEmail: 'We will be sending an OTP to this email',
  continue: 'Continue with Mobile Number',
  continueEmail: 'Continue with Email',
  termsMessage: 'By continuing you agree to our',
  mobileError: 'Please enter your 10 digit mobile number',
  emailError: 'Please enter valid email',
  otpSent: 'OTP sent to the given number.',
  otpSentToEmail: 'OTP sent to the given email.',
  confirmOTP: 'Confirm OTP',
  otpError: 'Please enter a valid OTP',
  terms: 'Terms',
  policy: 'Policy',
  help: 'For Help/Support',
  resendOTP: 'Resend OTP',
  deleteDoc: 'This permanently deletes this document from the records. Are you sure to continue?',
  deleteAllDoc:
    'This permanently deletes all cancelled document from the records. Are you sure to continue?',
  supportMessage:
    'We’ve received your interest. Our team will contact you within 24 hours during business days.',
}

export default {
  locale: 'english',
  localeAntd,
  messages,
}
