import { Button, Checkbox, Modal } from 'antd'
import { forwardRef, useImperativeHandle, useState } from 'react'
type visibilityProps = {
  onFinish: (values: any) => void
  visibility: boolean
  type: string
  onCancel: () => void
}
const VisibilityModal = forwardRef((props: visibilityProps, ref) => {
  const [open, setOpen] = useState(false)
  const [visibilityValue, setVisibilityValue] = useState(props.visibility)
  const [values, setValues] = useState({})
  const showModal = (values: any) => {
    setValues(values)
    setOpen(true)
  }
  const hideModal = () => {
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))

  return (
    <Modal
      maskClosable={true}
      open={open}
      closable={true}
      title={'Confirm'}
      destroyOnClose={true}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            props.onFinish({ ...values, visibility: visibilityValue })
            hideModal()
          }}
        >
          Save
        </Button>,
      ]}
      width={450}
      onCancel={() => {
        hideModal()
        props.onCancel()
      }}
    >
      <div className="pl-5">
        <Checkbox
          checked={visibilityValue}
          onChange={e => {
            setVisibilityValue(e.target.checked)
          }}
        >
          <span className="font-weight-bold">Add in all branches</span>
        </Checkbox>

        {visibilityValue ? (
          <p className="text-sm text-gray-500 mt-2">
            This {props.type} will be added in all branches.
          </p>
        ) : (
          <p className="text-sm text-gray-500 mt-2">
            This {props.type} will be added to the current branch only.
          </p>
        )}
      </div>
    </Modal>
  )
})

export default VisibilityModal
