import { Button, Col, Divider, Input, Row, Select, Table } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

export default function ExpenseItemTable(props) {
  const { is_cgst, is_rcm } = props
  const [new_cateogry, setNewCategory] = useState('')
  const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  const user = useSelector(state => state.user)

  const handleItemChange = (e, record, type) => {
    let newItem = {
      ...record,
      [type]:
        props.with_tax && props.is_cgst && type == 'tax_amount'
          ? e.target.value * 2
          : e.target.value,
    }
    if (type == 'category_id') {
      newItem.category =
        props.categories.find(cat => cat.category_id === e.target.value)?.category || ''
    }
    if (type == 'tax') {
      const netAmountVal = parseFloat(newItem.net_amount)
      newItem.tax_amount = (netAmountVal * parseFloat(newItem.tax)) / 100
      newItem.total_amount = netAmountVal + newItem.tax_amount
    } else if (type == 'tax_amount') {
      const taxAmountVal = parseFloat(newItem.tax_amount)
      if (parseFloat(newItem.tax) != 0 && taxAmountVal) {
        newItem.net_amount = taxAmountVal / (parseFloat(newItem.tax) / 100)
        newItem.total_amount = newItem.net_amount + taxAmountVal
      } else {
        newItem.total_amount = parseFloat(newItem.net_amount)
        newItem.tax_amount = 0
      }
    } else if (type == 'net_amount') {
      const netAmountVal = parseFloat(newItem.net_amount)
      newItem.tax_amount = (netAmountVal * parseFloat(newItem.tax)) / 100
      newItem.total_amount = netAmountVal + newItem.tax_amount
    } else if (type == 'total_amount') {
      const totalAmountVal = parseFloat(newItem.total_amount)
      newItem.net_amount = (totalAmountVal * 100) / (100 + parseFloat(newItem.tax))
      newItem.tax_amount = totalAmountVal - newItem.net_amount
    }
    const newItems = [...props.expense_items]
    const index = newItems.findIndex(item => item.id == record.id)
    newItems.splice(index, 1, newItem)
    props.updateExpenseItems(newItems)
  }
  const addNewItem = () => {
    const newItems = [...props.expense_items]
    newItems.push({
      id: 'new' + Math.random(),
      description: '',
      category: undefined,
      tax: 0,
      tax_amount: 0,
      net_amount: 0,
      total_amount: 0,
    })
    props.updateExpenseItems(newItems)
  }

  const removeItem = item => {
    let newItems = props.expense_items.filter(exp => exp.id != item.id)
    props.updateExpenseItems(newItems)
  }

  const tableColumns = [
    {
      title: 'Item Name',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <div>
          <Input value={text} onChange={e => handleItemChange(e, record, 'description')} />
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text, record) => (
        <div className="">
          <Select
            maxTagTextLength={40}
            placeholder="Select Category"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={e => handleItemChange({ target: { value: e } }, record, 'category_id')}
            value={record.category_id != -1 ? record.category : undefined}
            showSearch
            dropdownStyle={{
              minWidth: '500px',
            }}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider
                  style={{
                    margin: '8px 0',
                  }}
                />
                <span className="ml-1">Category</span>
                <div className="flex pb-2 pl-2 ">
                  <Input
                    placeholder="Can't find your category? Add new category here"
                    className="w-full mr-3"
                    value={new_cateogry}
                    onChange={e => setNewCategory(e.target.value)}
                  />
                  <Button
                    type="primary-light"
                    onClick={() => {
                      props.handleAddNewCategory(new_cateogry)
                      setNewCategory('')
                    }}
                  >
                    <i className="fa-solid fa-plus mr-2"></i>
                    Add Category
                  </Button>
                </div>
              </div>
            )}
            options={props.categories.map(item => ({
              label: item.category,
              value: item.category_id,
            }))}
          />
          {record.category_id == -1 && record.category != '' && (
            <p
              className="italic ml-3  text-gray-500 absolute font-size-11 right-zero cursor-pointer"
              onClick={() => {
                props.handleAddNewCategory(record.category)
              }}
            >
              <i class="fa-duotone text-gray-500 fa-solid fa-sparkles mr-2"></i>
              Want to add <span className="font-weight-bolder  ml-1"> {record.category}</span> ?
            </p>
          )}
        </div>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      hidden: !props.with_tax,
      render: (text, record) => (
        <Select
          showSearch
          className="font-weight-bold"
          placeholder="Select Tax"
          suffixIcon={<i className="fa-light fa-lg fa-chevron-down"></i>}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownStyle={{
            minWidth: '100px',
          }}
          value={record.tax}
          onChange={e => handleItemChange({ target: { value: e } }, record, 'tax')}
        >
          {props.taxes.map((e, i) => (
            <Option key={i} value={e}>
              {e}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: props?.companyDetails?.tax_labels?.CGST,
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      hidden: !(user?.selectedCompany?.country_code == 'IN' && props.with_tax && props.is_cgst),

      render: (text, record) => (
        <div>
          <Input
            type="number"
            value={record.tax_amount / 2}
            onChange={e => handleItemChange(e, record, 'tax_amount')}
          />
        </div>
      ),
    },
    {
      title: props.companyDetails.tax_labels.SGST,
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      hidden: !(user?.selectedCompany?.country_code == 'IN' && props.with_tax && props.is_cgst),
      render: (text, record) => (
        <div>
          <Input type="number" value={record.tax_amount / 2} disabled />
        </div>
      ),
    },
    {
      title: props.party_id == 0 ? 'Tax Amount' : props.companyDetails.tax_labels.IGST,
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      hidden: !(
        user?.selectedCompany?.country_code == 'IN' &&
        props.with_tax == true &&
        props.is_cgst == false
      ),

      render: (text, record) => (
        <div>
          <Input
            type="number"
            value={record.tax_amount}
            onChange={e => handleItemChange(e, record, 'tax_amount')}
          />
        </div>
      ),
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax_amount',
      key: 'tax_amount',
      hidden: !(user?.selectedCompany?.country_code != 'IN' && props.with_tax == true),
      render: (text, record) => (
        <div>
          <Input
            type="number"
            value={parseFloat(text)}
            onChange={e => handleItemChange(e, record, 'tax_amount')}
          />
        </div>
      ),
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      hidden: props.amount_type != 'net_amount',
      render: (text, record) => (
        <div>
          <Input
            type="number"
            value={record.net_amount}
            onChange={e => handleItemChange(e, record, 'net_amount')}
          />
        </div>
      ),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
      hidden: !props.with_tax || props.amount_type != 'total_amount',
      render: (text, record) => (
        <div>
          <Input
            type="number"
            value={record.total_amount}
            onChange={e => handleItemChange(e, record, 'total_amount')}
          />
        </div>
      ),
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Button type="danger" onClick={() => removeItem(record)}>
          <i className="fa fa-trash" />
        </Button>
      ),
    },
  ].filter(item => !item.hidden)

  return (
    <div>
      <Table
        dataSource={props.expense_items}
        size="large"
        columns={tableColumns}
        footer={() => (
          <div className="flex">
            <div style={{ width: '30%' }}>
              <Button type="primary" onClick={() => addNewItem()} className="ml-2">
                <i className="fa-solid fa-plus"></i> Add Item
              </Button>
            </div>
            <div style={{ width: '70%' }}>
              <Row>
                <Col span={12}>
                  <h6 className="text-gray-500 text-right">Taxable Amount:</h6>
                </Col>
                <Col span={12}>
                  <h6 className="text-gray-500 text-right font-size-16">
                    <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
                    {props.getExpenseItemsNetAmount().toLocaleString('en-IN', dotOptions)}
                  </h6>
                </Col>
                {(!is_cgst || user?.selectedCompany?.country_code != 'IN') && (
                  <>
                    <Col span={12}>
                      <h6 className="text-gray-500 text-right">
                        {props.selectedPartyId == 0 || user?.selectedCompany?.country_code != 'IN'
                          ? 'Tax Amount'
                          : props.companyDetails.tax_labels.IGST}
                        :
                      </h6>
                    </Col>
                    <Col span={12}>
                      <h6 className="text-gray-500 text-right font-size-16">
                        <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
                        {props.getExpenseItemsTaxAmount().toLocaleString('en-IN', dotOptions)}
                      </h6>
                    </Col>
                  </>
                )}
                {is_cgst && user?.selectedCompany?.country_code == 'IN' && (
                  <>
                    <Col span={12}>
                      <h6 className="text-gray-500 text-right">
                        {props.companyDetails.tax_labels.CGST}:
                      </h6>
                    </Col>
                    <Col span={12}>
                      <h6 className="text-gray-500 text-right font-size-16">
                        <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
                        {(parseFloat(props.getExpenseItemsTaxAmount()) / 2).toLocaleString(
                          'en-IN',
                          dotOptions,
                        )}
                      </h6>
                    </Col>
                    <Col span={12}>
                      <h6 className="text-gray-500 text-right">
                        {props.companyDetails.tax_labels.SGST}:
                      </h6>
                    </Col>
                    <Col span={12}>
                      <h6 className="text-gray-500 text-right font-size-16">
                        <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
                        {(parseFloat(props.getExpenseItemsTaxAmount()) / 2).toLocaleString(
                          'en-IN',
                          dotOptions,
                        )}
                      </h6>
                    </Col>
                  </>
                )}
                {is_rcm && (
                  <Col span={12}>
                    <h6 className="text-gray-500 text-right">Rcm Value:</h6>
                  </Col>
                )}
                {is_rcm && (
                  <Col span={12}>
                    <h6 className="text-darkred text-right font-size-16">
                      <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
                      {props.getExpenseItemsTaxAmount().toLocaleString('en-IN', dotOptions)}
                    </h6>
                  </Col>
                )}

                <Col span={12}>
                  <h6 className="text-gray-900 font-weight-bolder font-size-21 text-right">
                    Total Amount:
                  </h6>
                </Col>
                <Col span={12}>
                  <h6 className="text-gray-900 font-weight-bolder font-size-21 text-right">
                    <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
                    {props.getExpenseItemsTotalAmount().toLocaleString('en-IN', dotOptions)}
                  </h6>
                </Col>
              </Row>
            </div>
          </div>
        )}
      />
    </div>
  )
}
