import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Form, Input, Upload, message } from 'antd'
import { formItemLayout, getBase64 } from 'components/other/utility'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { getAPIData, getMultipartAPI } from 'services/jwt'
import SignatureOptions from '../other/signatureOptions'
import imageCompression from 'browser-image-compression'

const SIGNATURE_METHODS = {
  upload: 'upload',
  type: 'type',
  draw: 'draw',
}

export default class SignatureForm extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      selectedSignature: {},
      loading: false,
      signMethod: SIGNATURE_METHODS.upload,
      uploadedSignature: null,
      typedSignature: '',
      fontSignatureImg: null,
    }
    this.signatureCanvas = React.createRef()
  }

  clearSignatureData = () => {
    this.setState({
      signMethod: SIGNATURE_METHODS.upload,
      uploadedSignature: null,
      typedSignature: '',
      fontSignatureImg: null,
      selectedSignature: {},
    })

    // Clear signature canvas data
    if (this.signatureCanvas.current) {
      this.signatureCanvas.current.clear()
    }

    // Clear signature name field
    if (this.formRef.current) {
      console.log('called reset')
      this.formRef.current?.resetFields()
    }
  }

  setTypedSignature = e => {
    this.setState({ typedSignature: e.target.value, fontSignatureImg: null })
  }

  setFontSignatureImg = fileUrl => {
    this.setState({ fontSignatureImg: fileUrl })
  }

  setSignMethod = signMethod => {
    this.setState({ signMethod: signMethod })
  }

  setUploadedSignature = uploadedSignature => {
    this.setState({ uploadedSignature })
  }

  onAdd = () => {
    this.setState({ visible: true })
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    this.setState({
      file: file,
      uploadedSignature: file.url ?? file.preview,
    })
  }

  handleFinalSignature = async values => {
    const { uploadedSignature, fontSignatureImg, signMethod } = this.state
    const signatureCanvas = this.signatureCanvas
    let finalSignature
    if (signMethod === SIGNATURE_METHODS.draw) {
      finalSignature = signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    } else if (signMethod === SIGNATURE_METHODS.type) {
      finalSignature = fontSignatureImg
    } else if (signMethod === SIGNATURE_METHODS.upload) {
      finalSignature = uploadedSignature
    }

    if (!finalSignature) {
      message.error('No File Selected')
      return
    }

    this.setState(
      { selectedSignature: { ...this.state.selectedSignature, image: finalSignature } },
      () => this.onFinish(values),
    )
  }

  onFinish = async values => {
    this.setState({ loading: true })
    var url = 'add'
    var reqData = {}
    var data = {}
    if (this.state.selectedSignature.id != undefined) {
      url = 'update'
    }

    reqData = { id: this.state.selectedSignature.id }
    const req = { ...values, ...reqData }
    var form_data = new FormData()

    for (let key in this.state.selectedSignature) {
      if (key === 'image') {
        // Append the blob as file with a filename
        const blob = await (await fetch(this.state.selectedSignature[key])).blob()
        const file = new File([blob], 'signature.png', { type: 'image/png' })
        const compressedBlob = await imageCompression(file, {
          maxSizeMB: 1,
          useWebWorker: true,
          fileType: 'image/jpeg',
        })
        const compressedFile = new File([compressedBlob], file.name, {
          type: compressedBlob?.type ?? 'image/jpeg',
        })

        form_data.append(key, compressedFile)
      } else {
        form_data.append(key, this.state.selectedSignature[key])
      }
    }

    for (var key in values) {
      if (key == 'image') {
        const file = values[key]
        if (file != undefined) {
          const compressedBlob = await imageCompression(file, {
            maxSizeMB: 1,
            fileType: 'image/jpeg',
            useWebWorker: true,
          })

          const compressedFile = compressedBlob
          new File([compressedBlob], file.name, {
            type: compressedBlob?.type ?? 'image/jpeg',
          })
          form_data.set(key, compressedFile)
        } else {
          form_data.set(key, values[key])
        }
      } else {
        form_data.set(key, values[key])
      }
    }

    data = await getMultipartAPI('signature', url, form_data)
    if (data?.success) {
      message.success(data.message)
      this.props.refreshSignatures()
      this.clearSignatureData()
      this.setState({
        visible: false,
        loading: false,
      })
    }

    this.setState({ loading: false })
  }

  handleFormPrefill = currentData => {
    this.setState(
      { visible: true, uploadedSignature: currentData.image, selectedSignature: currentData },
      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            ...currentData,
          })
        }
      },
    )
  }

  render() {
    if (this.state.uploadedSignature != '' && this.state.uploadedSignature != undefined) {
      var fileList = [
        {
          uid: '-1',
          name: 'Signature.png',
          status: 'done',
          url: this.state.uploadedSignature,
        },
      ]
    } else {
      var fileList = []
    }

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )

    return (
      <div>
        <div>
          <Drawer
            title={
              <div className="flex justify-between items-center">
                <span>Signature Details</span>
                <Button
                  type="primary"
                  className="btn btn-primary px-2 mt-2"
                  onClick={() => this.formRef.current.submit()}
                >
                  <FormattedMessage id="button.save&update" />
                </Button>
              </div>
            }
            width={isMobile ? '100%' : '50%'}
            height={isMobile ? '90%' : '100%'}
            placement={isMobile ? 'top' : 'right'}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            closable={() => this.setState({ visible: false, selectedSignature: {} })}
            onClose={() =>
              this.setState({ visible: false, selectedSignature: {} }, this.clearSignatureData)
            }
            open={this.state.visible}
            footer={[
              <Button
                type="primary"
                className="btn btn-primary px-2 mt-2"
                onClick={() => this.formRef.current.submit()}
              >
                <FormattedMessage id="button.save&update" />
              </Button>,
            ]}
          >
            <Card className="mb-5">
              <Form
                {...formItemLayout}
                labelAlign="left"
                ref={this.formRef}
                name="signatureForm"
                onFinish={this.handleFinalSignature}
                onFinishFailed={() => message.error('Check All Details Again')}
                values={{
                  ...this.state.selectedSignature,
                }}
              >
                <Form.Item
                  name="signature_name"
                  rules={[{ required: true, message: 'Please fill Signature Name' }]}
                  extra="Signature Name is only for your reference and will not be shown on the documents."
                  label={'Signature Name'}
                >
                  <Input placeholder="Signature Name (This is only for your reference and will not be shown on the documents" />
                </Form.Item>
                <SignatureOptions
                  selectedSignature={this.state.selectedSignature}
                  customRequest={this.customRequest}
                  fileList={fileList}
                  uploadButton={uploadButton}
                  signMethod={this.state.signMethod}
                  uploadedSignature={this.state.uploadedSignature}
                  setSignMethod={this.setSignMethod}
                  typedSignature={this.state.typedSignature}
                  setTypedSignature={this.setTypedSignature}
                  setFontSignatureImg={this.setFontSignatureImg}
                  setUploadedSignature={this.setUploadedSignature}
                  signatureCanvas={this.signatureCanvas}
                />

                <p className="text-gray-500">
                  Images must be PNG or JPEG, recommended 1:1 (1024 x 1024 pixels) or 4:3 (640 x 480
                  Pixels) aspect ratios.
                </p>
              </Form>
            </Card>
            <span>
              <h4 className="font-weight-bolder font-size-36 mb-4">
                <span className="mr-2">
                  <FormattedMessage id="text.yourSignature" />.
                </span>
                <span className="text-gray-10">
                  <FormattedMessage id="text.yourAuthority" />.{' '}
                  <i className="fa-solid fa-signature"></i>
                </span>
              </h4>

              <img
                src="/resources/images/signature.jpg"
                className="ml-1 pb-1 height-200"
                alt="Swipe"
              />
            </span>
          </Drawer>
        </div>
      </div>
    )
  }
}

export class EnhancedSignatureForm extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <SignatureForm {...this.props} />
  }
}
