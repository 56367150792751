import { GET_LEAD_OPTIONS, SET_LEAD_OPTIONS, SET_LEAD_OPTIONS_LOADING } from './types'

export const get_lead_options = () => {
  return {
    type: GET_LEAD_OPTIONS,
  }
}

export const set_lead_options = payload => {
  return {
    type: SET_LEAD_OPTIONS,
    payload,
  }
}

export const set_lead_options_loading = isLoading => ({
  type: SET_LEAD_OPTIONS_LOADING,
  payload: isLoading,
})
