import { Text, View } from '@react-pdf/renderer'
import { styles as getStyles } from './styles'
import React, { Fragment } from 'react'
import { getStateName } from '../export_condition'
import PlaceOfSupply from '../InvoicePlaceOfSupply'
import { dispatch_to_doc_types } from '../utils'

const BillTo = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  var newarray = []
  let export_labels = [
    [
      {
        conversion_factor: 'Conversion Rate',
        is_show: invoice.is_export == 1 || invoice.is_multi_currency == 1,
      },
      {
        currency_code: 'Currency',
        is_show: invoice.is_export == 1 || invoice.is_multi_currency == 1,
      },
      { export_type: 'Export Type', is_show: invoice.is_export == 1 },
    ],
    [
      { name: 'Country Name', is_show: invoice.is_export == 1 },
      { shipping_date: 'Shipping Bill Date', is_show: invoice.is_export == 1 },
      { shipping_bill_number: 'Shipping bill #', is_show: invoice.is_export == 1 },
    ],
    [{ shipping_port_code: 'Shipping Port Code', is_show: invoice.is_export == 1 }],
  ]

  invoice.invoice_settings.show_conversion_factor != 1 && export_labels[0].shift()
  try {
    if (invoice.company.gstin.length != 15) {
      export_labels[0] = export_labels[0].filter(item => Object.keys(item)[0] != 'export_type')
    }
  } catch (e) {
    console.log(e)
  }

  if (Object.keys(invoice).includes('document_custom_headers')) {
    var new_headers = [...invoice.document_custom_headers]
    while (new_headers.length) newarray.push(new_headers.splice(0, 3))
  }
  return (
    <View>
      <View style={styles.billTo_headerContainer}>
        <View style={styles.billTo_block2}>
          <Text>
            <Text style={styles.billTo_label}>{invoice.document_title} #: </Text>
            <Text style={[styles.billTo_invoiceDate, styles.billTo_bold]}>
              {invoice.serial_number}
            </Text>
          </Text>
        </View>

        <View style={styles.billTo_block2}>
          <Text>
            <Text style={styles.billTo_label}>
              {invoice.document_type == 'purchases' ? 'Supplier Invoice' : invoice.document_title}{' '}
              Date:{' '}
            </Text>
            <Text style={[styles.billTo_invoiceDate, styles.billTo_bold]}>
              {invoice.document_date}
            </Text>
          </Text>
        </View>

        {invoice.invoice_settings.show_due_date == 1 && (
          <View style={styles.billTo_block2}>
            <Text>
              <Text style={styles.billTo_label}>
                {`${invoice.invoice_settings.labels[`${invoice.document_type}_due_date`]}:`}{' '}
              </Text>
              <Text style={styles.billTo_invoiceDate}>{invoice['document_due_date']}</Text>
            </Text>
          </View>
        )}

        {newarray.map((item, index) => (
          <>
            {item.map((item2, index2) => (
              <View style={styles.billTo_block2}>
                <Text>
                  <Text style={styles.billTo_label}>{item2.label}: </Text>
                  <Text style={[styles.billTo_invoiceDate, styles.billTo_bold]}>{item2.value}</Text>
                </Text>
              </View>
            ))}
          </>
        ))}
      </View>

      {invoice.document_type == 'purchases' && invoice.supplier_invoice_serial_number != '' && (
        <View style={styles.billTo_headerContainer}>
          <View style={styles.billTo_block2}>
            <Text>
              <Text style={styles.billTo_label}>Supplier Invoice #: </Text>
              <Text style={[styles.billTo_invoiceDate, styles.billTo_bold]}>
                {invoice.supplier_invoice_serial_number}
              </Text>
            </Text>
          </View>

          {/* <View style={styles.company_block2}>
            <Text>
              <Text style={styles.billTo_label}>Supplier Invoice Date: </Text>
              <Text style={[styles.billTo_invoiceDate, styles.billTo_bold]}>
                {invoice.supplier_invoice_date_string}
              </Text>
            </Text>
          </View> */}
        </View>
      )}

      {Object.keys(invoice).includes('eway_bill') && invoice.eway_bill.length > 0 && (
        <View style={styles.billTo_headerContainer}>
          <View style={styles.billTo_block4}>
            <Text>
              <Text style={styles.billTo_billTo}>Eway Bill # </Text>
              <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                {invoice.eway_bill[0].serial_number}
              </Text>
            </Text>
          </View>

          <View style={styles.billTo_block4}>
            <Text>
              <Text style={styles.billTo_billTo}>Vehicle Number: </Text>
              <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                {invoice.eway_bill[0].vehicle_number}
              </Text>
            </Text>
          </View>
        </View>
      )}

      {'export_details' in invoice &&
        invoice.export_details.length > 0 &&
        export_labels.map((item2, index) => (
          <View style={styles.billTo_headerContainer}>
            {item2.map(item => (
              <>
                {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                  invoice.export_details[0][Object.keys(item)[0]] != '' &&
                  invoice.export_details[0][Object.keys(item)[0]] != null &&
                  item.is_show == true && (
                    <View style={styles.billTo_block4}>
                      <Text>
                        <Text style={styles.billTo_billTo}>{item[Object.keys(item)[0]]}: </Text>
                        <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                          {invoice.export_details[0][Object.keys(item)[0]]}
                        </Text>
                      </Text>
                    </View>
                  )}
              </>
            ))}
          </View>
        ))}

      <View style={[styles.billTo_headerContainer]}>
        {invoice.customer.name != null && invoice.customer.name != '' && (
          <View style={styles.billTo_block3}>
            {invoice.document_type == 'purchases' ||
            invoice.document_type == 'purchase_orders' ||
            invoice.document_type == 'purchase_returns' ? (
              <Text
                style={styles.billTo_labels_2}
              >{`${invoice.invoice_settings.labels.vendor_details}:`}</Text>
            ) : (
              <Text
                style={styles.billTo_labels_2}
              >{`${invoice.invoice_settings.labels.customer_details}:`}</Text>
            )}
            {invoice.customer.name != invoice.customer.company_name ? (
              <>
                <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                  {invoice.customer.name}
                </Text>
                <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                  {invoice.customer.company_name}
                </Text>
              </>
            ) : (
              <Text style={[styles.billTo_smallFont, styles.billTo_bold]}>
                {invoice.customer.company_name}
              </Text>
            )}

            {invoice.customer.gstin != null && invoice.customer.gstin != '' && (
              <Text style={[styles.billTo_mb1, styles.billTo_smallFont, styles.billTo_bold]}>
                {tax_id_label}: {invoice.customer.gstin}
              </Text>
            )}

            {'custom_party_values' in invoice &&
              invoice.custom_party_values.map((item, index) => (
                <Text>
                  <Text style={styles.billTo_billTo}>{item.name}: </Text>

                  <Text style={styles.billTo_bold}>{item.value}</Text>
                </Text>
              ))}

            <Text style={[styles.billTo_smallFont]}>
              {invoice.customer.phone == ''
                ? ''
                : 'Ph: ' +
                  (invoice.customer.dial_code != '91'
                    ? '+' + invoice.customer.dial_code + ' '
                    : '') +
                  invoice.customer.phone}
            </Text>
            <Text style={[styles.billTo_mb1, styles.billTo_smallFont]}>
              {invoice.customer.email == '' ||
              invoice.customer.email == undefined ||
              invoice.customer.email == null
                ? ''
                : invoice.customer.email}
            </Text>

            {invoice.reference != '' && (
              <Text>
                <Text style={styles.billTo_labels}>Reference: </Text>
                <Text style={styles.billTo_info}>{invoice.reference}</Text>
              </Text>
            )}

            {invoice.gst_details.id == undefined &&
              invoice.company.shipping_address.address_1 != '' &&
              invoice.invoice_settings.show_dispatch_address === 1 && (
                <View style={styles.global_mb}>
                  <Text style={[styles.billTo_billTo]}>
                    {dispatch_to_doc_types.includes(invoice.document_type)
                      ? invoice.invoice_settings.labels.dispatch_to
                      : invoice.invoice_settings.labels.dispatch_from}
                    :
                  </Text>
                  <Text>{invoice.company.shipping_address.title}</Text>
                  <Text>{invoice.company.shipping_address.address_1}</Text>
                  <Text>{invoice.company.shipping_address.address_2}</Text>
                  <Text>
                    {invoice.company.shipping_address.city != '' &&
                      invoice.company.shipping_address.city}
                    {invoice.company.shipping_address.state != '' &&
                      invoice.company.shipping_address.state != undefined &&
                      invoice.company.shipping_address.state != null &&
                      (getStateName(invoice.company.shipping_address.state) != 'OTHERTERRITORY' ||
                        getStateName(invoice.company.shipping_address.state) !=
                          '97-OTHERTERRITORY' ||
                        getStateName(invoice.company.shipping_address.state) !=
                          'OTHER TERRITORY') &&
                      (invoice.company.shipping_address.city != '' ? ', ' : '') +
                        getStateName(invoice.company.shipping_address.state)}
                    {invoice.is_export == 1 &&
                      invoice.company.shipping_address.country &&
                      invoice.company.shipping_address.country != 'India' &&
                      '\n' + invoice.company.shipping_address.country}
                    {invoice.company.shipping_address.pincode != '' &&
                      invoice.company.shipping_address.pincode != null &&
                      invoice.company.shipping_address.pincode != 'None' &&
                      invoice.company.shipping_address.pincode != undefined &&
                      (invoice.company.shipping_address.city != '' ||
                      invoice.company.shipping_address.state != ''
                        ? ', '
                        : '') + invoice.company.shipping_address.pincode}
                    {invoice.company.shipping_address.notes != '' &&
                      '\n' + invoice.company.shipping_address.notes}
                  </Text>
                </View>
              )}

            {/* {data.gstin.length == 15 && (
              <View style={styles.billTo_row}>
                <Text style={styles.billTo_labels}>Place of Supply: </Text>
                {invoice.document_type == 'purchases' ||
                invoice.document_type == 'purchase_orders' ||
                invoice.document_type == 'purchase_returns' ||
                invoice.immovable_tax_type == 1 ? (
                  <Text style={styles.billTo_info}>{data.state}</Text>
                ) : (
                  <Text style={styles.billTo_info}>
                    {invoice.customer.billing.state != '' &&
                      invoice.customer.billing.state != 'OTHERTERRITORY' &&
                      invoice.customer.billing.state != '97-OTHERTERRITORY' &&
                      invoice.customer.billing.state + ' '}
                    {invoice.customer.billing.state == '' && invoice.company.billing_state}
                    {invoice.is_export == 1 &&
                      (invoice.customer.billing.state == 'OTHERTERRITORY' ||
                        invoice.customer.billing.state == '97-OTHERTERRITORY') && (
                        <Text style={{ textTransform: 'uppercase' }}>
                          {invoice.export_details[0].name}
                        </Text>
                      )}
                  </Text>
                )}
              </View>
            )} */}
            {/*
            <PlaceOfSupply
              invoice={invoice}
              styles={{
                block: styles.billTo_row,
                title: styles.billTo_labels,
                text: styles.billTo_info,
              }}
              in_new_line={false}
            />*/}
          </View>
        )}

        <View style={styles.billTo_block3}>
          {invoice.customer.billing != undefined &&
            invoice.customer.billing.address_1 != null &&
            invoice.customer.billing.address_1 != '' && (
              <View>
                <Text
                  style={styles.billTo_labels_2}
                >{`${invoice.invoice_settings.labels.customer_billing_address}:`}</Text>
                <Text>{invoice.customer.billing.address_1}</Text>
                <Text>{invoice.customer.billing.address_2}</Text>
                <Text>
                  {invoice.customer.billing.city != '' && invoice.customer.billing.city}
                  {invoice.is_export != 1 &&
                    invoice.customer.billing.state != '' &&
                    invoice.customer.billing.state != undefined &&
                    invoice.customer.billing.state != null &&
                    (getStateName(invoice.customer.billing.state) != 'OTHERTERRITORY' ||
                      getStateName(invoice.customer.billing.state) != '97-OTHERTERRITORY' ||
                      getStateName(invoice.customer.billing.state) != 'OTHER TERRITORY') &&
                    (invoice.customer.billing.city != '' ? ', ' : '') +
                      getStateName(invoice.customer.billing.state)}
                  {invoice.is_export == 1 &&
                    invoice.customer.billing.country &&
                    invoice.customer.billing.country != 'India' &&
                    '\n' + invoice.customer.billing.country}
                  {invoice.customer.billing.pincode != '' &&
                    invoice.customer.billing.pincode != null &&
                    invoice.customer.billing.pincode != 'None' &&
                    invoice.customer.billing.pincode != undefined &&
                    (invoice.customer.billing.city != '' || invoice.customer.billing.state != ''
                      ? ', '
                      : '') + invoice.customer.billing.pincode}
                </Text>
              </View>
            )}

          <PlaceOfSupply
            invoice={invoice}
            styles={{
              block: styles.billTo_block4,
              title: styles.billTo_billTo,
              text: styles.billTo_bold,
            }}
            in_new_line={false}
          />

          {Object.keys(invoice).includes('eway_bill') && invoice.eway_bill.length > 0 && (
            <Fragment>
              <View style={styles.billTo_row}>
                <Text style={styles.billTo_labels}>Eway Bill # </Text>
                <Text style={[styles.billTo_info]}>{invoice.eway_bill[0].serial_number}</Text>
              </View>

              <View style={styles.billTo_row}>
                <Text style={styles.billTo_labels}>Vehicle Number: </Text>
                <Text style={[styles.billTo_info]}>{invoice.eway_bill[0].vehicle_number}</Text>
              </View>
            </Fragment>
          )}

          {/* {'export_details' in invoice &&
            invoice.export_details.length > 0 &&
            export_labels.map((item2, index) => (
              <>
                {item2.map(item => (
                  <>
                    {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                      invoice.export_details[0][Object.keys(item)[0]] != '' &&
                      invoice.export_details[0][Object.keys(item)[0]] != null && (
                        <View style={styles.billTo_row}>
                          <Text style={styles.billTo_labels}>{item[Object.keys(item)[0]]}: </Text>
                          <Text style={styles.billTo_info}>
                            {invoice.export_details[0][Object.keys(item)[0]]}
                          </Text>
                        </View>
                      )}
                  </>
                ))}
              </>
            ))} */}

          {!dispatch_to_doc_types.includes(invoice.document_type) &&
            invoice.customer.shipping != undefined &&
            invoice.customer.shipping.address_1 != null &&
            invoice.customer.shipping.address_1 != '' && (
              <View style={[styles.billTo_row, styles.global_mt]}>
                <Text
                  style={styles.billTo_labels_2}
                >{`${invoice.invoice_settings.labels.customer_shipping_address}:`}</Text>
                <View style={styles.billTo_info_2}>
                  {invoice.customer.shipping.title != '' && (
                    <Text>{invoice.customer.shipping.title}</Text>
                  )}
                  <Text>{invoice.customer.shipping.address_1}</Text>
                  <Text>{invoice.customer.shipping.address_2}</Text>
                  <Text>
                    {invoice.customer.shipping.city != '' && invoice.customer.shipping.city}
                    {invoice.is_export != 1 &&
                      invoice.customer.shipping.state != '' &&
                      invoice.customer.shipping.state != undefined &&
                      invoice.customer.shipping.state != null &&
                      (getStateName(invoice.customer.shipping.state) != 'OTHERTERRITORY' ||
                        getStateName(invoice.customer.shipping.state) != '97-OTHERTERRITORY' ||
                        getStateName(invoice.customer.shipping.state) != 'OTHER TERRITORY') &&
                      (invoice.customer.shipping.city != '' ? ', ' : '') +
                        getStateName(invoice.customer.shipping.state)}
                    {invoice.is_export == 1 &&
                      invoice.customer.shipping.country &&
                      invoice.customer.shipping.country != 'India' &&
                      '\n' + invoice.customer.shipping.country}
                    {invoice.customer.shipping.pincode != '' &&
                      invoice.customer.shipping.pincode != null &&
                      invoice.customer.shipping.pincode != 'None' &&
                      invoice.customer.shipping.pincode != undefined &&
                      (invoice.customer.shipping.city != '' || invoice.customer.shipping.state != ''
                        ? ', '
                        : '') + invoice.customer.shipping.pincode}
                    {invoice.customer.shipping.notes != '' &&
                      '\n' + invoice.customer.shipping.notes}
                  </Text>
                </View>
              </View>
            )}
        </View>
      </View>
    </View>
  )
}

export default BillTo
