import { Button, Descriptions, Table, Tooltip } from 'antd'
import AddHsn from 'components/modal/addHsn'
import EnhancedProductAnalysis from 'components/modal/productAnalysis'
import { extraChargesAmount, getTaxAmount, getTotalDiscount } from 'components/other/calculation'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get_batch_settings } from 'redux/document/actions'
import { getPermissions } from 'redux/permissions/actions'
import { getAPI } from 'services/jwt'
import { mergeItemsAndItemHeaders } from 'utils/utilFunctions'

class ProductsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedKeys: [],
    }
  }
  addHsn = React.createRef()
  async componentDidMount() {
    if (!this.props.isExternal) {
      if (this.props?.permissions?.length == 0) {
        await this.props.dispatch(getPermissions())
      }
      if (this.props?.batch_settings == undefined) {
        await this.props.dispatch(get_batch_settings())
      }
    }
  }

  getNetAmount = record => {
    return record.price * record.qty - (record.price * record.qty * record.discount) / 100
  }

  getTaxAmount = record => {
    return (this.getNetAmount(record) * record.tax) / 100
  }
  getRowTotal = record => {
    return Math.round(this.getNetAmount(record) + this.getTaxAmount(record))
  }

  getTotal = () => {
    var sum = 0
    this.props.data.map(item => {
      sum = sum + this.getRowTotal(item)
    })
    return Math.round(sum)
  }

  getTotalTax = () => {
    var sum = 0
    this.props.data.map(item => {
      sum = sum + this.getTaxAmount(item)
    })
    return sum
  }
  addProductHsn = async (product_id, hsn_code) => {
    var req = {
      product_id: product_id,
      hsn_code: hsn_code,
    }
    var data = await getAPI('utils', 'add_hsn_code', req)
    // message.success(data.message)
    this.props?.refresh()
  }
  render() {
    let conversion_factor = 1
    let currency_symbol = this.props?.document?.company.currency_symbol

    const { integrations, batch_settings } = this.props
    const { hide_taxable_amount } = this.props.document.invoice_settings

    if (
      (this.props.document.is_export || this.props.document.is_multi_currency) &&
      this.props.document.invoice_settings.show_both_currencies != true
    ) {
      conversion_factor = this.props.document.export_details[0].conversion_factor
      currency_symbol = this.props.document.export_details[0].currency_symbol
    }
    const hasNonEmptyField = record => {
      return (
        (record.batch_no !== '' ||
          record.expiry_date !== '' ||
          record.mfg_date !== '' ||
          record.custom_col_values.some(value => value !== '')) &&
        !this.props.isExternal
      )
    }
    var tableColumn = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        width: '36%',
        render: (text, selectedData) => {
          if (selectedData.is_item_header != undefined && selectedData.is_item_header) {
            return (
              <div className="flex items-center">
                <div>
                  <span className="font-weight-medium font-size-14 text-dark">
                    {selectedData.product_name}
                  </span>
                </div>
              </div>
            )
          }
          return (
            <div className="flex items-center">
              <div>
                <a href="#">
                  <span
                    onClick={e =>
                      !this.props.isExternal &&
                      this.productAnalysis.showModal(
                        selectedData,
                        selectedData.product_id,
                        selectedData,
                      )
                    }
                    className="font-weight-medium font-size-14 text-dark"
                  >
                    {selectedData.product_name + selectedData.variant_name}

                    {selectedData.unit != 'OTH' && selectedData.unit != 'NONE' && (
                      <span className="font-size-10 text-purple ml-2">{selectedData.unit}</span>
                    )}
                    {selectedData.batch_no != 0 && (
                      <Tooltip
                        title={selectedData.batch_no?.split(',')?.map((batch, index) => (
                          <p key={index} className="m-0 p-0 font-size-12 text-gray-100">
                            {batch}
                          </p>
                        ))}
                      >
                        <p className="m-0 p-0 font-size-12 text-gray-500">
                          {selectedData.batch_no?.length > 20
                            ? selectedData.batch_no?.substring(0, 20) + '...'
                            : selectedData.batch_no}
                        </p>
                      </Tooltip>
                    )}
                  </span>

                  <p className="font-size-10 mt-0 mb-0">
                    {selectedData.qtyinstock != '' && selectedData.qtyinstock != undefined && (
                      <span
                        className="mt-0 mb-0 mr-2"
                        //   style={{ color: this.getQtyClass(selectedData.qtyinstock) }}
                      >
                        Avl qty: {selectedData.qtyinstock.toFixed(2)}
                      </span>
                    )}
                    {selectedData.hsn_code != '' && selectedData.hsn_code != undefined ? (
                      <span
                        className="mt-0 mb-0 text-gray-900 mr-2"
                        onClick={() => this.addHsn.current?.showModal(selectedData)}
                      >
                        {this.props?.user?.selectedCompany?.labels?.hsn_code}:{' '}
                        {selectedData.hsn_code}
                      </span>
                    ) : (
                      !this.props.isExternal &&
                      (this.props.checkForHSN ? (
                        <Tooltip title="HSN Code is required to proceed" color="red">
                          <Button
                            type="danger"
                            size="small"
                            className="mr-2 font-semibold"
                            shape="round"
                            onClick={() => this.addHsn.current?.showModal(selectedData)}
                          >
                            <i className="fa-regular fa-exclamation-circle mr-1"></i>
                            Add {this.props?.user?.selectedCompany?.labels?.hsn_code}
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          type="add-field"
                          className="mr-2"
                          shape="round"
                          onClick={() => this.addHsn.current.showModal(selectedData)}
                        >
                          <i className="fa-regular fa-plus mr-1"></i>{' '}
                          {this.props?.user?.selectedCompany?.labels?.hsn_code}
                        </Button>
                      ))
                    )}
                    {selectedData.barcode_id != '' && selectedData.barcode_id != undefined && (
                      <Tooltip title="Print Barcode">
                        <Button type="yellow-2" size="small" className="">
                          <i className="fa fa-barcode" />
                        </Button>
                      </Tooltip>
                    )}
                    {selectedData.is_delete == 1 && (
                      <div className="ml-2 mt-2 p-0.5 border border-danger rounded w-14 text-center">
                        <span className="text-danger text-md font-semibold">Deleted</span>
                      </div>
                    )}
                  </p>
                </a>

                {selectedData.description != '' && selectedData.description != '<p><br></p>' && (
                  <p
                    className="font-size-12 mt-1 mb-0 font-weight-medium"
                    style={{
                      maxWidth: '500px',
                    }}
                  >
                    <span
                      className="text-gray-500"
                      dangerouslySetInnerHTML={{ __html: selectedData.description }}
                    ></span>
                  </p>
                )}
              </div>
            </div>
          )
        },
      },
      Table.EXPAND_COLUMN,
      {
        title: 'Quantity',
        dataIndex: 'qty',
        width: '16%',
        render: (text, data) =>
          data.is_item_header == undefined && (
            <span>
              <span>
                {data.qty == null ? '' : Number(data.qty * data.conversion_rate).toFixed(3)}
              </span>
              {data.unit != 'OTH' && data.unit != 'NONE' && (
                <span className="ml-1">{data.item_unit}</span>
              )}
            </span>
          ),
      },
      {
        title: (
          <>
            <span>
              <span className="font-size-12 mt-0 mb-0">Unit Price</span>
            </span>
          </>
        ),
        dataIndex: 'price',
        width: '16%',
        responsive: ['md'],
        render: (text, record) =>
          record.is_item_header == undefined && (
            <>
              <span className="font-size-15">
                {((Number(text) * (1 - record.discount / 100)) / conversion_factor).toFixed(2)}
              </span>
              {record.discount > 0 && (
                <p className="flex flex-col font-size-12 mt-0 mb-0 text-forest font-weight-medium">
                  <span>
                    {parseInt(String(record.id).split('_')[0]) > -1
                      ? (Number(text) / conversion_factor).toFixed(2)
                      : 0}
                  </span>
                  <span className="text-forest">
                    {' '}
                    ({record.discount.toFixed(2)}%, {currency_symbol}
                    {record.discount_amount.toFixed(2)})
                  </span>
                </p>
              )}
            </>
          ),
      },
      //{
      //  title: 'Price with Tax',
      //  dataIndex: 'price_with_tax',
      //  width: '12%',
      //  responsive: ['md'],
      //  render: (text, data) => <span>{text.toFixed(2)}</span>,
      //},
      {
        title: (
          <>
            <span>
              <span className="font-size-12 mt-0 mb-0">Net Amount</span>
              <p className="font-size-9 mb-0 mt-0">Tax (%)</p>
            </span>
          </>
        ),
        align: 'right',
        width: '16%',
        responsive: ['md'],
        render: (_, record) =>
          record.is_item_header == undefined && (
            <>
              <span className="font-size-15">
                {parseInt(String(record.id).split('_')[0]) > -1
                  ? (record.net_amount / conversion_factor).toFixed(2)
                  : 0}
              </span>
              {record.tax > 0 && this.props?.document?.with_tax == 1 && (
                <p className="font-size-11 mt-0 mb-0 text-gray-10">
                  <span>
                    {parseInt(String(record.id).split('_')[0]) > -1
                      ? (getTaxAmount(record) / conversion_factor).toFixed(2)
                      : 0}
                  </span>
                  <span className="text-gray-10"> ({record.tax}%)</span>
                </p>
              )}
            </>
          ),
        is_delete: this.props?.document?.with_tax == 0,
      },
      {
        title: 'Total',
        align: 'right',
        width: '16%',
        render: (_, record) =>
          record.is_item_header == undefined && (
            <>
              <span className="font-weight-bold font-size-16">
                {/*{currency_symbol}*/}
                {parseInt(String(record.id).split('_')[0]) > -1
                  ? (record.total_amount / conversion_factor).toFixed(2)
                  : 0}{' '}
              </span>
              {/* {console.log(this.props.document.export_details)} */}
              {/* {this.props.document.export_details.length > 0 && (
              <span className="text-gray-10 font-size-12">
                <br></br>({this.props.document.export_details[0].currency_symbol}
                {(
                  record.total_amount / this.props.document.export_details[0].conversion_factor
                ).toFixed(2)}{' '}
                )
              </span>
            )} */}
            </>
          ),
      },
    ]
    tableColumn = tableColumn.filter(item => item.is_delete != true)
    const expandedRowRender = record => {
      let data = [record]
      let columns = []

      if (record.has_batches === 1) {
        columns.push(
          {
            title: 'Batch No.',
            dataIndex: 'batch_no',
            key: 'batch_no',
            render: text => <span>{text ? text : '-'}</span>,
          },
          {
            title: 'Mfg. Date',
            dataIndex: 'mfg_date',
            key: 'mfg_date',
            render: text => <span>{text ? text : '-'}</span>,
          },
          {
            title: 'Expiry Date',
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            render: text => <span>{text ? text : '-'}</span>,
          },
        )
      } else if (record.has_batches === 2) {
        columns.push({
          title: `${batch_settings.serial_number_label}`,
          dataIndex: 'batch_no',
          key: 'batch_no',
          render: text => <span>{text ? text : '-'}</span>,
        })
      }

      record.custom_col_names.map((col, index) =>
        columns.push({
          title: col,
          dataIndex: `custom_col_values[${index}]`,
          key: `custom_col_values_${index}`,
          render: (text, record) => (
            <span>{record.custom_col_values[index] ? record.custom_col_values[index] : '-'}</span>
          ),
        }),
      )

      return (
        <div className="p-3 pb-2">
          <span className=""></span>
          <>
            {/* <Table columns={columns} dataSource={data} pagination={false} /> */}
            <Descriptions title="" bordered size="small" column={1}>
              {record.has_batches === 1 && (
                <>
                  <Descriptions.Item label="Batch No.">
                    {record.batch_no ? record.batch_no : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Mfg. Date">
                    {record.mfg_date ? record.mfg_date : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Expiry Date">
                    {record.expiry_date ? record.expiry_date : '-'}
                  </Descriptions.Item>
                </>
              )}
              {record.has_batches === 2 && (
                <Descriptions.Item label={batch_settings.serial_number_label}>
                  {record.batch_no ? record.batch_no : '-'}
                </Descriptions.Item>
              )}
              {record.custom_col_names.map((col, index) => (
                <Descriptions.Item label={col}>
                  {record.custom_col_values[index] ? record.custom_col_values[index] : '-'}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>
        </div>
      )
    }

    return (
      <div className="">
        <Table
          size="small"
          className="products-table"
          rowClassName={(record, index) =>
            `${this.props.checkForHSN && !record.hsn_code ? 'missing-hsn' : ''} ${
              record.is_item_header != undefined ? 'bg-card-orange text-xs item-header-row' : ''
            }`
          }
          dataSource={
            this.props.item_headers != undefined && this.props.item_headers?.length > 0
              ? mergeItemsAndItemHeaders(this.props.data, this.props.item_headers)
              : this.props.data
          }
          columns={tableColumn}
          pagination={{ pageSize: 5, size: 'small' }}
          footer={() => (
            <>
              {this.props.data?.length > 0 && this.props.document.hide_totals == 0 && (
                <div className="flex flex-wrap mb-2">
                  <div className="w-8/12 text-right">
                    {this.props.document_custom_additional_charges != undefined &&
                      this.props.document_custom_additional_charges.map((each, index) => (
                        <h6 key={index}>
                          <span className="text-gray-500">{each.name}</span>
                        </h6>
                      ))}
                    {this.props.document.company.is_composite == 0 && hide_taxable_amount == 0 && (
                      <h6>
                        <span className="text-gray-500">Taxable Amount:</span>
                      </h6>
                    )}
                    {this.props.document.tax_amount > 0 && (
                      <h6>
                        <span className="text-gray-500">Tax Amount:</span>
                      </h6>
                    )}

                    <h6 className="mb-0">
                      <span className="text-gray-900 font-weight-bolder font-size-21">
                        Total Amount:
                      </span>
                    </h6>
                    {Number(this.props.extraDiscount) + Number(getTotalDiscount(this.props.data)) >
                      0 && (
                      <h6>
                        <span className="text-gray-500">Total Discount:</span>
                      </h6>
                    )}
                  </div>

                  <div className="w-4/12 text-right pr-2">
                    {this.props.document_custom_additional_charges != undefined &&
                      this.props.document_custom_additional_charges.map((each, index) => (
                        <h6 key={index}>
                          <span className="text-gray-500 font-weight-bolder">
                            {each.type == -1 ? '(-)' : ''} {currency_symbol}
                            {(each.net_amount / conversion_factor).toFixed(2)}{' '}
                          </span>
                        </h6>
                      ))}
                    {this.props.document.company.is_composite == 0 && hide_taxable_amount == 0 && (
                      <h6>
                        <span className="font-size-16 text-gray-500 font-weight-bolder">
                          {' '}
                          {currency_symbol}
                          {(
                            (this.props.document.net_amount +
                              extraChargesAmount(
                                this.props.document_custom_additional_charges,
                                'net_amount',
                              )) /
                            conversion_factor
                          ).toFixed(2)}{' '}
                        </span>
                      </h6>
                    )}
                    {this.props.document.tax_amount > 0 && (
                      <h6>
                        <span className="font-size-16 text-gray-500 font-weight-bolder">
                          {' '}
                          {currency_symbol}
                          {(this.props.document.tax_amount / conversion_factor).toFixed(2)}{' '}
                        </span>
                      </h6>
                    )}

                    <h6 className="mb-0">
                      <span className="font-size-21 text-gray-900 font-weight-bolder">
                        {' '}
                        {currency_symbol}
                        {(this.props.document.total_amount / conversion_factor).toFixed(2)}
                      </span>
                    </h6>
                    {Number(this.props.extraDiscount) + Number(getTotalDiscount(this.props.data)) >
                      0 && (
                      <h6 className="mb-0">
                        <span className="text-gray-500 font-weight-bolder">
                          {' '}
                          {currency_symbol}
                          {(Number(this.props.document.total_discount) / conversion_factor).toFixed(
                            2,
                          )}{' '}
                        </span>
                      </h6>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: record => hasNonEmptyField(record),
            expandedRowKeys: this.state.expandedKeys,
            onExpand: (expanded, record) => {
              if (expanded) {
                this.setState({
                  expandedKeys: [record.item_row_id],
                })
              } else {
                this.setState({
                  expandedKeys: [],
                })
              }
            },
          }}
          expandIcon={({ expanded, onExpand, record }) => {
            if (record.is_item_header != undefined) {
              return null
            }
            if (expanded) {
              return (
                <Button
                  size="small"
                  type="transparent"
                  className="text-danger"
                  onClick={e => {
                    onExpand(record, e)
                    e.stopPropagation()
                  }}
                >
                  <span className="text-danger">
                    Close <i className="fa-solid fa-chevron-up"></i>
                  </span>
                </Button>
              )
            } else {
              if (hasNonEmptyField(record)) {
                return (
                  <Button
                    type="link"
                    size="small"
                    onClick={e => {
                      onExpand(record, e)
                    }}
                  >
                    + More Details
                  </Button>
                )
              } else {
                return <span></span>
              }
            }
          }}
          rowKey={record => record.item_row_id}
        />
        <AddHsn ref={this.addHsn} addProductHsn={this.addProductHsn} />

        {!this.props.isExternal && (
          <EnhancedProductAnalysis
            onRef={ref => (this.productAnalysis = ref)}
            menu="product"
            onProductUpdate={() => {}}
          />
        )}
      </div>
    )
  }
}

ProductsList.defaultProps = {
  isExternal: false,
}
const mapStateToProps = state => {
  return {
    integrations: state.permissions.integrations,
    batch_settings: state.document.batch_settings,
    user: state.user,
  }
}
export default connect(mapStateToProps)(ProductsList)
