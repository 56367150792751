import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  List,
  message,
  Switch,
  Tabs,
  Typography,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import store from 'store'
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { getAPI, getAPIData } from 'services/jwt'

const EditNotesForm = forwardRef((props, ref) => {
  const [showEditDrawer, setShowEditDrawer] = useState(false)
  const [selectedNotes, setSelectedNotes] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const notesRef = createRef()
  const showDrawer = (data, is_Add) => {
    setIsEdit(is_Add)
    setShowEditDrawer(true)
    setSelectedNotes(data)
  }
  useImperativeHandle(ref, () => ({
    showDrawer,
  }))
  const onChangeNote = async req => {
    const data = await getAPI('user', 'edit_document_notes', req)
    setShowEditDrawer(false)
    props.refreshNotesTerms()
  }
  return (
    <Drawer
      title={
        <>
          <div className="flex items-center">
            <div className="mr-auto">
              <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                {` ${isEdit ? 'Edit' : 'Add'} ${props.doc_name} ${
                  props.is_notes ? 'Notes' : 'Terms'
                } `}
              </span>
            </div>
          </div>
        </>
      }
      placement="right"
      width={isMobile ? '90%' : '40%'}
      closable={() => setShowEditDrawer(false)}
      onClose={() => setShowEditDrawer(false)}
      closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      open={showEditDrawer}
      destroyOnClose={true}
      extra={
        <Button
          onClick={() => notesRef.current.submit()}
          type="primary"
          className="arrow-transition"
        >
          Save
          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
        </Button>
      }
      footer={
        <>
          <Button
            onClick={() => notesRef.current.submit()}
            type="primary"
            className="mr-2 arrow-transition"
          >
            Save
            <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
          </Button>

          <Button onClick={() => setShowEditDrawer(false)} type="danger">
            Close
          </Button>
        </>
      }
    >
      <Card>
        <Form
          labelAlign="left"
          ref={notesRef}
          name="NotesEditForm"
          onFinish={values => onChangeNote({ ...selectedNotes, ...values })}
          initialValues={selectedNotes}
          size="default"
        >
          <h6>{'Label'}:</h6>

          <Form.Item
            name={'label'}
            rules={[{ required: true, message: 'Please fill the Field Name' }]}
            className="w-full"
          >
            <Input placeholder={'Label'} className="w-full" />
          </Form.Item>
          <h6>{props.is_notes ? 'Notes' : 'Terms'}:</h6>

          <Form.Item
            name={'notes'}
            rules={[{ required: true, message: 'Please fill the Field Name' }]}
            className="w-full"
          >
            <TextArea
              rows={7}
              placeholder={props.doc_name + ' ' + props.is_notes ? 'Notes' : 'Terms'}
              className="w-full"
            />
          </Form.Item>
        </Form>
      </Card>
    </Drawer>
  )
})
export default EditNotesForm
