
import { useCallback, useEffect, useState } from "react";
import type {IAfterGuiAttachedParams} from "@ag-grid-community/core";
import type {IDoesFilterPassParams} from "@ag-grid-community/core";
import type { CustomFilterProps } from "@ag-grid-community/react";
import { useGridFilter } from "@ag-grid-community/react";
import { Button, Radio, Space } from "antd";

export default ({ model, onModelChange, api, colDef }: CustomFilterProps) => {

  const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
  const [unappliedModel, setUnappliedModel] = useState(model);

  const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
    return true;
  }, []);

  const afterGuiAttached = useCallback(
    ({ hidePopup }: IAfterGuiAttachedParams) => {
      setCloseFilter(() => hidePopup);
    },
    [],
  );

  // register filter handlers with the grid
  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  useEffect(() => {
    setUnappliedModel(model);
  }, [model]);

  const onFilterChange = (event:any) => {
    const { value } = event.target;
   
    setUnappliedModel(value);
  };

  const onClick = (isApplied:boolean) => {
    if(isApplied){
    onModelChange(unappliedModel);
    }else{
      onModelChange(null)
    }
    if (closeFilter) {
      closeFilter();
    }
  };

  return (
    <div className="m-2 w-max flex flex-col justify-center">
      <Radio.Group className="mb-3" onChange={onFilterChange} value={unappliedModel }>
      <Space direction="vertical">
      {Object.keys(colDef.filterParams.filters ?? {}).map((filterKey:string) => (
        <Radio key={filterKey} value={filterKey}>
          {colDef.filterParams.filters[filterKey]}
        </Radio>
      ))}
    </Space>
      </Radio.Group>
      <Space className="ml-2" direction="horizontal">
      <Button type="secondary" size = "small" onClick={()=>onClick(true)}>
        Apply
      </Button>
      <Button type="secondary" size = "small" onClick={()=>onClick(false)}>
        Reset
      </Button>
      </Space>
    </div>
  );
};
