import { Button, Card, Drawer, Form, FormInstance, Input, InputNumber, message, Select } from 'antd'
import { FormProps } from 'antd/lib'
import { formItemLayout } from 'components/other/utility'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { get_barcode_settings } from 'redux/document/actions'
import { State } from 'redux/types'

type ProductData = {
  barcode_id: string
  product_name: string
  discount_amount?: number
  discount: number
  price_with_tax: number
  price: number
  tax: number
  pkg_date?: string
  exp_date?: string
}
const formItemLayoutTyped = formItemLayout as FormProps
const defaultProductData = {
  barcode_id: '',
  product_name: '',
  discount_amount: 0,
  discount: 0,
  price_with_tax: 0,
  price: 0,
  tax: 0,
}

type BarcodeDetailsProps = {
  showDrawer: boolean
  setShowDrawer: (val: boolean) => void
  data?: ProductData
  backupData?: ProductData
  updateData: (data: ProductData) => void
}

const calculateDiscountAmount = (price: number, discount: number) => {
  return ((price * discount) / 100).toFixed(2)
}

const BarcodeDetails = (props: BarcodeDetailsProps) => {
  const dispatch = useDispatch()
  const formRef = useRef<FormInstance>(null)

  var barcodeSettings = useSelector((state: State) => state.document.barcode_settings)
  if (Object.keys(barcodeSettings)?.length === 0) {
    dispatch(get_barcode_settings())
    barcodeSettings = useSelector((state: State) => state.document.barcode_settings)
  }

  const [price_type, setPriceType] = useState(
    barcodeSettings.show_price_with_tax ? 'price_with_tax' : 'price',
  )

  useEffect(() => {
    formRef.current?.setFieldsValue(props.data ?? {})
  }, [props.updateData])

  const updateValues = (key: keyof ProductData, value: string | number) => {
    const data = {
      ...(props?.data ?? defaultProductData),
    }
    if (key == 'price_with_tax') {
      data.price_with_tax = Number(value)
      data.price = Number(value) - (Number(value) * data.tax) / 100
      data.discount_amount = (data.price_with_tax * data.discount) / 100
    }
    if (key == 'price') {
      data.price = Number(value)
      data.price_with_tax = Number(value) + (Number(value) * data.tax) / 100
    }
    if (key == 'discount') {
      data.discount = Number(value)
      data.discount_amount = (data.price_with_tax * data.discount) / 100
    }
    if (key == 'discount_amount') {
      data.discount_amount = Number(value) ?? 0
      data.discount = (data.discount_amount * 100) / (data.price_with_tax ?? 1)
    }
    formRef.current?.setFieldsValue(data)
    props.updateData(data)
  }

  return (
    <div>
      <div>
        <Drawer
          title="Barcode Details"
          width={isMobile ? '100%' : '45%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark" />}
          closable={true}
          onClose={() => {
            props.setShowDrawer(false)
          }}
          open={props.showDrawer}
        >
          <Card>
            <Form
              {...formItemLayoutTyped}
              labelAlign="left"
              ref={formRef}
              name="BarcodeDetails"
              onFinishFailed={() => message.error('Check All Details Again')}
              initialValues={{
                ...(props.data ?? defaultProductData),
                discount_amount:
                  props?.data?.discount_amount ??
                  calculateDiscountAmount(
                    props?.data?.price_with_tax ?? 0,
                    props?.data?.discount ?? 0,
                  ),
              }}
            >
              <Form.Item
                name="product_name"
                label={'Product Name'}
                rules={[{ required: true, message: 'Please fill Product Name' }]}
              >
                <Input
                  placeholder="Product Name"
                  onChange={e => {
                    props.updateData({
                      ...(props?.data ?? defaultProductData),
                      product_name: e.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name={price_type} label="Price">
                <InputNumber
                  min={0}
                  placeholder="Price"
                  style={{ width: '100%' }}
                  addonAfter={
                    <Select
                      defaultValue={price_type}
                      style={{ width: 100 }}
                      onChange={value => {
                        setPriceType(value)
                      }}
                    >
                      <Select.Option value="price_with_tax">With Tax</Select.Option>
                      <Select.Option value="price">Without Tax</Select.Option>
                    </Select>
                  }
                  onChange={e => {
                    if (price_type === 'price_with_tax') {
                      updateValues('price_with_tax', e ?? 0)
                    } else {
                      updateValues('price', e ?? 0)
                    }
                  }}
                />
              </Form.Item>

              <Form.Item name="discount" label="Discount (%)">
                <InputNumber
                  min={0}
                  addonAfter="%"
                  placeholder="Discount"
                  style={{ width: '100%' }}
                  onChange={e => {
                    updateValues('discount', e ?? 0)
                  }}
                />
              </Form.Item>
              <Form.Item name="discount_amount" label="Discount Amount">
                <InputNumber
                  min={0}
                  placeholder="Discount Amount"
                  style={{ width: '100%' }}
                  onChange={e => {
                    updateValues('discount_amount', e ?? 0)
                  }}
                />
              </Form.Item>
              <Form.Item name="pkg_date" label={'Package Date'}>
                <Input
                  placeholder="DD-MMM-YY"
                  onChange={e => {
                    props.updateData({
                      ...(props?.data ?? defaultProductData),
                      pkg_date: e.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name="exp_date" label={'Expiry Date'}>
                <Input
                  placeholder="DD-MMM-YY"
                  onChange={e => {
                    props.updateData({
                      ...(props?.data ?? defaultProductData),
                      exp_date: e.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Button
                // @ts-ignore
                type="danger"
                className="mt-3"
                onClick={() => {
                  const data = { ...(props.backupData ?? defaultProductData) }
                  props.updateData(data)
                  formRef.current?.setFieldsValue(data)
                }}
              >
                {'Reset'}
              </Button>
              <Button
                // @ts-ignore
                type="primary"
                className="mt-3 ml-3"
                onClick={() => {
                  props.setShowDrawer(false)
                }}
              >
                Done
              </Button>
              <p className="mt-1 text-gray-500 font-size-12">
                The modified details will be used for the current barcode generation and will not be
                updated in the master product.{' '}
              </p>
            </Form>
          </Card>
        </Drawer>
      </div>
    </div>
  )
}

export default BarcodeDetails
