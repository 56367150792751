import { StyleSheet } from '@react-pdf/renderer'

export const styles = invoice => {
  let FONT_FACTOR = invoice.invoice_settings.font_size
  let PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  // To select font style if language is English
  if (locale == 'English') {
    locale = font_style
  }

  return StyleSheet.create({
    globalContainer: {
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: '#1d1d1f',
    },

    global_mt: {
      marginTop: 3.6 * PAPER_SIZE,
    },

    global_borderTop: {
      borderTopWidth: 1,
      borderColor: '#1d1d1f',
    },

    // COMPANY
    company_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
    },
    company_blockC2: {
      flex: 1,
    },
    company_blockC50: {
      width: '50%',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 2.7 * PAPER_SIZE,
    },
    company_blockC100: {
      width: '100%',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 5.4 * PAPER_SIZE,
    },
    company_blockCA: {
      width: '64%',
      textAlign: 'center',
    },
    company_blockCB: {
      width: '18%',
    },
    company_borderTop: {
      borderTopWidth: 0.72,
    },
    company_borderBottom: {
      borderBottomWidth: 0.72,
    },
    company_borderRight: {
      borderRightWidth: 0.72,
    },
    company_borderLeft: {
      borderLeftWidth: 0.72,
    },
    company_block: {
      flexDirection: 'row',
    },
    company_blockRow: {
      flexDirection: 'row',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 2.7 * PAPER_SIZE,
    },
    company_blockCol: {
      flexDirection: 'column',
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 5.4 * PAPER_SIZE,
    },
    company_block3: {
      marginRight: 75 * PAPER_SIZE,
    },
    company_invoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    company_text: {
      fontSize: 8.35 * FONT_FACTOR,
      color: '#141414',
    },
    company_text2: {
      marginTop: 2 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    company_info: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
    },
    company_label: {
      fontSize: 8.1 * FONT_FACTOR,
      color: '#141414',
      fontFamily: `helmed_${locale}`,
      // fontFamily: `helitalic_${locale}`,
    },
    company_address: {
      fontSize: 8.1 * FONT_FACTOR,
    },
    company_row: {
      width: '100%',
      flexDirection: 'row',
    },
    company_labels: {
      width: '40%',
      color: '#141414',
      fontFamily: `helmed_${locale}`,
      textAlign: 'left',
      paddingRight: 9 * PAPER_SIZE,
      fontSize: 8.1 * FONT_FACTOR,
    },
    company_info2: {
      width: '60%',
      fontFamily: `helmed_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'left',
      color: '#141414',
    },
    company_column: {
      width: '100%',
      flexDirection: 'column',
      borderTopWidth: 1,
      borderColor: '#141414',
    },
    company_bold: {
      fontFamily: `helbold_${locale}`,
    },
    company_large: {
      fontSize: 10.8 * FONT_FACTOR,
    },
    company_logo: {
      maxHeight: '63',
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginLeft: 4.5 * PAPER_SIZE,
      marginVertical: 2.7 * PAPER_SIZE,
    },
    company_qrcode: {
      // width: '36%',
      position: 'relative',
      textAlign: 'left',
      marginTop: 2 * PAPER_SIZE,
      marginHorizontal: 'auto',
      marginRight: 4.5 * PAPER_SIZE,
      marginVertical: 2.7 * PAPER_SIZE,
    },
    company_irn: {
      fontSize: 6.3,
      color: '#141414',
    },
    company_qr_header_off: {
      width: '36%',
      textAlign: 'left',
      position: 'relative',
      marginHorizontal: 'auto',
      marginLeft: 0 * PAPER_SIZE,
      marginVertical: 2.7 * PAPER_SIZE,
    },

    // INDEX
    index_page: {
      fontSize: 9 * FONT_FACTOR,
      paddingHorizontal: 25 * PAPER_SIZE,
      lineHeight: 1.3,
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      color: '#141414',
    },
    index_pageGrow: {
      display: 'flex',
      justifyContent: 'flex-start',
      height: '100%', // Ensures the page fills the full height
    },
    index_grow: {
      flexGrow: 1, // Takes up remaining space
      flexDirection: 'column', // Arrange children vertically
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: '#1d1d1f',
    },
    index_bottomBorderGrow: {
      flexShrink: 1, // Takes up remaining space
      flexDirection: 'column', // Arrange children vertically
    },
    index_bottomBorder: {
      position: 'absolute', // Ensure it stays at the bottom of the page
      bottom: 0, // Align it to the bottom
      left: 0, // Start from the left edge
      right: 0, // Stretch to the right edge
      borderBottomWidth: 1, // Border width
      borderBottomColor: '#1d1d1f', // Border color (adjust as needed)
    },
    index_logo: {
      width: 'auto',
      height: '36',
      position: 'relative',
      marginRight: 'auto',
      textAlign: 'left',
      marginBottom: 5 * PAPER_SIZE,
    },
    index_footer: {
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      width: '100%',
      left: 24 * PAPER_SIZE,
    },
    index_footerNote: {
      fontSize: 8.1 * FONT_FACTOR,
      bottom: 18 * PAPER_SIZE,
      left: 24 * PAPER_SIZE,
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      marginTop: 0,
    },
    index_alignRight: {
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'right',
    },
    index_brandingFooter: {
      alignContent: 'bottom',
      position: 'absolute',
      fontFamily: `helmed_${locale}`,
      bottom: 27 * PAPER_SIZE,
      left: 24 * PAPER_SIZE,
      width: '100%',
      flexDirection: 'row',
      marginRight: 4.5 * PAPER_SIZE,
    },
    index_brandingLogo: {
      width: '100%',
      textAlign: 'right',
    },
    index_logo: {
      width: 'auto',
      height: 27 * PAPER_SIZE,
      position: 'relative',
      marginHorizontal: 'auto',
      marginRight: 0,
    },
    index_banner: {
      width: '100%',
      maxHeight: '72',
      position: 'relative',
      textAlign: 'right',
      paddingVertical: 2.7 * PAPER_SIZE,
      marginHorizontal: 'auto',
      marginLeft: 0,
    },
    index_watermark: {
      alignContent: 'center',
      position: 'absolute',
      bottom: '20%',
      left: '3%',
      width: '100%',
      opacity: 0.072 * PAPER_SIZE,
    },

    //BANK and NOTES
    invoiceBankDetails_headerContainer: {
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      marginBottom: '22',
      lineHeight: 1.26,
    },
    invoiceBankDetails_headerContainer2: {
      flexDirection: 'column',
      fontFamily: `helreg_${locale}`,
      marginBottom: '9',
      lineHeight: 1.26,
    },
    invoiceBankDetails_blockC: {
      width: '100%',
      flexDirection: 'row',
      alignContent: 'center',
    },
    invoiceBankDetails_blockCA: {
      width: '48%',
      paddingVertical: 1.8 * PAPER_SIZE,
      paddingHorizontal: 5.4 * PAPER_SIZE,
    },
    invoiceBankDetails_blockCB: {
      width: '16%',
      paddingVertical: 1.8 * PAPER_SIZE,
      paddingRight: 5.4 * PAPER_SIZE,
    },
    invoiceBankDetails_blockCC: {
      width: '36%',
      paddingVertical: 1.8 * PAPER_SIZE,
      paddingLeft: 5.4 * PAPER_SIZE,
    },
    invoiceBankDetails_blockCD: {
      width: '100%',
      display: 'flex',
      padding: 5.4 * PAPER_SIZE,
    },
    invoiceBankDetails_blockC50: {
      width: '50%',
      display: 'flex',
      padding: 5.4 * PAPER_SIZE,
    },

    invoiceBankDetails_borderTop: {
      borderTopWidth: 0.72,
    },
    invoiceBankDetails_borderBottom: {
      borderBottomWidth: 1,
    },
    invoiceBankDetails_borderRight: {
      borderRightWidth: 0.72,
    },
    invoiceBankDetails_borderLeft: {
      borderLeftWidth: 0.72,
    },
    invoiceBankDetails_block: {
      flexDirection: 'column',
    },
    invoiceBankDetails_block3: {
      marginRight: 75 * PAPER_SIZE,
    },
    invoiceBankDetails_invoiceContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    invoiceBankDetails_bankRow: {
      flexDirection: 'row',
      paddingVertical: 0.45 * PAPER_SIZE,
      paddingHorizontal: 0,
    },
    invoiceBankDetails_labels: {
      width: '25%',
      color: '#333333',
      fontFamily: `helreg_${locale}`,
      paddingRight: 4.5,
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceBankDetails_info: {
      width: '75%',
      fontFamily: `helmed_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'left',
      color: '#141414',
    },
    invoiceBankDetails_text: {
      fontSize: 7.2 * FONT_FACTOR,
      color: '#141414',
    },
    invoiceBankDetails_label: {
      fontSize: 7.2 * FONT_FACTOR,
      color: '#333333',
      textAlign: 'left',
      fontFamily: `helbold_${locale}`,
      marginBottom: 0.9 * PAPER_SIZE,
      // fontFamily: `helitalic_${locale}`,
    },
    invoiceBankDetails_address: {
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceBankDetails_column: {
      width: '100%',
      flexDirection: 'column',
    },
    invoiceBankDetails_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceBankDetails_large: {
      fontSize: 9 * FONT_FACTOR,
    },
    invoiceBankDetails_signature: {
      marginTop: 2.7 * PAPER_SIZE,
      height: '54',
      position: 'relative',
      textAlign: 'right',
      marginHorizontal: 'auto',
      marginRight: 4.5 * PAPER_SIZE,
    },
    invoiceBankDetails_block2: {
      width: '64',
      marginRight: 27 * PAPER_SIZE,
    },
    // invoiceBankDetails_bankNotes: {
    //   fontSize: 8.35 * FONT_FACTOR,
    //   marginTop: 2.7 * PAPER_SIZE,
    //   paddingRight: 4.5,
    //   color: '#333333',
    //   textAlign: 'left',
    // },
    invoiceBankDetails_upi: {
      position: 'relative',
      width: '4%',
      textAlign: 'left',
    },
    invoiceBankDetails_label2: {
      fontSize: 7.2 * FONT_FACTOR,
      color: '#333333',
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helreg_${locale}`,
    },

    invoiceNotes_receiveSignBlock: {
      width: '50%',
      textAlign: 'right',
      marginVertical: 'auto',
      marginBottom: 0,
    },
    invoiceNotes_receiveSignText: {
      fontSize: 8.1 * FONT_FACTOR,
      paddingTop: 2.7,
      paddingLeft: 2.7,
      marginTop: 27,
      borderTopWidth: 1,
      borderColor: '#333333',
      textAlign: 'left',
    },

    //ITEMS TABLE
    invoiceItemsTable_borderLeft: {
      borderWidth: 1,
      borderColor: '#1d1d1f',
    },
    invoiceItemsTable_tableContainer: {
      flexDirection: 'column',
      // flexWrap: 'wrap',
    },
    invoiceItemsTable_tableContainerGrow: {
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      // flexWrap: 'wrap',
    },
    invoiceItemsTable_amountinwords: {
      textAlign: 'left',
      fontSize: 8 * FONT_FACTOR,
      paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingTop: 0.9 * PAPER_SIZE,
    },
    invoiceItemsTable_balance: {
      paddingVertical: 2.7 * PAPER_SIZE,
      color: '#343435',
    },
    invoiceItemsTable_row1: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      height: 22 * PAPER_SIZE,
      fontSize: 11 * FONT_FACTOR,
      marginBottom: 1 * PAPER_SIZE,
    },
    invoiceItemsTable_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceItemsTable_italic: {
      fontFamily: `helitalic_${locale}`,
      textAlign: 'right',
    },
    invoiceItemsTable_dc_totals: {
      paddingVertical: 2.7 * PAPER_SIZE,
      textAlign: 'right',
      paddingRight: 4.5,
      color: '#343435',
    },
    invoiceItemsTable_borderTop: {
      borderTopWidth: 0.5,
      borderColor: '#1d1d1f',
    },
    invoiceItemsTable_amountPaid: {
      textAlign: 'right',
      fontSize: 9 * FONT_FACTOR,
      marginLeft: 13.5 * PAPER_SIZE,
      paddingRight: 4.5,
      marginTop: 0.9 * PAPER_SIZE,
      color: '#343435',
    },
    invoiceItemsTable_image: {
      width: 9.9 * PAPER_SIZE,
      height: 9.9 * PAPER_SIZE,
      position: 'absolute',
      textAlign: 'right',
      marginTop: 1.8 * PAPER_SIZE,
    },
    invoiceItemsTable_block: {
      flexDirection: 'row',
      marginTop: 0.9 * PAPER_SIZE,
    },
    invoiceItemsTable_balanceBlock: {
      marginVertical: 3.6 * PAPER_SIZE,
      borderBottomWidth: 1,
      borderColor: '#1d1d1f',
    },
    invoiceItemsTable_labels: {
      width: '78%',
      color: '#515154',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceItemsTable_info: {
      width: '22%',
      fontSize: 8.1 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#4F5454',
    },
    invoiceItemsTable_labels2: {
      width: '78%',
      color: '#343435',
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceItemsTable_payments: {
      width: '78%',
      color: '#343435',
      textAlign: 'right',
      paddingRight: 4.5,
      fontFamily: `helmed_${locale}`,
      fontSize: 8.1 * FONT_FACTOR,
    },
    invoiceItemsTable_info2: {
      width: '22%',
      fontSize: 8.1 * FONT_FACTOR,
      paddingRight: 4.5,
      textAlign: 'right',
      fontFamily: `helbold_${locale}`,
      color: '#343435',
    },
    invoiceItemsTable_borderBlock: {
      width: '64%',
    },

    // TABLE FOOTER
    invoiceTableFooter_row: {
      flexDirection: 'row',
      fontSize: 9.9 * FONT_FACTOR,
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
      borderBottomWidth: 1,
      borderBottomColor: '#6c7c83',
    },
    invoiceTableFooter_cell: {
      paddingLeft: 4.5,
      paddingVertical: 1.8 * PAPER_SIZE,
      paddingRight: 3.6 * PAPER_SIZE,
      borderRightWidth: 0.36,
      borderRightColor: '#6c7c83',
    },
    invoiceTableFooter_row1: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      height: 18 * PAPER_SIZE,
      fontSize: 12.6 * FONT_FACTOR,
      borderBottomWidth: 0.5,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_row2: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      height: 13 * PAPER_SIZE,
      fontSize: 8.5 * FONT_FACTOR,
      marginBottom: 1 * PAPER_SIZE,
      borderBottomWidth: 0.5,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_row3: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
      alignItems: 'center',
      height: 13 * PAPER_SIZE,
      fontSize: 8.5 * FONT_FACTOR,
      borderBottomWidth: 0.5,
      borderColor: '#1d1d1f',
    },
    invoiceTableFooter_block: {
      width: '72%',
      borderBottomWidth: 0.5,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_block2: {
      width: '28%',
      borderBottomWidth: 0.5,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableFooter_description: {
      width: '78%',
      textAlign: 'right',
      paddingRight: 0,
    },
    invoiceTableFooter_description2: {
      width: '58%',
      textAlign: 'right',
      paddingRight: 0,
    },
    invoiceTableFooter_total: {
      width: '22%',
      textAlign: 'right',
      paddingRight: 4.5,
    },
    invoiceTableFooter_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceTableFooter_italic: {
      fontFamily: `helitalic_${locale}`,
    },
    invoiceItemsTable_borderBlock2: {
      width: '36%',
      textAlign: 'right',
      borderBottomWidth: 0.2,
      borderColor: '#515154',
      marginTop: 2 * PAPER_SIZE,
    },
    invoiceItemsTable_BatchName: {
      fontSize: 7.5 * FONT_FACTOR,
      color: '#4F5454',
      display: 'block',
    },

    // TABLE BLANKSPACE
    invoiceTableBlankSpace_row: {
      flexDirection: 'row',
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'center',
      fontFamily: `helreg_${locale}`,
    },
    invoiceTableBlankSpace_container: {
      flexGrow: 1,
      flexDirection: 'column',
    },
    invoiceTableBlankSpace_cell: {
      // paddingHorizontal: 4.5 * PAPER_SIZE,
      paddingVertical: 0.9 * PAPER_SIZE,
      paddingRight: 3.6 * PAPER_SIZE,
      borderRightWidth: 0.36,
      borderRightColor: '#6c7c83',
    },
    invoiceTableBlankSpace_block2: {
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: '#1d1d1f',
    },
    invoiceTableBlankSpace_italic: {
      fontFamily: `helitalic_${locale}`,
    },
    invoiceTableBlankSpace_mb: {
      marginBottom: 2.7 * PAPER_SIZE,
    },

    // TABLE HEADER
    invoiceTableHeader_container: {
      flexDirection: 'row',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: '#1d1d1f',
      color: '#141414',
      fontSize: 8.1 * FONT_FACTOR,
      fontFamily: `helmed_${locale}`,
    },
    invoiceTableHeader_cell: {
      paddingLeft: 4.5,
      paddingVertical: 2.7 * PAPER_SIZE,
      paddingRight: 3.6 * PAPER_SIZE,
      borderRightWidth: 0.36,
      borderRightColor: '#6c7c83',
    },
    invoiceTableHeader_italic: {
      fontFamily: `helitalic_${locale}`,
    },
    invoiceTableHeader_slno_dc: {
      width: '5%',
      paddingLeft: '3px',
      textAlign: 'left',
    },
    invoiceTableHeader_description_dc: {
      fontFamily: `helmed_${locale}`,
      width: '78%',
      textAlign: 'left',
      paddingRight: 4 * PAPER_SIZE,
    },
    invoiceTableHeader_qty_dc: {
      width: '17%',
      textAlign: 'right',
      paddingRight: 4 * PAPER_SIZE,
    },
    // ITEM HEADER
    invoiceItemHeader: {
      color: '#141414',
      fontSize: 8.1 * FONT_FACTOR,
      fontFamily: `helmed_${locale}`,
      paddingVertical: 0.5 * PAPER_SIZE,
      flexDirection: 'row',
      width: '100%',
      textAlign: 'left',
      textOverflow: 'wrap',
      backgroundColor: '#f9f9f9',
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },

    // TABLE ROW
    invoiceTableRow_row: {
      flexDirection: 'row',
      fontFamily: `helreg_${locale}`,
      textOverflow: 'wrap',
      // paddingVertical: 0.5 * PAPER_SIZE,
    },
    invoiceTableRow_cell: {
      paddingLeft: 4.5,
      paddingVertical: 0.5 * PAPER_SIZE,
      paddingRight: 3.6 * PAPER_SIZE,
      borderRightWidth: 0.36,
      borderRightColor: '#6c7c83',
    },
    invoiceTableRow_bold: {
      fontFamily: `helbold_${locale}`,
    },
    invoiceTableRow_med: {
      fontFamily: `helmed_${locale}`,
    },
    invoiceTableRow_italic: {
      fontFamily: `helitalic_${locale}`,
    },
    invoiceTableRow_slno_dc: {
      width: '5%',
      paddingLeft: '3px',
      textAlign: 'left',
    },
    invoiceTableRow_description_dc: {
      fontFamily: `helmed_${locale}`,
      width: '78%',
      textAlign: 'left',
      paddingRight: 4 * PAPER_SIZE,
    },
    invoiceTableRow_qty_dc: {
      width: '17%',
      textAlign: 'right',
      paddingRight: 4 * PAPER_SIZE,
    },
    // invoiceTableRow_border: {
    //   borderLeftWidth: 1,
    //   borderRightWidth: 1,
    //   borderColor: '#141414',
    // },
    invoiceTableRow_image: {
      maxWidth: 54 * PAPER_SIZE,
      maxHeight: 54 * PAPER_SIZE,
      borderRadius: 5.4,
      position: 'relative',
      textAlign: 'center',
      marginHorizontal: 'auto',
      marginLeft: 0,
      marginRight: 3.6 * PAPER_SIZE,
      marginVertical: 2.7 * PAPER_SIZE,
    },

    // TITLE
    invoiceTitle_row: {
      flexDirection: 'row',
    },
    invoiceTitle_labels: {
      width: '40%',
      textAlign: 'center',
      fontFamily: `helbold_${locale}`,
      letterSpacing: 2 * PAPER_SIZE,
      bottom: 1.8 * PAPER_SIZE,
      fontSize: 9.9 * FONT_FACTOR,
      textTransform: 'uppercase',
    },
    invoiceTitle_info: {
      width: '30%',
      fontFamily: `helmed_${locale}`,
      fontSize: 7.2 * FONT_FACTOR,
      paddingRight: 2.7 * PAPER_SIZE,
      letterSpacing: 1 * PAPER_SIZE,
      textAlign: 'right',
      color: '#333333',
    },
    invoiceTitle_dec: {
      width: '100%',
      fontFamily: `helmed_${locale}`,
      fontSize: 7 * FONT_FACTOR,
      letterSpacing: 0,
      color: '#333',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
  })
}
