import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Upload,
  message,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import CustomColumnsForm from 'components/forms/customColumns'
import InvoiceThemeForm from 'components/forms/invoiceTheme'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'
import store from 'store'
// import GoogleLocation from '../modal/googleProfile'
import ImgCrop from 'antd-img-crop'
import ProBadge from 'components/badges/pro'
import CustomNotes from 'components/forms/customNotesForm'
import SectionHeader from 'components/other/sectionHeader'
import { formItemLayout, getBase64, presets, scrollAndHighlight } from 'components/other/utility'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { get_invoice_settings } from 'redux/document/actions'
import DocumentPrefixes, { PrefixForm } from './documentPrefixes'
import InvoiceLabels from './invoiceLabels'
import FeatureAction from 'components/cleanui/cards/featureAction'
import ShareTemplates from './shareTemplates'
import NotesTermsDrawer from 'components/forms/notesTermsDrawer'
import { withRouter } from 'react-router-dom'
import { isEqual } from 'lodash'
import ExitSaveModal from 'components/modal/exitSaveModal'
import imageCompression from 'browser-image-compression'

const { Option } = Select
const keys = [
  'show_images',
  'show_net_balance',
  'show_due_date',
  'show_dispatch_address',
  'show_payments',
  'show_round_off',
  'hide_qty',
  'qty_decimals_3',
  'hide_discount',
  'show_qty_conversion_rate',
  'show_receivers_signature',
  'locale',
  'font_style',
  'repeat_header',
  'hide_hsn',
  'full_page',
  'color',
  'show_conversion_factor',
  'show_both_currencies',
  'show_company_details',
  'show_hsn_details',
  'pdf_orientation',
  'price_max_decimals',
  'invoice_footer',
  'pos_footer',
  'font_size',
  'paper_size',
  'margin_top',
  'margin_bottom',
  'margin_left',
  'margin_right',
  'header_image',
  'footer_image',
  'watermark',
  'show_google_reviews_invoice',
  'show_google_reviews_pos',
  'show_discount_col',
  'banner_image_1',
  'banner_image_2',
]

class InvoiceSettingsForm extends Component {
  formRef = React.createRef()
  formRef2 = React.createRef()
  customNotesRef = React.createRef()
  documentPrefixesRef = React.createRef()
  invoiceLabelsRef = React.createRef()
  prefixFormRef = React.createRef()
  // googleLocation=React.createRef()
  subscriptionPlanRef = React.createRef()
  templatesRef = React.createRef()
  document_types = [
    { name: 'All', document_type: 'all' },
    { name: 'Invoices', document_type: 'invoice' },
    { name: 'Purchases', document_type: 'purchase' },
    { name: 'Sales Returns', document_type: 'sales_return' },
    { name: 'Purchase Returns', document_type: 'purchase_return' },
    { name: 'Purchase Orders', document_type: 'purchase_order' },
    { name: 'Quotation', document_type: 'estimate' },
    { name: 'Pro Forma Invoice', document_type: 'pro_forma_invoice' },
    { name: 'Delivery Challan', document_type: 'delivery_challan' },
  ]

  constructor(props) {
    super(props)
    this.state = {
      settingData: '',
      settingDataCustomColumns: '',
      displayColorPicker: false,
      color: '#fff',
      paid: false,
      num_invoices: false,
      invoice_templates: [],
      selected_invoice_template: 1,
      selected_purchase_template: 1,
      selected_estimate_template: 1,
      prev_settings: {},
      key: 0,
      selectedLanguage: '',
      languages: [],
      font_styles: [
        {
          name: 'stylish',
          value: 'Stylish',
        },
        {
          name: 'retro',
          value: 'Retro',
        },
        {
          name: 'compact',
          value: 'Compact',
        },
      ],
      // location: '',
      // place_id: '',
      invoice_header_image: '',
      invoice_footer_image: '',
      banner_image_1: '',
      banner_image_2: '',
      watermark: '',
      image_type: '',
      prefixes: [],
      is_encrypted: true,
      empty_prefix: {
        id: -1,
        prefix: '',
        document_type: '',
        is_default: false,
        is_delete: false,
      },
      unChangedValues: {},
      enable_item_headers_toggled: false,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }
  handleLocationChange = async () => {
    const queryParams = new URLSearchParams(this.props.location.search)
    const highlightElement = queryParams.get('highlight')

    if (highlightElement) {
      scrollAndHighlight(highlightElement)
    }
  }

  onFinishDocumentSettings = async values => {
    const req = {
      ...values,
      is_web: 1,
      invoice_prefix: this.state.settingData.invoice_prefix,
      po_prefix: this.state.settingData.po_prefix,
      purchase_invoice_prefix: this.state.settingData.purchase_invoice_prefix,
      estimate_prefix: this.state.settingData.estimate_prefix,
      sales_return_prefix: this.state.settingData.sales_return_prefix,
      purchase_return_prefix: this.state.settingData.purchase_return_prefix,
      delivery_challan_prefix: this.state.settingData.delivery_challan_prefix,
      pro_forma_invoice_prefix: this.state.settingData.pro_forma_invoice_prefix,
      subscription_prefix: this.state.settingData.subscription_prefix,
      enable_item_headers: !this.state.enable_item_headers_toggled
        ? ''
        : values.enable_item_headers && values.enable_item_headers.length > 0
        ? values.enable_item_headers.join(',')
        : '',
    }

    // console.log('req:::::::', req)
    // return

    if (!values.color.startsWith('#')) {
      req.color = '#' + values.color
    }
    if (req.color.length <= 7) {
      for (let i = req.color.length; i < 7; i++) {
        req.color = '0' + req.color
      }
    } else {
      message.error('Please enter valid color code')
      return true
    }

    if (req.margin_top > 250 || req.margin_bottom > 250) {
      message.error('Margin top and bottom should be less than or equal to 250')
      return true
    }

    const data = await getAPI('utils', 'invoice_settings', req)
    if (data && data.success) {
      message.success(data.message)
      this.props.dispatch(get_invoice_settings())
      this.setState({ settingsVisible: false })
    }
  }
  onClose = () => {
    let currentData = this.formRef.current.getFieldsValue()
    let currentDataExceptEnableItemHeaders = { ...currentData }
    try {
      delete currentDataExceptEnableItemHeaders.enable_item_headers
    } catch (e) {}
    for (let key in currentDataExceptEnableItemHeaders) {
      if (typeof currentDataExceptEnableItemHeaders[key] == 'boolean') {
        currentDataExceptEnableItemHeaders[key] = currentDataExceptEnableItemHeaders[key] ? 1 : 0
      }
    }

    if (!isEqual(currentDataExceptEnableItemHeaders, this.state.unChangedValues)) {
      ExitSaveModal({
        onConfirm: () => {
          this.formRef.current.submit()
        },
        onCancel: () => {
          this.setState({ settingsVisible: false })
        },
      }).showModal()
    } else {
      this.setState({ settingsVisible: false })
    }
  }

  onOpen = async () => {
    // await this.componentDidMount()
    var selected_country_code = this.props?.user?.selectedCompany?.country_code

    var data = await getAPIData(
      'utils',
      'invoice_settings' + `?country_code=${selected_country_code}`,
    )

    if (data) {
      let unChangedValues = {}
      keys.map(key => {
        unChangedValues[key] = data.invoice_settings[key]
      })
      this.setState(
        {
          settingData: {
            ...data.invoice_settings,
            enable_item_headers:
              data.invoice_settings.enable_item_headers != ''
                ? data.invoice_settings.enable_item_headers?.split(',')
                : [],
          },
          unChangedValues: unChangedValues,
          color: data.invoice_settings.color,
          num_invoices: data.num_invoices == 0 ? true : false,
          paid: data.paid == 1 ? true : false,
          invoice_templates: data.invoice_templates,
          selected_invoice_template: data.invoice_settings.invoice_template,
          selected_purchase_template: data.invoice_settings.purchase_template,
          selected_estimate_template: data.invoice_settings.estimate_template,
          invoice_header_image: data.invoice_settings.invoice_header_image,
          invoice_footer_image: data.invoice_settings.invoice_footer_image,
          banner_image_1: data.invoice_settings.banner_image_1,
          banner_image_2: data.invoice_settings.banner_image_2,
          watermark: data.invoice_settings.watermark,
          prefixes: data.prefixes,
          languages: data.languages,
          is_encrypted: data.invoice_settings.is_encrypted == 1 ? true : false,
          selectedLanguage: data.invoice_settings.locale,
          enable_item_headers_toggled:
            data.invoice_settings.enable_item_headers != '' ? true : false,
        },
        () => {
          this.formRef.current?.setFieldsValue({
            ...this.state.settingData,
          })
          this.handleLocationChange()
        },
      )
    }
    // var data = await getAPIData('v2/gservices', 'placeid')
    // if (data?.place?.length > 0) {
    //   this.setState({ place_id: data.place[0].place_id, location: data.place[0].name })
    // }
    this.setState({ settingsVisible: true })
  }

  handleChange = color => {
    this.setState({ color: color.toHexString() }, () => {
      this.formRef.current.setFieldsValue({
        color: color.toHexString(),
      })
    })
  }
  handlePreview = async file => {
    // console.log(file)

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  customRequest = async ({ onSuccess, onError, file }) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file)
    }
    const compressedBlob = await imageCompression(
      new File(
        [await fetch(file.url || file.preview).then(res => res.blob())],
        file.name || 'image.png',
        {
          type: file.type || 'image/png',
        },
      ),
      { maxSizeMB: 1, useWebWorker: true },
    )

    const compressedFile = new File([compressedBlob], file.name, {
      type: compressedBlob?.type ?? 'image/png',
    })
    const compressedFinalSignature = await getBase64(compressedFile)
    const form = new FormData()
    form.append('file', compressedFile)
    form.append('image_type', this.state.image_type)
    form.append('delete', 0)

    const data = await getMultipartAPI('user', 'update_invoice_banners', form)
    if (data?.success) {
      onSuccess(null, compressedFile)
      if (this.state.image_type == 'header') {
        this.setState({ invoice_header_image: file.url || compressedFinalSignature })
      } else if (this.state.image_type == 'footer') {
        this.setState({ invoice_footer_image: file.url || compressedFinalSignature })
      } else if (this.state.image_type == 'watermark') {
        this.setState({ watermark: file.url || compressedFinalSignature })
      } else if (this.state.image_type == 'banner_image_1') {
        this.setState({ banner_image_1: file.url || compressedFinalSignature })
      } else if (this.state.image_type == 'banner_image_2') {
        this.setState({ banner_image_2: file.url || compressedFinalSignature })
      }

      message.success({
        content: data.message,
        style: {
          color: 'success',
        },
      })
    } else {
      message.error({
        content: data.message,
        style: {
          color: 'red',
        },
      })
      onError()
    }
  }
  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    return isJpgOrPng
  }

  onRemove = async file => {
    const form = new FormData()
    form.append('image_type', this.state.image_type)
    form.append('delete', 1)

    const data = await getMultipartAPI('user', 'update_invoice_banners', form)
    if (data?.success) {
      message.success(data.message)
      if (this.state.image_type == 'header') {
        this.setState({ invoice_header_image: '' })
      } else if (this.state.image_type == 'footer') {
        this.setState({ invoice_footer_image: '' })
      } else if (this.state.image_type == 'watermark') {
        this.setState({ watermark: '' })
      } else if (this.state.image_type == 'banner_image_1') {
        this.setState({ banner_image_1: '' })
      } else if (this.state.image_type == 'banner_image_2') {
        this.setState({ banner_image_2: '' })
      }
    }
  }

  handleCancel = () => this.setState({ previewVisible: false })

  refreshPrefix = async () => {
    const data = await getAPI('utils', 'get_document_prefix', { document_type: 'all' })
    if (data) {
      this.setState({ prefixes: data.prefixes })
      let prefixes = data.prefixes
      this.formRef?.current?.setFieldsValue({
        invoice_prefix: prefixes.filter(
          item => item.document_type == 'invoice' && item.is_default == 1,
        )[0]?.prefix,
        estimate_prefix: prefixes.filter(
          item => item.document_type == 'estimate' && item.is_default == 1,
        )[0]?.prefix,
        pro_forma_invoice_prefix: prefixes.filter(
          item => item.document_type == 'pro_forma_invoice' && item.is_default == 1,
        )[0]?.prefix,
        purchase_invoice_prefix: prefixes.filter(
          item => item.document_type == 'purchase' && item.is_default == 1,
        )[0]?.prefix,
        purchase_return_prefix: prefixes.filter(
          item => item.document_type == 'purchase_return' && item.is_default == 1,
        )[0]?.prefix,
        sales_return_prefix: prefixes.filter(
          item => item.document_type == 'sales_return' && item.is_default == 1,
        )[0]?.prefix,
        delivery_challan_prefix: prefixes.filter(
          item => item.document_type == 'delivery_challan' && item.is_default == 1,
        )[0]?.prefix,
        po_prefix: prefixes.filter(item => item.document_type == 'purchase_order')[0]?.prefix,
        subscription_prefix: prefixes.filter(
          item => item.document_type == 'subscription' && item.is_default == 1,
        )[0]?.prefix,
      })
    }
  }

  handleLanguageChange = value => {
    this.setState({ selectedLanguage: value })
  }
  handleEnableItemHeaders = e => {
    if (store.get('paid') == 0) {
      this.subscriptionPlanRef.current.openModal('item_headers')
      return
    }

    this.setState({ enable_item_headers_toggled: e })
    // set 'all' by default if toggled on
    if (e) {
      this.formRef.current.setFieldsValue({
        enable_item_headers: ['all'],
      })
    }
  }

  refreshSelectedTemplates = ({ invoice_template, purchase_template, estimate_template }) => {
    this.setState({
      selected_invoice_template: invoice_template,
      selected_purchase_template: purchase_template,
      selected_estimate_template: estimate_template,
    })
  }

  get_prefix_drop_down = (document_type, placeholder) => {
    return (
      <Select
        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
        placeholder={placeholder}
        style={{ width: '100%' }}
        dropdownRender={menu => (
          <>
            {menu}
            <div className="">
              <Button
                block
                size=""
                type="link-2"
                className="font-weight-bolder text-left my-2 arrow-transition"
                onMouseDown={e => e.preventDefault()}
                onClick={() => {
                  this.state.empty_prefix.document_type = document_type
                  this.prefixFormRef.current?.show_prefix_form(this.state.empty_prefix)
                }}
              >
                <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                Add New Prefix
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </div>
          </>
        )}
      >
        {this.state.prefixes.map((item, index) => {
          if (item.document_type == document_type) {
            return (
              <Option key={index} value={item.prefix}>
                {item.prefix}
              </Option>
            )
          }
        })}
      </Select>
    )
  }
  render() {
    const fontSizes = [
      { label: 'Small', value: 0.75 },
      { label: 'Normal', value: 1 },
      { label: 'Large', value: 1.2 },
    ]
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
    if (this.state.invoice_header_image != '') {
      var fileList = [
        {
          uid: '-1',
          name: 'header.png',
          status: 'done',
          url: this.state.invoice_header_image,
        },
      ]
    } else {
      var fileList = []
    }
    if (this.state.invoice_footer_image != '') {
      var footerList = [
        {
          uid: '-1',
          name: 'footer.png',
          status: 'done',
          url: this.state.invoice_footer_image,
        },
      ]
    } else {
      var footerList = []
    }

    if (this.state.watermark != '') {
      var watermarkList = [
        {
          uid: '-1',
          name: 'watermark.png',
          status: 'done',
          url: this.state.watermark,
        },
      ]
    } else {
      var watermarkList = []
    }

    if (this.state.banner_image_1 != '') {
      var bannerList1 = [
        {
          uid: '-1',
          name: 'banner1.png',
          status: 'done',
          url: this.state.banner_image_1,
        },
      ]
    } else {
      var bannerList1 = []
    }

    if (this.state.banner_image_2 != '') {
      var bannerList2 = [
        {
          uid: '-1',
          name: 'banner2.png',
          status: 'done',
          url: this.state.banner_image_2,
        },
      ]
    } else {
      var bannerList2 = []
    }

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>
          <FormattedMessage id="button.upload" />{' '}
        </div>
      </div>
    )
    var { previewImage, previewTitle, previewVisible } = this.state

    return (
      <div>
        {this.state.settingData == '' ? (
          <></>
        ) : (
          <Drawer
            title="Document Settings"
            placement="right"
            width={isMobile ? '100%' : '60%'}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            closable={true}
            onClose={this.onClose}
            open={this.state.settingsVisible}
            destroyOnClose={true}
            extra={
              <Space>
                <Button
                  onClick={() => this.formRef.current.submit()}
                  type="primary"
                  className="ml-2 font-weight-bold"
                >
                  Update Settings
                </Button>
              </Space>
            }
            footer={
              <>
                {this.state.key == 2 ? (
                  <>
                    <Button
                      onClick={() => this.formRef2.current.submit()}
                      type="primary"
                      className="mr-2 font-weight-bold"
                    >
                      Update Settings
                    </Button>
                    <Button onClick={() => this.onClose()} type="danger">
                      Close
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => this.formRef.current.submit()}
                      type="primary"
                      className="mr-2 font-weight-bold"
                    >
                      Update Settings
                    </Button>
                    <Button onClick={() => this.onClose()} type="danger">
                      Close
                    </Button>
                  </>
                )}
              </>
            }
          >
            <Form
              {...formItemLayout}
              labelAlign="left"
              ref={this.formRef}
              name="settingForm"
              onFinish={this.onFinishDocumentSettings}
              onFinishFailed={() => message.error('Check All Details Again')}
              initialValues={{
                ...this.state.settingData,
              }}
            >
              <Button
                onClick={() => {
                  this.invoiceThemeForm.onOpen()
                }}
                type="action-o"
                className="font-weight-medium hidden sm:inline mb-4 mr-3"
                data-beacon-id="invoice_templates"
              >
                <i className="fa-sharp fa-heart fa-beat fa-lg text-pink mr-2"></i>
                Invoice Templates
              </Button>

              <Tooltip
                title="Use this to add new fields or rename existing ones in the invoice and all other documents!"
                placement="top"
              >
                <Button
                  onClick={e => {
                    store.get('paid') == 0
                      ? this.subscriptionPlanRef.current.openModal('custom_fields')
                      : this.custom_column.onTabChange('2')
                  }}
                  type="action-o"
                  className="font-weight-medium hidden sm:inline mb-4 mr-3"
                >
                  <i className="fa-solid fa-sliders mr-2"></i>
                  Add Custom Fields
                  {store.get('paid') == 0 && <ProBadge />}
                </Button>
              </Tooltip>
              <Button
                onClick={e => {
                  this.templatesRef.current.showDrawer()
                  // store.get('paid') == 0
                  //   ? this.subscriptionPlanRef.current.openModal('custom_fields')
                  //   : this.custom_column.onTabChange('2')
                }}
                type="action-o"
                className="font-weight-medium hidden sm:inline mb-4 mr-3"
              >
                <i className="fa far fa-comment mr-2"></i>
                Email/ WhatsApp/ SMS
              </Button>
              <FeatureAction
                title="Document Prefixes & Suffixes"
                description="Add multiple prefixes and suffixes for all your documents to manage your serial numbers and document numbering."
                onClick={() => this.documentPrefixesRef.current.show_drawer('invoice', true)}
                btnText="Add Prefixes/Suffixes"
              />

              <SectionHeader
                title="Additional Customizations"
                link={true}
                linkTo={e => {
                  store.get('paid') == 0
                    ? this.subscriptionPlanRef.current.openModal('pdf_labels', 1)
                    : this.invoiceLabelsRef.current.show_drawer()
                }}
                linkText={
                  <>Customize Invoice Labels {store.get('paid') == 0 ? <ProBadge /> : ''}</>
                }
                link_beacon_id="add_invoice_labels"
              />

              <Card bordered={false} size="drawer" className="border-radius-small mb-4">
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      name={'show_images'}
                      label="Show Images"
                      valuePropName="checked"
                      extra="This will show images on all documents, provided images are uploaded for the product. Upto 5 images only."
                      data-beacon-id="show_images"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_net_balance'}
                      label="Show NetBalance"
                      valuePropName="checked"
                      extra="Only receivable balance (i.e customer is in Debit and you have to collect the payment) will be shown on the invoices."
                      data-beacon-id="show_net_balance"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_due_date'}
                      label="Show Due Date"
                      valuePropName="checked"
                      extra="To show due date in invoice documents."
                      data-beacon-id="show_due_date"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_dispatch_address'}
                      label="Show Dispatch Address"
                      valuePropName="checked"
                      extra="To show Dispatch Address in invoice documents."
                      data-beacon-id="show_dispatch_address"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_payments'}
                      label="Show Payments"
                      valuePropName="checked"
                      extra="Payment mode and date will be shown on the invoices if payments are recorded."
                      data-beacon-id="show_payments"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_round_off'}
                      label={<>{'Show Round Off'}</>}
                      valuePropName="checked"
                      extra="Round off amount will be shown in the invoice pdfs if this is enabled."
                      data-beacon-id="show_round_off"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={'hide_qty'}
                      label={<>{'Hide Quantity'}</>}
                      valuePropName="checked"
                      extra="Quantity will be hidden in the invoice pdfs if this is enabled."
                      data-beacon-id="hide_qty"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'qty_decimals_3'}
                      label={<>{'Show Quantity with 3 decimals'}</>}
                      valuePropName="checked"
                      extra="Quantity will be shown with 3 decimal places in the invoice pdfs if this is enabled."
                      data-beacon-id="qty_decimals_3"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'hide_discount'}
                      label={<>{'Hide Discount'}</>}
                      valuePropName="checked"
                      extra="Item Discount will be hidden in the invoice pdfs if this is enabled."
                      data-beacon-id="hide_discount"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_discount_col'}
                      label={<>{'Show Discount Column'}</>}
                      valuePropName="checked"
                      extra="If enabled, Discount will be shown in different column."
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_qty_conversion_rate'}
                      label={<>Show Quantity Conversion Rate</>}
                      valuePropName="checked"
                      extra="Quantity in primary unit also will be show under the alternative unit in pdfs."
                      data-beacon-id="show_qty_conversion_rate"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_receivers_signature'}
                      label={<span>Show Receiver's Signature</span>}
                      valuePropName="checked"
                      extra="If enabled, this section will be shown in PDFs."
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12}>
                    <Form.Item
                      label="Show Transactions sorted by"
                      name="show_transactions_by"
                      extra="Transactions will be shown in the order selected here."
                      data-beacon-id="show_transactions_by"
                    >
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        placeholder="Select"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue="created_date"
                      >
                        <Option value={'created_date'}>{'Created Date'}</Option>
                        <Option value={'document_date'}>{'Document Date'}</Option>
                      </Select>
                    </Form.Item>
                  </Col> */}

                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="select_language"
                      label="Select Language in PDF"
                      name="locale"
                    >
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        placeholder="Select Language"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => this.handleLanguageChange(e)}
                      >
                        {this.state.languages.map((item, i) => (
                          <Option key={item.name} value={item.name}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {this.state.selectedLanguage === 'English' && (
                    <Col span={12}>
                      <Form.Item
                        data-beacon-id="select_font_style"
                        label="Select Font Style in PDF"
                        name="font_style"
                        extra={'Applicable for English language only.'}
                      >
                        <Select
                          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                          placeholder="Select Font Style"
                          optionFilterProp="children"
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.font_styles.map((item, i) => (
                            <Option key={item.name} value={item.name}>
                              {item.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {/*<Col span={12}>
                    <Form.Item
                      name={'is_encrypted'}
                      label={<>Encryption in PDFs</>}
                      valuePropName="checked"
                      extra="If enabled, text copying from the PDF will be restricted. If disabled, the Language in PDF will be set to English."
                    >
                      <Switch onChange={e => this.setState({ is_encrypted: e })} />
                    </Form.Item>
                  </Col>*/}

                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="repeat_header"
                      name={'repeat_header'}
                      label={<>Repeat Header</>}
                      valuePropName="checked"
                      extra="If enabled, the header section is shown in every page in PDFs."
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name={'hide_hsn'}
                      label={`Hide ${this.props?.user?.selectedCompany?.labels?.hsn_code}`}
                      valuePropName="checked"
                      extra={`${this.props?.user?.selectedCompany?.labels?.hsn_code} code will be hidden in the invoice pdfs if this is enabled.`}
                      data-beacon-id="hide_hsn"
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  {/* <Col span={12}>
                    <Form.Item
                      name={'round_off'}
                      label={<>Round Off</>}
                      valuePropName="checked"
                      extra="If enabled, the round-off option will be ON by default when creating invoices, making your experience simpler."
                    >
                      <Switch />
                    </Form.Item>
                  </Col> */}

                  {/*<Col span={12}>
                    <Form.Item
                      name={'show_receivers_signature'}
                      label={
                        <span>
                          Show Receiver's Signature
                        </span>
                      }
                      valuePropName="checked"
                      extra="If enabled, this section will be shown in PDFs."
                    >
                      <Switch />
                    </Form.Item>
                  </Col>*/}
                  <Col span={12}>
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-gray-600 font-bold">
                        Enable Item Headers {store.get('paid') == 0 && <ProBadge />}
                      </span>
                      <Switch
                        checked={this.state.enable_item_headers_toggled}
                        onChange={e => this.handleEnableItemHeaders(e)}
                      />
                    </div>
                    {this.state.enable_item_headers_toggled && (
                      <Form.Item
                        name={'enable_item_headers'}
                        label={<span>Choose Document Types</span>}
                        extra={
                          <div>
                            Note: This field will be visible only in selected document types. If you
                            select All, it will be visible for all document types. You can select
                            multiple document types.
                          </div>
                        }
                      >
                        <Select
                          mode="multiple"
                          maxTagCount={'responsive'}
                          placeholder="Select Document Types"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={value => {
                            if (value[value.length - 1] == 'all') {
                              this.formRef.current.setFieldsValue({
                                enable_item_headers: ['all'],
                              })
                              return
                            }
                            if (value.includes('all') && value.length > 1) {
                              value = value.filter(item => item !== 'all')
                              this.formRef.current.setFieldsValue({
                                enable_item_headers: value,
                              })
                              return
                            }
                            if (value.length == 9) {
                              this.formRef.current.setFieldsValue({
                                enable_item_headers: ['all'],
                              })
                              return
                            }
                          }}
                        >
                          {this.document_types.map((item, index) => (
                            <Option key={index} value={item.document_type}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="Show full page"
                      name={'full_page'}
                      label={<>Show full page</>}
                      valuePropName="checked"
                      extra={`Applicable for Vintage, Evergreen, Compact and Legend templates.`}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="document_color"
                      label="Document Color (Default: #276EF1)"
                      name="color"
                      rules={[
                        {
                          max: 7,
                          message: 'Color code should be in #000000 format',
                        },
                      ]}
                    >
                      <Input
                        className="mb-2"
                        value={this.state.color}
                        style={{
                          color: this.state.color,
                          backgrounColor: this.state.color,
                          border: '2px solid',
                        }}
                      />
                    </Form.Item>
                    <ColorPicker
                      style={{ width: 80, height: 80, borderRadius: 10 }}
                      value={this.state.color}
                      onChange={this.handleChange}
                      {...presets}
                    />
                  </Col>
                </Row>
              </Card>
              <SectionHeader title={<>{'Export Invoice Settings'}</>} />
              <Card
                bordered={true}
                className="border-radius-small mb-4"
                data-beacon-id="export_settings"
              >
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      name={'show_conversion_factor'}
                      label={<>{'Show Conversion Factor'}</>}
                      valuePropName="checked"
                      extra="Conversion rate will be shown in the invoices if this is enabled."
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={'show_both_currencies'}
                      label={`Show in ${this.props?.user?.selectedCompany?.currency_code}`}
                      valuePropName="checked"
                      extra={`Currency will be shown in ${this.props?.user?.selectedCompany?.currency_code} for export invoices if this is enabled.`}
                    >
                      <Switch />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card bordered={true} className=" border-radius-small mb-4">
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="company_letter_head"
                      name={'show_company_details'}
                      label={<>Show Company Details {store.get('paid') == 0 && <ProBadge />}</>}
                      valuePropName="checked"
                      extra="Company Details will not be shown in PDF if this is disabled (Recommended for those who are using default company letterhead)"
                    >
                      <Switch style={{ color: 'blue' }} disabled={!this.state.paid} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="show_hsn_details"
                      name={'show_hsn_details'}
                      label={
                        <>
                          Show {this.props?.user?.selectedCompany?.labels?.hsn_code} Summary{' '}
                          {store.get('paid') == 0 && <ProBadge />}
                        </>
                      }
                      valuePropName="checked"
                      extra={`${this.props?.user?.selectedCompany?.labels?.hsn_code} Summary of the invoice will be shown in PDF if this is enabled. Only available in Vintage, Evergreen, Compact and Landscape templates.`}
                    >
                      <Switch style={{ color: 'blue' }} disabled={!this.state.paid} />
                    </Form.Item>
                  </Col>

                  {/* <Col span={12}>
                    <Form.Item
                      data-beacon-id="allow_negative_qty"
                      name={'allow_negative_qty'}
                      label={<>Allow Negative Quantity</>}
                      valuePropName="checked"
                      extra="If this is enabled, you can create bills with quantity less than 0."
                    >
                      <Switch />
                    </Form.Item>
                  </Col> */}
                  <Col span={12}>
                    <Form.Item
                      name={'pdf_orientation'}
                      label={<>PDF Orientation</>}
                      extra="Applicable to all templates, except Landscape(6th) template."
                    >
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        placeholder="Orientation"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue="portrait"
                      >
                        <Option value={'portrait'}>{'Portrait'}</Option>
                        <Option value={'landscape'}>{'Landscape'}</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="price_max_decimals"
                      name={'price_max_decimals'}
                      label={
                        <>
                          {'Decimals to be shown for Item Prices in PDFs'}{' '}
                          {store.get('paid') == 0 && <ProBadge />}
                        </>
                      }
                      extra="Maximum decimals points to be shown for Item prices (Unit Price, Price with Tax) in document PDFs. Default value is 2. Maximum value is 6."
                    >
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        placeholder="Select"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        disabled={!this.state.paid}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                        <Option value={6}>6</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          Document Footer
                          {store.get('paid') == 0 && <ProBadge />}
                        </>
                      }
                      data-beacon-id="invoice_footer"
                      name="invoice_footer"
                      extra="Upto 255 characters only."
                    >
                      <TextArea disabled={!this.state.paid} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={<>Thermal Print Footer {store.get('paid') == 0 && <ProBadge />}</>}
                      name="pos_footer"
                      extra="Upto 255 characters only."
                      data-beacon-id="pos_footer"
                    >
                      <TextArea disabled={!this.state.paid} />
                    </Form.Item>
                  </Col>
                  {/* <Col span={12}>
                    <Form.Item
                      name={'show_payment_qr'}
                      label={<>Show Payment QR</>}
                      valuePropName="checked"
                      extra="If enabled, the QR code for payment will be shown in the Thermal Print."
                    >
                      <Switch />
                    </Form.Item>
                  </Col> */}
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="font_size"
                      label={<>Document Font Size</>}
                      name="font_size"
                      extra="Font size will be applied to all the documents."
                    >
                      <Radio.Group disabled={!this.state.paid}>
                        {fontSizes.map(option => (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      data-beacon-id="paper_size"
                      label={<>Document Paper Size</>}
                      name="paper_size"
                      extra="Paper size will be applied to all the documents."
                      hidden
                    >
                      <Radio.Group
                        disabled={!this.state.paid}
                        onChange={e => {
                          if (e.target.value == 'A5') {
                            this.formRef.current.setFieldsValue({ font_size: 0.75 })
                          } else {
                            this.formRef.current.setFieldsValue({ font_size: 1 })
                          }
                        }}
                      >
                        {[
                          {
                            label: 'A4',
                            value: 'A4',
                          },
                          {
                            label: 'A5',
                            value: 'A5',
                          },
                        ].map(option => (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <SectionHeader
                data-beacon-id="document_notes_and_terms"
                title={<span>Notes and Terms {store.get('paid') == 0 && <ProBadge />}</span>}
              />

              <FeatureAction
                title="Document Notes & Terms"
                description="Add notes and terms to your documents. These will be shown in your invoices, estimates, and other documents."
                onClick={() => this.customNotesRef.current.showDrawer()}
                btnText="Add Notes & Terms"
              />

              <SectionHeader
                title={
                  <span>Add Margins to the documents {store.get('paid') == 0 && <ProBadge />}</span>
                }
              />
              <Card bordered={true} className="border-radius-small mb-4">
                {/* <div className="flex justify-between items-center mt-4">
                  <h6 className="font-weight-bold text-black  mb-0">
                    Add Margins to the documents {store.get('paid') == 0 && <ProBadge />}
                  </h6>
                </div> */}
                {/* <Divider className="mb-4" /> */}
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      label="Document Margin Top"
                      name="margin_top"
                      data-beacon-id="document_margin_top"
                      extra="Add margins in the increments of 10. Start with 50 and iterate."
                      rules={[
                        {
                          type: 'number',
                          max: 250,
                        },
                      ]}
                    >
                      <InputNumber disabled={!this.state.paid} className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Document Margin Bottom"
                      name="margin_bottom"
                      data-beacon-id="document_margin_bottom"
                      extra="Add margins in the increments of 10. Start with 50 and iterate."
                      rules={[
                        {
                          type: 'number',
                          max: 250,
                        },
                      ]}
                    >
                      <InputNumber disabled={!this.state.paid} className="w-full" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      label="Document Margin Left"
                      name="margin_left"
                      data-beacon-id="document_margin_left"
                      extra="Value must be between 10 and 60, default value is 24."
                      rules={[
                        {
                          type: 'number',
                          max: 60,
                          min: 10,
                        },
                      ]}
                    >
                      <InputNumber disabled={!this.state.paid} className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Document Margin Right"
                      name="margin_right"
                      data-beacon-id="document_margin_right"
                      extra="Value must be between 10 and 60, default value is 24."
                      rules={[
                        {
                          type: 'number',
                          max: 60,
                          min: 10,
                        },
                      ]}
                    >
                      <InputNumber disabled={!this.state.paid} className="w-full" />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <SectionHeader
                data-beacon-id="invoice_header_footer_images"
                title={
                  <span>
                    Invoice Header and Footer images {store.get('paid') == 0 && <ProBadge />}
                  </span>
                }
              />
              <Card bordered={true} className="border-radius-small mb-4">
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      name="header_image"
                      data-beacon-id="invoice_header_image"
                      label={<span>Invoice Header Image</span>}
                      extra="Images must be PNG or JPEG. (1000 px by 125 px or 8:1 aspect ratio)"
                    >
                      <ImgCrop grid rotate aspect={8 / 1}>
                        <Upload
                          name="header_image"
                          listType="picture-card"
                          className="avatar-uploader w-200"
                          disabled={!this.state.paid}
                          // className=""
                          fileList={fileList}
                          onPreview={this.handlePreview}
                          customRequest={this.customRequest}
                          beforeUpload={this.beforeUpload}
                          onRemove={file => {
                            this.setState({ image_type: 'header' }, () => {
                              this.onRemove(file)
                            })
                          }}
                          onChange={e => this.setState({ image_type: 'header' })}
                        >
                          {this.state.invoice_header_image == '' ? uploadButton : null}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="footer_image"
                      label={<span>Invoice Footer Image</span>}
                      data-beacon-id="invoice_footer_image"
                      extra="Images must be PNG or JPEG, (1000 px by 125 px or 8:1 aspect ratio)"
                    >
                      <ImgCrop grid rotate aspect={8 / 1}>
                        <Upload
                          name="footer_image"
                          listType="picture-card"
                          className="avatar-uploader"
                          fileList={footerList}
                          disabled={!this.state.paid}
                          onPreview={this.handlePreview}
                          customRequest={this.customRequest}
                          beforeUpload={this.beforeUpload}
                          onRemove={file => {
                            this.setState({ image_type: 'footer' }, () => {
                              this.onRemove(file)
                            })
                          }}
                          onChange={e => this.setState({ image_type: 'footer' })}
                        >
                          {this.state.invoice_footer_image == '' ? uploadButton : null}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="watermark"
                      label={<span>Watermark</span>}
                      extra="Images must be PNG or JPEG. (512 x 512px or 1:1 aspect ratio)"
                    >
                      <ImgCrop grid rotate aspect={1}>
                        <Upload
                          name="watermark"
                          listType="picture-card"
                          className="avatar-uploader"
                          fileList={watermarkList}
                          disabled={!this.state.paid}
                          onPreview={this.handlePreview}
                          customRequest={this.customRequest}
                          beforeUpload={this.beforeUpload}
                          onRemove={file => {
                            this.setState({ image_type: 'watermark' }, () => {
                              this.onRemove(file)
                            })
                          }}
                          onChange={e => this.setState({ image_type: 'watermark' })}
                        >
                          {this.state.watermark === '' ? uploadButton : null}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  hidden
                  name="show_google_reviews_invoice"
                  label="Show Google Review Link Invoice"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  hidden
                  name="show_google_reviews_pos"
                  label="Show Google Review Link Pos"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Card>
              <SectionHeader
                data-beacon-id="invoice_banner_images"
                title={<span>Invoice Banner images {store.get('paid') == 0 && <ProBadge />}</span>}
              />
              <Card bordered={true} className="border-radius-small mb-4">
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      name="banner_image_1"
                      data-beacon-id="banner_image_1"
                      label={<span>Invoice Banner Image 1</span>}
                      extra={
                        <span>
                          <span>
                            Images must be PNG or JPEG. (1000 px by 125 px or 8:1 aspect ratio).
                          </span>
                          <br />
                          <span>Will be shown above the items table in the PDFs.</span>
                        </span>
                      }
                    >
                      <ImgCrop grid rotate aspect={8 / 1}>
                        <Upload
                          name="banner_image_1"
                          listType="picture-card"
                          className="avatar-uploader w-200"
                          disabled={!this.state.paid}
                          fileList={bannerList1}
                          onPreview={this.handlePreview}
                          customRequest={this.customRequest}
                          beforeUpload={this.beforeUpload}
                          onRemove={file => {
                            this.setState({ image_type: 'banner_image_1' }, () => {
                              this.onRemove(file)
                            })
                          }}
                          onChange={e => this.setState({ image_type: 'banner_image_1' })}
                        >
                          {this.state.banner_image_1 == '' ? uploadButton : null}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="banner_image_2"
                      label={<span>Invoice Banner Image 2</span>}
                      data-beacon-id="banner_image_2"
                      extra={
                        <span>
                          <span>
                            Images must be PNG or JPEG. (1000 px by 125 px or 8:1 aspect ratio).
                          </span>
                          <br />
                          <span>Will be shown below the items table in the PDFs.</span>
                        </span>
                      }
                    >
                      <ImgCrop grid rotate aspect={8 / 1}>
                        <Upload
                          name="banner_image_2"
                          listType="picture-card"
                          className="avatar-uploader"
                          fileList={bannerList2}
                          disabled={!this.state.paid}
                          onPreview={this.handlePreview}
                          customRequest={this.customRequest}
                          beforeUpload={this.beforeUpload}
                          onRemove={file => {
                            this.setState({ image_type: 'banner_image_2' }, () => {
                              this.onRemove(file)
                            })
                          }}
                          onChange={e => this.setState({ image_type: 'banner_image_2' })}
                        >
                          {this.state.banner_image_2 == '' ? uploadButton : null}
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
            <Modal
              open={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={this.handleCancel}
              closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>

            {/* <CustomNotes ref={this.customNotesRef} refreshNotesTerms={() => {}} /> */}
            <NotesTermsDrawer ref={this.customNotesRef} refreshNotesTerms={() => {}} />
            <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
            <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
            <InvoiceThemeForm
              onRef={ref => (this.invoiceThemeForm = ref)}
              refreshSelectedTemplates={this.refreshSelectedTemplates}
            />
            <DocumentPrefixes ref={this.documentPrefixesRef} onFinish={this.refreshPrefix} />
            <InvoiceLabels
              ref={this.invoiceLabelsRef}
              labels={this.state?.settingData?.labels}
              fieldsDisabled={!this.state.paid}
            />
            <CustomColumnsForm
              onRef={ref => (this.custom_column = ref)}
              onAPICalled={() => {
                console.log('')
              }}
            />
            <PrefixForm ref={this.prefixFormRef} onFinish={this.refreshPrefix} />
            <ShareTemplates ref={this.templatesRef} refreshNotesTerms={() => {}} />
            {/* <CustomNotes ref={this.customNotesRef} /> */}
            {/* <GoogleLocation ref={this.googleLocation} /> */}
          </Drawer>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => state

export default withRouter(connect(mapStateToProps)(InvoiceSettingsForm))
