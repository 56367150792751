import { Image, Text, View } from '@react-pdf/renderer'
import { getTextColor } from 'components/other/utility'
import { styles as getStyles } from './styles'
// import { HIDE_PRICES } from '../utils'

const InvoiceTitle = ({ invoice, title }) => {
  var styles = getStyles(invoice)

  return (
    <>
      {invoice.invoice_settings.invoice_header_image != '' && (
        <View style={styles.index_brandingLogo}>
          <Image
            style={styles.index_headerImage}
            src={{
              uri: invoice.invoice_settings.invoice_header_image,
              //uri: '/resources/images/banner3.png',
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
            }}
            crossorigin="anonymous"
          />
        </View>
      )}

      {invoice.invoice_settings.invoice_header_image == '' && (
        <View style={styles.index_brandingLogo}>
          <View style={[styles.title_bg, { backgroundColor: invoice.company.color }]} />
        </View>
      )}
      <View
        style={[
          styles.title_headerContainer,
          {
            paddingTop: !!invoice.invoice_settings.margin_top
              ? invoice.invoice_settings.margin_top
              : 0,
          },
        ]}
      >
        {/* Header Banner */}

        {invoice.invoice_settings.show_company_details == 1 && (
          <View style={styles.title_blockC}>
            {invoice.company.logo != null && invoice.company.logo != '' && (
              <View style={styles.title_logoContainer}>
                <Image
                  style={styles.title_logo}
                  src={{
                    uri: invoice.company.logo,
                    method: 'GET',
                    headers: { 'Cache-Control': 'no-cache' },
                  }}
                  crossorigin="anonymous"
                />
              </View>
            )}
          </View>
        )}
        <View style={styles.title_blockI}>
          <Text
            style={{
              ...styles.title_reportTitle,
              color: getTextColor(invoice.company.color),
              //textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
            }}
          >
            {title}
          </Text>

          {/* <Text style={styles.global_pr}>
            <Text
              style={{
                ...styles.company_labels,
                //textAlign: invoice.invoice_settings.show_company_details == 0 ? 'left' : 'right',
              }}
            >
              {invoice.document_title} #:{' '}
            </Text>
            <Text style={[styles.company_info, styles.company_bold]}>{invoice.serial_number}</Text>
          </Text> */}
        </View>
      </View>
    </>
  )
}

export default InvoiceTitle
