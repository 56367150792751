import { Image, Link, Text, View } from '@react-pdf/renderer'
import { styles as getStyles } from './styles'
import { formatPageNumber } from '../utils'

export default function InvoiceFooter({ invoice }) {
  var styles = getStyles(invoice)
  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <>
      <Link
        href={invoice.company.website}
        target="_blank"
        fixed
        style={{
          ...styles.index_footer,
          width: invoice.user_details.paid == 1 ? '100%' : '80%',
          color: invoice.company.color,
          bottom:
            33.3 * PAPER_SIZE +
            (!!invoice.invoice_settings.margin_bottom
              ? invoice.invoice_settings.margin_bottom
              : 0) +
            (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
          left: !!invoice.invoice_settings.margin_left ? invoice.invoice_settings.margin_left : 10,
        }}
      >
        {invoice.company.invoice_footer}
      </Link>
      <Text
        fixed
        render={({ pageNumber, totalPages }) => (
          <Text>
            <Text style={styles.index_footerPadding}>
              {formatPageNumber(pageNumber, totalPages, invoice.type)}
              {'   ' + '\u2022' + '   '}
            </Text>
            {pageNumber != 1 && (
              <Text style={styles.index_footerPadding}>
                {invoice.serial_number}
                {'   ' + '\u2022' + '   '}
              </Text>
            )}
            <Text>
              {invoice.signature === '' && invoice.invoice_settings.digitally_signed === 1
                ? 'This is a computer generated document and requires no signature.'
                : invoice.signature !== '' && invoice.invoice_settings.digitally_signed === 1
                ? 'This is a digitally signed document.'
                : ''}
            </Text>
          </Text>
        )}
        style={{
          ...styles.index_footerNote,
          bottom:
            24 * PAPER_SIZE +
            (!!invoice.invoice_settings.margin_bottom
              ? invoice.invoice_settings.margin_bottom
              : 0) +
            (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
          left: !!invoice.invoice_settings.margin_left ? invoice.invoice_settings.margin_left : 10,
        }}
      />
      {invoice.user_details.paid != 1 && (
        <View
          fixed
          style={{
            ...styles.index_brandingFooter,
            bottom:
              18 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
          }}
        >
          <View style={styles.index_brandingLogo}>
            <Text style={styles.index_alignRight}>Powered By</Text>
            <Link src="https://getswipe.in" target="_blank">
              <Image
                style={styles.index_logo}
                src={{
                  uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/swipe_unpaid.png',
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </Link>
          </View>
        </View>
      )}
      {invoice.user_details.paid != 1 && (
        <Image
          fixed
          style={[styles.index_watermark]}
          src={{
            uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/watermark2.png',
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
          }}
          crossorigin="anonymous"
        />
      )}
      {invoice.invoice_settings.watermark != '' && invoice.user_details.paid != 0 && (
        <Image
          fixed
          style={[styles.index_watermark]}
          src={{
            uri: invoice.invoice_settings.watermark,
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
          }}
          crossorigin="anonymous"
        />
      )}
      {/* Footer Banner */}
      {invoice.invoice_settings.invoice_footer_image != '' && (
        <View
          fixed
          style={{
            ...styles.index_footer,
            bottom:
              24 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0),
            left: !!invoice.invoice_settings.margin_left
              ? invoice.invoice_settings.margin_left
              : 10,
            right: !!invoice.invoice_settings.margin_right
              ? invoice.invoice_settings.margin_right
              : 10,
          }}
        >
          <Image
            style={styles.index_banner}
            src={{
              uri: invoice.invoice_settings.invoice_footer_image,
              //uri: '/resources/images/banner3.png',
              method: 'GET',
              headers: { 'Cache-Control': 'no-cache' },
            }}
            crossorigin="anonymous"
          />
        </View>
      )}
    </>
  )
}
