import React from 'react'
import { useSelector } from 'react-redux'

export function ProductOption({ item, document_settings }) {
  const user = useSelector(state => state.user)
  const { inventory } = useSelector(state => state.permissions.permission)

  return (
    <div className="cursor-pointer w-full" key={item.id}>
      <div
        style={{
          lineHeight: 1.44,
          whiteSpace: 'normal',
          overflowWrap: 'break-all',
        }}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <span className="mb-0 pb-0 font-weight-medium">
                {item.product_name + ' ' + item.variant_name}
              </span>
              <p className="font-size-11 mt-0 mb-0 text-gray-500">
                {inventory == 1
                  ? ((item['product_type'] == 'Service' &&
                      document_settings.track_stock_for_services === 1) ||
                      item['product_type'] != 'Service') && (
                      <>
                        {item.qtyinstock > 0 ? (
                          <span
                            className="font-weight-bold font-size-10 mr-1"
                            style={{ color: 'green' }}
                          >
                            Avl. qty: {item.qtyinstock}{' '}
                          </span>
                        ) : (
                          <span
                            className="font-weight-bold font-size-10 mr-1"
                            style={{ color: 'red' }}
                          >
                            Avl. qty: {item.qtyinstock}{' '}
                          </span>
                        )}
                      </>
                    )
                  : null}
                {item.unit !== 'OTH' && item.unit !== 'NONE' && (
                  <span className="font-size-10 text-purple mr-2">{item.unit}</span>
                )}
                <span className="text-purple font-weight-bold mr-2">
                  {item.product_category}
                  {'  '}
                </span>
                <span className="text-gray-10">{item.hsn_code}</span>
              </p>
            </div>
          </div>
          <span className="float-right font-weight-bold">
            {item.is_price_with_tax === 1 ? (
              <>
                <span className="mr-1 text-gray-700">{user?.selectedCompany?.currency_symbol}</span>
                <span className="text-gray-700">{item.price_with_tax.toFixed(2)}</span>
                {item.tax > 0 && (
                  <p className="font-size-9 text-gray-500 mb-0 text-right">incl tax {item.tax}%</p>
                )}
              </>
            ) : (
              <>
                <span className="mr-1 text-gray-700">{user?.selectedCompany?.currency_symbol}</span>
                <span className="text-gray-700">{item.unit_price.toFixed(2)}</span>
                {item.tax > 0 && (
                  <p className="font-size-9 text-gray-500 mb-0 text-right">+ tax {item.tax}%</p>
                )}
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  )
}
