import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Settings',
  'topBar.profileMenu.logout': 'Logout',
  'sales.tab.invoices': 'Invoices',
  'sales.tab.pos': 'POS',
  'sales.heading': 'Sales',
  'sales.tab.allTransactions': 'All Transactions',
  'sales.tab.paid': 'Paid',
  'sales.tab.pending': 'Pending',
  'sales.tab.cancelled': 'Cancelled',
  'sales.tab.refund': 'Refund',
  'sales.tab.returns': 'Returns',

  'expenses.heading': 'Expenses',
  'expenses.addExpense': 'Add an Expense Now',

  'button.download': 'Download Data',
  'button.createInvoice': 'Create Invoice',
  'button.createExpense': 'Create Expense',
  'button.sales': 'Create Invoice',
  'button.sales_returns': 'Create Sales Return/Credit Note',
  'button.purchases': 'Create Purchase',
  'button.purchase_orders': 'Create Purchase Order',
  'button.purchase_returns': 'Create Purchase Return/Debit Note',
  'button.estimates': 'Create Quotation',
  'button.pro_forma_invoices': 'Create Pro Forma Invoice',
  'button.delivery_challans': 'Create Delivery Challan',
  'button.expense_report': 'Expense Report',
  'button.settings': 'Settings',
  'button.goToPos': 'POS Billing',

  'menuLeft.sales': 'Sales',
  'menuLeft.purchases': 'Purchases',
  'menuLeft.purchase_orders': 'Purchase Orders',
  'menuLeft.purchase_returns': 'Purchase Returns',
  'menuLeft.estimates': 'Estimates',
  'menuLeft.payments': 'Payments',
  'menuLeft.timeline': 'Payments Timeline',
  'menuLeft.inventory': 'Inventory',
  'menuLeft.pro_forma_invoices': 'Pro Forma Invoices',
  'menuLeft.delivery_challans': 'Delivery Challans',
  'menuLeft.products': 'Products',
  'menuLeft.vendors': 'Vendors',
  'menuLeft.customers': 'Customers',
  'menuLeft.expenses': 'Expenses',
  'menuLeft.invite_users': 'Add Users',
  'menuLeft.analytics': 'Insights',
  'menuLeft.sales_summary': 'Sales Summary',
  'menuLeft.reports': 'Reports',
  'menuLeft.onlineorders': 'Online Orders',
  'menuLeft.settings': 'Settings',
  'menuLeft.tutorials': 'Tutorials',
  'menuLeft.ewaybills': 'E-Way Bills',

  'table.amount': 'Amount',
  'table.status': 'Status',
  'table.createdDate': 'Date/Created Time',
  'table.send': 'Send',
  'table.action': 'Action',
  'table.customer': 'Customer',
  'table.description': 'description',
  'table.expenseId': 'Expense #',
  'table.mode': 'Payment Mode',

  totalAmount: 'Total Amount',
  'button.view': 'View',
  'button.recordPayment': 'Record Payment',
  'button.deliveryChallan': 'Delivery Challan',
  'button.thermalPrint': 'Thermal Print',
  'button.edit': 'Edit',
  'button.cancel': 'Cancel',
  // Login
  welcome: 'Swipe में आपका स्वागत है',
  '10digit': '10 अंकों का मोबाइल नंबर',
  willSendOTP: 'हम इस नंबर पर एक OTP भेजेंगे',
  continue: 'मोबाइल नंबर के साथ जारी रखें',
  termsMessage: 'जारी रखकर आप हमारी सहमति देते हैं',
  mobileError: 'कृपया अपना 10 अंकों का मोबाइल नंबर दर्ज करें',
  otpSent: 'दिए गए नंबर पर भेजा गया OTP',
  confirmOTP: 'ओटीपी की पुष्टि करें',
  terms: 'मामले',
  policy: 'नीति',
  help: 'मदद / समर्थन के लिए',
}

export default {
  locale: 'Hindi',
  localeAntd,
  messages,
}
