import React, { useEffect, useState } from 'react'
import store from 'store'

const ACCESS_TOKEN = 'accessToken'
const AGENT_ACCESS_KEY = 'agentAccessKey'
const StorageListener: React.FC = () => {
  const [tokenChanged, setTokenChanged] = useState(false)

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'accessToken' || event.key === 'agentAccessKey') {
        if (event.oldValue !== event.newValue) {
          if (event.key === ACCESS_TOKEN) {
            store.remove(AGENT_ACCESS_KEY)
          }
          if (event.key === AGENT_ACCESS_KEY) {
            store.remove(ACCESS_TOKEN)
          }
          setTokenChanged(true) // Flag that the token has changed
        }
      }
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && tokenChanged) {
        window.location.reload()
      }
    }

    window.addEventListener('storage', handleStorageChange)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [tokenChanged])

  return null
}

export default StorageListener
