import React, { Fragment } from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'
import { PlaceOfSupply_2 } from '../InvoicePlaceOfSupply'
import { dispatch_to_doc_types } from '../utils'

const CompanyDetails = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  let export_labels_1 = [
    {
      conversion_factor: 'Conversion Rate',
      is_show: invoice.is_export || invoice.is_multi_currency,
    },
    {
      currency_code: 'Currency',
      is_show: invoice.is_export || invoice.is_multi_currency,
    },
    {
      export_type: 'Export Type',
      is_show: invoice.is_export,
    },

    { name: 'Country Name', is_show: invoice.is_export },
    { shipping_date: 'Shipping Bill Date', is_show: invoice.is_export },
    { shipping_bill_number: 'Shipping bill #', is_show: invoice.is_export },

    { shipping_port_code: 'Shipping Port Code', is_show: invoice.is_export },
  ]

  invoice.invoice_settings.show_conversion_factor != 1 && export_labels_1.shift()

  let export_labels = []

  try {
    if (invoice.is_multi_currency == 1) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item.is_show == true
        )
      })
    }

    if (invoice.company.gstin.length != 15) {
      export_labels_1 = export_labels_1.filter(item => {
        return Object.keys(item)[0] != 'export_type'
      })
    }
    if (
      (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
      invoice.invoice_settings.show_both_currencies != true
    ) {
      export_labels_1 = export_labels_1.filter(item => {
        return (
          invoice.export_details[0][Object.keys(item)[0]] != '' &&
          invoice.export_details[0][Object.keys(item)[0]] != null &&
          item.is_show == true
        )
      })
    }
  } catch (e) {
    console.log(e)
  }

  while (export_labels_1.length) export_labels.push(export_labels_1.splice(0, 2))
  var newarray = []
  if (Object.keys(invoice).includes('document_custom_headers')) {
    var new_headers = [...invoice.document_custom_headers]
    while (new_headers.length) newarray.push(new_headers.splice(0, 2))
  }

  return (
    <View fixed={invoice.invoice_settings.repeat_header == 1}>
      <View
        style={[
          styles.company_column,
          invoice.invoice_settings.full_page == false && styles.globalContainer,
        ]}
      >
        <View style={styles.company_blockC}>
          <View style={[styles.company_blockCA, styles.company_borderRight]}>
            {invoice.invoice_settings.show_company_details == 1 && (
              <View style={[styles.company_blockRow, styles.company_borderBottom]}>
                {invoice.company.logo != null && invoice.company.logo != '' && (
                  <View>
                    <Image
                      style={styles.company_logo}
                      src={{
                        uri: invoice.company.logo,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                      }}
                      crossorigin="anonymous"
                    />
                  </View>
                )}
                <View style={styles.company_blockC2}>
                  {/*<Text style={[styles.company_large, styles.company_bold]}>{data.company_name}</Text>*/}

                  <Text
                    style={[
                      data.company_name.length > 35
                        ? {
                            fontSize:
                              (9.9 * 30) / data.company_name.length >= 8
                                ? (10.8 * 25) / data.company_name.length
                                : 8,
                          }
                        : styles.company_large,
                      styles.company_bold,
                    ]}
                  >
                    {data.company_name}
                  </Text>

                  {data.gstin.length == 15 && (
                    <>
                      <Text>
                        <Text style={styles.company_label}>{tax_id_label}: </Text>
                        <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                          {data.gstin}
                        </Text>
                      </Text>
                    </>
                  )}
                  {data.pan_number != '' && (
                    <>
                      <Text>
                        <Text style={styles.company_label}>PAN: </Text>
                        <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                          {data.pan_number}
                        </Text>
                      </Text>
                    </>
                  )}
                  {'custom_fields' in data && data.custom_fields.length > 0 && (
                    <>
                      {data.custom_fields.map((field, index) => {
                        if (field.value != '' && field.value != null && field.value != undefined) {
                          return (
                            <Text key={index}>
                              <Text style={styles.company_label}>{field.field_name}: </Text>
                              <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                                {field.value}
                              </Text>
                            </Text>
                          )
                        }
                      })}
                    </>
                  )}
                  <Text style={styles.company_address}>
                    {data.address_1} {data.address_2 != '' && ', ' + data.address_2}
                  </Text>
                  <Text style={styles.company_address}>
                    {data.city == ''
                      ? ''
                      : data.city +
                        (data.state == '' ||
                        data.state == undefined ||
                        data.state == null ||
                        getStateName(data.state) == 'OTHER TERRITORY' ||
                        getStateName(data.state) == '97-OTHERTERRITORY' ||
                        getStateName(data.state) == 'OTHERTERRITORY'
                          ? ''
                          : ',')}{' '}
                    {data.state == '' ||
                    getStateName(data.state) == 'OTHER TERRITORY' ||
                    getStateName(data.state) == '97-OTHERTERRITORY' ||
                    getStateName(data.state) == 'OTHERTERRITORY'
                      ? ''
                      : getStateName(data.state) +
                        (data.pincode == '' || data.pincode == undefined || data.pincode == null
                          ? ''
                          : ', ')}
                    {invoice.is_export == 1 &&
                      '\n' + `${data.country}${data.pincode != '' ? ' - ' : ''}`}
                    {data.pincode}
                  </Text>
                  {/*<Text style={styles.company_text}>
                  {data.city == ''
                    ? ''
                    : data.city +
                      (data.pincode == '' || data.pincode == undefined || data.pincode == null
                        ? ''
                        : ',')}{' '}
                  {data.pincode}{' '}
                </Text>*/}
                  <Text>
                    {data.mobile != '' && data.mobile != 'None' && (
                      <>
                        <Text style={styles.company_label}>Mobile: </Text>
                        <Text style={styles.company_invoiceDate}>
                          {`+${data.dial_code} ${data.mobile}`}

                          {data.alt_contact == '' ? '   ' : ', '}
                          {data.alt_contact}
                          {data.alt_contact != '' && '   '}
                        </Text>
                      </>
                    )}
                  </Text>
                  <Text>
                    {data.email != '' && (
                      <>
                        <Text style={styles.company_label}>Email: </Text>
                        <Text style={styles.company_invoiceDate}>{data.email}</Text>
                      </>
                    )}
                  </Text>
                  {data.website != '' && (
                    <>
                      <Text>
                        <Text style={styles.company_label}>Website: </Text>
                        <Text style={styles.company_invoiceDate}>{data.website}</Text>
                      </Text>
                    </>
                  )}
                </View>
              </View>
            )}

            {invoice.customer.name != null && invoice.customer.name != '' && (
              <View style={styles.company_blockCol}>
                {invoice.document_type == 'purchases' ||
                invoice.document_type == 'purchase_orders' ||
                invoice.document_type == 'purchase_returns' ? (
                  <Text
                    style={styles.company_label}
                  >{`${invoice.invoice_settings.labels.vendor_details}:`}</Text>
                ) : (
                  <Text
                    style={styles.company_label}
                  >{`${invoice.invoice_settings.labels.customer_details}:`}</Text>
                )}
                {invoice.customer.name != invoice.customer.company_name ? (
                  <>
                    <Text style={[styles.company_text, styles.company_bold]}>
                      {invoice.customer.name}
                    </Text>
                    <Text style={[styles.company_text, styles.company_bold]}>
                      {invoice.customer.company_name}
                    </Text>
                  </>
                ) : (
                  <Text style={[styles.company_text, styles.company_bold]}>
                    {invoice.customer.company_name}
                  </Text>
                )}

                {invoice.customer.gstin != null && invoice.customer.gstin != '' && (
                  <Text style={[styles.company_label]}>
                    {tax_id_label}: {invoice.customer.gstin}
                  </Text>
                )}

                {invoice.customer.billing != undefined &&
                  invoice.customer.billing.address_1 != null &&
                  invoice.customer.billing.address_1 != '' && (
                    <>
                      {invoice.document_type != 'purchases' &&
                        invoice.document_type != 'purchase_orders' && (
                          <Text
                            style={styles.company_label}
                          >{`${invoice.invoice_settings.labels.customer_billing_address}:`}</Text>
                        )}

                      {(invoice.document_type == 'purchases' ||
                        invoice.document_type == 'purchase_orders') && (
                        <Text style={styles.company_label}>
                          {`${invoice.invoice_settings.labels.vendor_billing_address}:`}
                        </Text>
                      )}
                      <Text style={styles.company_text}>{invoice.customer.billing.address_1}</Text>
                      <Text style={styles.company_text}>{invoice.customer.billing.address_2}</Text>
                      <Text style={styles.company_text}>
                        {invoice.customer.billing.city != '' && invoice.customer.billing.city}
                        {invoice.is_export != 1 &&
                          invoice.customer.billing.state != '' &&
                          invoice.customer.billing.state != undefined &&
                          invoice.customer.billing.state != null &&
                          (getStateName(invoice.customer.billing.state) != 'OTHERTERRITORY' ||
                            getStateName(invoice.customer.billing.state) != '97-OTHERTERRITORY' ||
                            getStateName(invoice.customer.billing.state) != 'OTHER TERRITORY') &&
                          (invoice.customer.billing.city != '' ? ', ' : '') +
                            getStateName(invoice.customer.billing.state)}
                        {invoice.is_export == 1 &&
                          invoice.customer.billing.country &&
                          invoice.customer.billing.country != 'India' &&
                          '\n' + invoice.customer.billing.country}
                        {invoice.customer.billing.pincode != '' &&
                          invoice.customer.billing.pincode != null &&
                          invoice.customer.billing.pincode != 'None' &&
                          invoice.customer.billing.pincode != undefined &&
                          (invoice.customer.billing.city != '' ||
                          invoice.customer.billing.state != ''
                            ? ', '
                            : '') + invoice.customer.billing.pincode}
                      </Text>
                    </>
                  )}

                <Text style={styles.company_text}>
                  {invoice.customer.phone == ''
                    ? ''
                    : 'Ph: ' +
                      (invoice.customer.dial_code != '91'
                        ? '+' + invoice.customer.dial_code + ' '
                        : '') +
                      invoice.customer.phone}
                </Text>
                <Text style={styles.company_text}>
                  {invoice.customer.email == '' ||
                  invoice.customer.email == undefined ||
                  invoice.customer.email == null
                    ? ''
                    : invoice.customer.email}
                </Text>

                {'custom_party_values' in invoice &&
                  invoice.custom_party_values.map((item, index) => (
                    <Text style={styles.company_text}>
                      <Text style={styles.company_label}>{item.name}: </Text>

                      <Text style={styles.company_text}>{item.value}</Text>
                    </Text>
                  ))}
              </View>
            )}

            {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
              <>
                {!dispatch_to_doc_types.includes(invoice.document_type) &&
                  invoice.customer.shipping != undefined &&
                  invoice.customer.shipping.address_1 != null &&
                  invoice.customer.shipping.address_1 != '' && (
                    <View style={[styles.company_blockCol, styles.company_borderTop]}>
                      <Text
                        style={styles.company_label}
                      >{`${invoice.invoice_settings.labels.customer_shipping_address}:`}</Text>
                      {invoice.customer.shipping.title != '' && (
                        <Text style={styles.company_text}>{invoice.customer.shipping.title}</Text>
                      )}
                      <Text style={styles.company_text}>{invoice.customer.shipping.address_1}</Text>
                      <Text style={styles.company_text}>{invoice.customer.shipping.address_2}</Text>
                      <Text style={styles.company_text}>
                        {invoice.customer.shipping.city != '' && invoice.customer.shipping.city}
                        {invoice.is_export != 1 &&
                          invoice.customer.shipping.state != '' &&
                          invoice.customer.shipping.state != undefined &&
                          invoice.customer.shipping.state != null &&
                          (getStateName(invoice.customer.shipping.state) != 'OTHERTERRITORY' ||
                            getStateName(invoice.customer.shipping.state) != '97-OTHERTERRITORY' ||
                            getStateName(invoice.customer.shipping.state) != 'OTHER TERRITORY') &&
                          (invoice.customer.shipping.city != '' ? ', ' : '') +
                            getStateName(invoice.customer.shipping.state)}
                        {invoice.is_export == 1 &&
                          invoice.customer.shipping.country &&
                          invoice.customer.shipping.country != 'India' &&
                          '\n' + invoice.customer.shipping.country}
                        {invoice.customer.shipping.pincode != '' &&
                          invoice.customer.shipping.pincode != null &&
                          invoice.customer.shipping.pincode != 'None' &&
                          invoice.customer.shipping.pincode != undefined &&
                          (invoice.customer.shipping.city != '' ||
                          invoice.customer.shipping.state != ''
                            ? ', '
                            : '') + invoice.customer.shipping.pincode}
                        {invoice.customer.shipping.notes != '' &&
                          '\n' + invoice.customer.shipping.notes}
                      </Text>
                    </View>
                  )}

                {invoice.gst_details.id == undefined &&
                  invoice.company.shipping_address.address_1 != '' &&
                  invoice.invoice_settings.show_dispatch_address === 1 && (
                    <View style={[styles.company_blockCol, styles.company_borderTop]}>
                      <Text style={styles.company_label}>
                        {dispatch_to_doc_types.includes(invoice.document_type)
                          ? invoice.invoice_settings.labels.dispatch_to + ':'
                          : invoice.invoice_settings.labels.dispatch_from + ':'}
                      </Text>
                      <Text style={styles.company_text}>
                        {invoice.company.shipping_address.title}
                      </Text>
                      <Text style={styles.company_text}>
                        {invoice.company.shipping_address.address_1}
                      </Text>
                      <Text style={styles.company_text}>
                        {invoice.company.shipping_address.address_2}
                      </Text>
                      <Text style={styles.company_text}>
                        {invoice.company.shipping_address.city != '' &&
                          invoice.company.shipping_address.city}
                        {invoice.company.shipping_address.state != '' &&
                          invoice.company.shipping_address.state != undefined &&
                          invoice.company.shipping_address.state != null &&
                          (getStateName(invoice.company.shipping_address.state) !=
                            'OTHERTERRITORY' ||
                            getStateName(invoice.company.shipping_address.state) !=
                              '97-OTHERTERRITORY' ||
                            getStateName(invoice.company.shipping_address.state) !=
                              'OTHER TERRITORY') &&
                          (invoice.company.shipping_address.city != '' ? ', ' : '') +
                            getStateName(invoice.company.shipping_address.state)}
                        {invoice.is_export == 1 &&
                          invoice.company.shipping_address.country &&
                          invoice.company.shipping_address.country != 'India' &&
                          '\n' + invoice.company.shipping_address.country}
                        {invoice.company.shipping_address.pincode != '' &&
                          invoice.company.shipping_address.pincode != null &&
                          invoice.company.shipping_address.pincode != 'None' &&
                          invoice.company.shipping_address.pincode != undefined &&
                          (invoice.company.shipping_address.city != '' ||
                          invoice.company.shipping_address.state != ''
                            ? ', '
                            : '') + invoice.company.shipping_address.pincode}
                      </Text>
                    </View>
                  )}
              </>
            )}
          </View>

          <View style={styles.company_blockCB}>
            <View style={[styles.company_block, styles.company_borderBottom]}>
              <View style={[styles.company_blockC50, styles.company_borderRight]}>
                <Text style={styles.company_label}>{invoice.document_title} #: </Text>
                <Text style={[styles.company_text2, styles.company_bold]}>
                  {invoice.serial_number}
                </Text>
              </View>

              <View style={[styles.company_blockC50]}>
                <Text style={styles.company_label}>
                  {invoice.document_type == 'purchases'
                    ? 'Supplier Invoice'
                    : invoice.document_title}{' '}
                  Date:{' '}
                </Text>
                <Text style={[styles.company_text2, styles.company_bold]}>
                  {invoice.document_date}
                </Text>
              </View>
            </View>

            {invoice.document_type == 'purchases' && invoice.supplier_invoice_serial_number != '' && (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                <View style={[styles.company_blockC50, styles.company_borderRight]}>
                  <Text style={styles.company_label}>Supplier Invoice #: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.supplier_invoice_serial_number}
                  </Text>
                </View>

                {/* <View style={[styles.company_blockC50]}>
                  <Text style={styles.company_label}>Supplier Invoice Date: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.supplier_invoice_date_string}
                  </Text>
                </View> */}
              </View>
            )}

            {(invoice.company.gstin.length == 15 ||
              invoice.invoice_settings.show_due_date == 1) && (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                {/* <View style={[styles.company_blockC50, styles.company_borderRight]}>
                  <Text style={styles.company_label}>Place of Supply: </Text>
                  {invoice.document_type == 'purchases' ||
                  invoice.document_type == 'purchase_orders' ||
                  invoice.document_type == 'purchase_returns' ||
                  invoice.immovable_tax_type == 1 ? (
                    <Text style={[styles.company_text2, styles.company_bold]}>
                      {invoice.company.billing_state}
                    </Text>
                  ) : (
                    <Text style={[styles.company_text2, styles.company_bold]}>
                      {invoice.customer.billing.state != '' &&
                        invoice.customer.billing.state != 'OTHERTERRITORY' &&
                        invoice.customer.billing.state != '97-OTHERTERRITORY' &&
                        invoice.customer.billing.state}
                      {invoice.customer.billing.state == '' && invoice.company.billing_state}{' '}
                      {invoice.is_export == 1 &&
                        invoice.customer.billing.state == '97-OTHERTERRITORY' && (
                          <Text style={{ textTransform: 'uppercase' }}>
                            {invoice.export_details[0].name}
                          </Text>
                        )}
                    </Text>
                  )}
                </View> */}

                <PlaceOfSupply_2
                  invoice={invoice}
                  styles={{
                    block: { ...styles.company_blockC50, ...styles.company_borderRight },
                    title: styles.company_label,
                    text: { ...styles.company_text2, ...styles.company_bold },
                  }}
                  in_new_line={false}
                />
                {invoice.invoice_settings.show_due_date == 1 && (
                  <View style={styles.company_blockC50}>
                    {invoice.document_type != 'delivery_challans' && (
                      <>
                        <Text style={styles.company_label}>
                          {`${
                            invoice.invoice_settings.labels[`${invoice.document_type}_due_date`]
                          }:`}{' '}
                        </Text>
                        <Text style={[styles.company_text2, styles.company_bold]}>
                          {invoice.document_due_date}
                        </Text>
                      </>
                    )}
                  </View>
                )}
              </View>
            )}

            {Object.keys(invoice).includes('eway_bill') && invoice.eway_bill.length > 0 && (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                <View style={[styles.company_blockC50, styles.company_borderRight]}>
                  <Text style={styles.company_label}>Eway Bill #: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.eway_bill[0].serial_number}
                  </Text>
                </View>

                <View style={[styles.company_blockC50]}>
                  <Text style={styles.company_label}>Vehicle Number: </Text>
                  <Text style={[styles.company_text2, styles.company_bold]}>
                    {invoice.eway_bill[0].vehicle_number}
                  </Text>
                </View>
              </View>
            )}

            {newarray.map((item, index) => (
              <View style={[styles.company_block, styles.company_borderBottom]}>
                {item.map((item2, index2) => (
                  <Fragment>
                    {index2 == 0 && (
                      <View style={[styles.company_blockC50, styles.company_borderRight]}>
                        <Text style={styles.company_label}>{item2.label}: </Text>
                        <Text style={[styles.company_text2, styles.company_bold]}>
                          {item2.value}
                        </Text>
                      </View>
                    )}

                    {index2 == 1 && (
                      <View style={[styles.company_blockC50]}>
                        <Text style={styles.company_label}>{item2.label}:</Text>
                        <Text style={[styles.company_text2, styles.company_bold]}>
                          {item2.value}
                        </Text>
                      </View>
                    )}
                  </Fragment>
                ))}
              </View>
            ))}
            {'export_details' in invoice &&
              invoice.export_details.length > 0 &&
              export_labels.map((item2, index) => (
                <View style={[styles.company_block, styles.company_borderBottom]}>
                  {item2.map((item, index2) => (
                    <Fragment>
                      {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                        index2 == 0 &&
                        invoice.export_details[0][Object.keys(item)[0]] != '' &&
                        invoice.export_details[0][Object.keys(item)[0]] != null && (
                          <View style={[styles.company_blockC50, styles.company_borderRight]}>
                            <Text style={styles.company_label}>{item[Object.keys(item)[0]]}:</Text>
                            <Text style={[styles.company_text2, styles.company_bold]}>
                              {invoice.export_details[0][Object.keys(item)[0]]}
                            </Text>
                          </View>
                        )}
                      {Object.keys(invoice.export_details[0]).includes(Object.keys(item)[0]) &&
                        index2 == 1 &&
                        invoice.export_details[0][Object.keys(item)[0]] != '' &&
                        invoice.export_details[0][Object.keys(item)[0]] != null && (
                          <View style={[styles.company_blockC50]}>
                            <Text style={styles.company_label}>{item[Object.keys(item)[0]]}:</Text>
                            <Text style={[styles.company_text2, styles.company_bold]}>
                              {invoice.export_details[0][Object.keys(item)[0]]}
                            </Text>
                          </View>
                        )}
                    </Fragment>
                  ))}
                </View>
              ))}

            {Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0 && (
              <View style={[styles.company_block]}>
                <View style={[styles.company_blockC100]}>
                  <Text style={styles.company_irn}>IRN: {invoice.einvoice[0].irn}</Text>
                  <Text style={styles.company_irn}>
                    Acknowledgement Number, Date: {invoice.einvoice[0].ack_no},{' '}
                    {invoice.einvoice[0].ack_date}
                  </Text>

                  <View style={styles.company_qrcode}>
                    <EnhancedQRCode value={`${invoice.einvoice[0].qrcode}`} level="L" />
                  </View>
                </View>
              </View>
            )}

            {invoice.einvoice.length == 0 && (
              <View style={[styles.company_block]}>
                <View style={[styles.company_blockC100]}>
                  {!dispatch_to_doc_types.includes(invoice.document_type) &&
                    invoice.customer.shipping != undefined &&
                    invoice.customer.shipping.address_1 != null &&
                    invoice.customer.shipping.address_1 != '' && (
                      <>
                        <Text
                          style={styles.company_label}
                        >{`${invoice.invoice_settings.labels.customer_shipping_address}:`}</Text>
                        {invoice.customer.shipping.title != '' && (
                          <Text style={styles.company_text}>{invoice.customer.shipping.title}</Text>
                        )}
                        <Text style={styles.company_text}>
                          {invoice.customer.shipping.address_1}
                        </Text>
                        <Text style={styles.company_text}>
                          {invoice.customer.shipping.address_2}
                        </Text>
                        <Text style={styles.company_text}>
                          {invoice.customer.shipping.city != '' && invoice.customer.shipping.city}
                          {invoice.is_export != 1 &&
                            invoice.customer.shipping.state != '' &&
                            invoice.customer.shipping.state != undefined &&
                            invoice.customer.shipping.state != null &&
                            (getStateName(invoice.customer.shipping.state) != 'OTHERTERRITORY' ||
                              getStateName(invoice.customer.shipping.state) !=
                                '97-OTHERTERRITORY' ||
                              getStateName(invoice.customer.shipping.state) != 'OTHER TERRITORY') &&
                            (invoice.customer.shipping.city != '' ? ', ' : '') +
                              getStateName(invoice.customer.shipping.state)}
                          {invoice.is_export == 1 &&
                            invoice.customer.shipping.country &&
                            invoice.customer.shipping.country != 'India' &&
                            '\n' + invoice.customer.shipping.country}
                          {invoice.customer.shipping.pincode != '' &&
                            invoice.customer.shipping.pincode != null &&
                            invoice.customer.shipping.pincode != 'None' &&
                            invoice.customer.shipping.pincode != undefined &&
                            (invoice.customer.shipping.city != '' ||
                            invoice.customer.shipping.state != ''
                              ? ', '
                              : '') + invoice.customer.shipping.pincode}
                          {invoice.customer.shipping.notes != '' &&
                            '\n' + invoice.customer.shipping.notes}
                        </Text>
                      </>
                    )}

                  {invoice.gst_details.id == undefined &&
                    invoice.company.shipping_address.address_1 != '' &&
                    invoice.invoice_settings.show_dispatch_address === 1 && (
                      <View style={styles.company_mt}>
                        <Text style={styles.company_label}>
                          {dispatch_to_doc_types.includes(invoice.document_type)
                            ? invoice.invoice_settings.labels.dispatch_to + ':'
                            : invoice.invoice_settings.labels.dispatch_from + ':'}
                        </Text>
                        {/*{invoice.company.shipping.title != '' ? (
                          <Text style={styles.company_text}>{invoice.customer.shipping.title}</Text>
                        ) : (
                          <Text style={styles.company_text}>{invoice.company.company_name}</Text>
                        )}*/}
                        <Text style={styles.company_text}>
                          {invoice.company.shipping_address.title}
                        </Text>
                        <Text style={styles.company_text}>
                          {invoice.company.shipping_address.address_1}
                        </Text>
                        <Text style={styles.company_text}>
                          {invoice.company.shipping_address.address_2}
                        </Text>
                        <Text style={styles.company_text}>
                          {invoice.company.shipping_address.city != '' &&
                            invoice.company.shipping_address.city}
                          {invoice.company.shipping_address.state != '' &&
                            invoice.company.shipping_address.state != undefined &&
                            invoice.company.shipping_address.state != null &&
                            (getStateName(invoice.company.shipping_address.state) !=
                              'OTHERTERRITORY' ||
                              getStateName(invoice.company.shipping_address.state) !=
                                '97-OTHERTERRITORY' ||
                              getStateName(invoice.company.shipping_address.state) !=
                                'OTHER TERRITORY') &&
                            (invoice.company.shipping_address.city != '' ? ', ' : '') +
                              getStateName(invoice.company.shipping_address.state)}
                          {invoice.is_export == 1 &&
                            invoice.company.shipping_address.country &&
                            invoice.company.shipping_address.country != 'India' &&
                            '\n' + invoice.company.shipping_address.country}
                          {invoice.company.shipping_address.pincode != '' &&
                            invoice.company.shipping_address.pincode != null &&
                            invoice.company.shipping_address.pincode != 'None' &&
                            invoice.company.shipping_address.pincode != undefined &&
                            (invoice.company.shipping_address.city != '' ||
                            invoice.company.shipping_address.state != ''
                              ? ', '
                              : '') + invoice.company.shipping_address.pincode}
                          {invoice.company.shipping_address.notes != '' &&
                            '\n' + invoice.company.shipping_address.notes}
                        </Text>
                      </View>
                    )}
                </View>
              </View>
            )}

            {invoice.reference != '' && (
              <View style={[styles.company_block]}>
                <View style={[styles.company_blockC100]}>
                  <Text style={styles.company_label}>Reference: </Text>
                  <Text style={styles.company_text2}>{invoice.reference}</Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export default CompanyDetails
