import { Document, Font, Image, Page, View } from '@react-pdf/renderer'
import { registerFont } from 'components/fonts'
import { get_invoice_title, get_invoice_type } from '../export_condition'
import CompanyDetails from './company'
import InvoiceItemsTable from './invoiceItemsTable'
import InvoiceBankDetails from './invoiceNotes'
import InvoiceTitle from './invoiceTitle'
import InvoiceFooter from './invoiceFooter'
import { styles as getStyles } from './styles'
import Banner from '../banner'

export default function Invoice({ invoice }) {
  const hyphenationCallback = word => {
    return word.split('')
  }

  registerFont(invoice)
  Font.registerHyphenationCallback(hyphenationCallback)

  var styles = getStyles(invoice)
  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <Document>
      {invoice.type.map((item, index) => (
        <Page
          wrap
          size={invoice.invoice_settings.paper_size}
          orientation={'landscape'}
          key={index}
          style={{
            ...styles.index_page,
            paddingTop:
              16 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
            paddingBottom:
              30 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            paddingLeft: !!invoice.invoice_settings.margin_left
              ? invoice.invoice_settings.margin_left
              : 10,
            paddingRight: !!invoice.invoice_settings.margin_right
              ? invoice.invoice_settings.margin_right
              : 10,
          }}
        >
          {/* Header Banner */}
          {invoice.invoice_settings.invoice_header_image != '' && (
            <View style={styles.index_brandingLogo}>
              <Image
                style={styles.index_banner}
                src={{
                  uri: invoice.invoice_settings.invoice_header_image,
                  //uri: '/resources/images/banner3.png',
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </View>
          )}
          <InvoiceTitle
            title={get_invoice_title(
              invoice.document_type,
              invoice.document_title,
              invoice.with_tax,
              invoice.company.gstin,
              item,
              invoice,
            )}
            type={get_invoice_type(invoice.document_type, item)}
            color={invoice.company.color}
            invoice={invoice}
          />
          <View style={styles.index_container}>
            <CompanyDetails fixed invoice={invoice} type={item} />
            <Banner
              invoice={invoice}
              count={1}
              borderSides={1}
              borderTop={1}
              paddingHorizontal={4.5}
              borderRadius={1.8}
            />
            <InvoiceItemsTable invoice={invoice} type={item} />
            <Banner invoice={invoice} count={2} paddingHorizontal={4.5} borderRadius={1.8} />
            <InvoiceBankDetails invoice={invoice} type={item} />
          </View>
          <InvoiceFooter invoice={invoice} type={item} />
        </Page>
      ))}
    </Document>
  )
}
