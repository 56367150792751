export const amountFormatter = (amount, maximumFractionDigits = 2) => {
  const price_options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }

  return amount.toLocaleString('en-IN', price_options).replace(/(\.0+|0+)$/, '')
}
export const dispatch_to_doc_types = [
  'purchases',
  'sales_returns',
  'purchase_orders',
  'purchase',
  'sales_return',
  'purchase_order',
]

export const HIDE_PRICES = 'Hide Prices'

export const formatPageNumber = (pageNumber, totalPages, invoiceTypes) => {
  if (!invoiceTypes || invoiceTypes.length === 0) {
    return `Page ${pageNumber} / ${totalPages}` // Default behavior if invoice types are missing
  }

  // Calculate pages per type
  const pagesPerType = Math.ceil(totalPages / invoiceTypes.length)

  // Adjust current page number
  const adjustedPageNumber = ((pageNumber - 1) % pagesPerType) + 1

  return `Page ${adjustedPageNumber} / ${pagesPerType}`
}
