import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import {
  Affix,
  AutoComplete,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
  Upload,
} from 'antd'
import ImgCrop from 'antd-img-crop'
import BetaBadge from 'components/badges/beta'
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import _ from 'lodash'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { getAPI, getMultipartAPI } from 'services/jwt'
import { DELIVERY_CHALLAN, INVOICE, SERVICE } from 'utils/constants'
import { BATCHES_NOT_ALLOWED } from 'utils/errorCodes'
import { get_auto_complete_product_format } from './utility'
const Option = Select.Option

const { Dragger } = Upload

const SelectItem = forwardRef(
  (
    {
      product_categories,
      productBatchesRef,
      document_type,
      serialNumberRef,
      edit,
      convert,
      duplicate,
      allowBatches,
      productFormRef,
      selectedPriceList,
      warehouse_id,
      document_settings,
      data,
      updateInProductsTable,
      onFinishBatchesQty,
      onChange,
      export_type,
      total_discount,
      customerDiscount,
      with_tax,
      onCustomChange,
      qty_name,
      custom_columns,
      is_export,
      is_multi_currency,
      conversion_factor,
      count,
      onUploadItems,
      menu_name,
      hideUploadItems,
      getItemSuggestionsRef,
    },
    productsTableRef,
  ) => {
    const auto = useRef(null)
    const qtyInput = useRef(null)
    const formRef = useRef(null)
    const [timeout, setSearchTimeout] = useState(null)
    const [searchVisible, setSearchVisible] = useState(false)
    const [selected_category, setSelectedCategory] = useState('')
    const [searchText, setSearchText] = useState('')
    const [apiSearchText, setApiSearchText] = useState('')
    const [page, setPage] = useState(0)
    const [num_records, setNumRecords] = useState(25)
    const [selected, setSelected] = useState(false)
    const [products, setProducts] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [prevReq, setPrevReq] = useState({})
    const [files, setFiles] = useState([])
    const [isModalVisible, setModalVisible] = useState(false)
    const [fileType, setFileType] = useState('')
    const [batchAIUpload, setBatchAIUpload] = useState(false)
    const [batchUploadFiles, setBatchUploadFiles] = useState([])

    const debouncedSetAPISearchText = useCallback(
      _.debounce(value => setApiSearchText(value), 500),
      [],
    )

    useEffect(() => {
      if (searchText === '') {
        setApiSearchText('')
      } else {
        debouncedSetAPISearchText(searchText)
      }
    }, [searchText])

    useImperativeHandle(productsTableRef, () => ({
      doSearch,
      resetForm,
      getSearchText,
      focusProductSearch,
    }))

    useEffect(() => {
      doSearch(searchText)
    }, [selected_category])

    const resetForm = () => {
      formRef?.current?.setFieldsValue({
        product_name: '',
        value: '',
        qty: '',
      })
    }

    const getSearchText = () => {
      return searchText
    }
    const focusProductSearch = () => {
      auto?.current?.focus()
    }

    const handleEnter = event => {
      if (event.key.toLowerCase() === 'enter') {
        const form = event.target.form
        const index = [...form].indexOf(event.target)
        form.elements[index + 1].click()
        event.preventDefault()
      }
    }

    const handleSubmit = async values => {
      if (selected) {
        const index = data.findIndex(item => item.id == selectedData.id)
        values.qty = values.qty == '' || values.qty == null ? 1 : values.qty
        const final_discount =
          total_discount != 0 && total_discount != undefined
            ? total_discount
            : customerDiscount == 0
            ? selectedData.discount
            : customerDiscount

        if (with_tax == 0) {
          selectedData.price_with_tax = selectedData.unit_price
        }

        getItemSuggestionsRef?.current?.checkHitRate(selectedData.product_id)

        var newData = {
          ...selectedData,
          ...values,
          key: count + 1,
          discount: final_discount,
          discount_value: (
            (values.qty * selectedData.price_with_tax * final_discount) /
            100
          ).toFixed(2),
          discount_price_with_tax_value: (
            (1 * selectedData.price_with_tax * final_discount) /
            100
          ).toFixed(2),
          discount_net_value: ((values.qty * selectedData.price * final_discount) / 100).toFixed(2),
          discount_unit_price_value: ((1 * selectedData.price * final_discount) / 100).toFixed(2),
          description: selectedData.description,
          editorState: EditorState.createWithContent(stateFromHTML(selectedData.description)),
          expanded: true,
          cess_amount: values.qty * selectedData.unit_price * (selectedData.cess / 100),
          selected_discount_type: 'discount',
          is_discount_percent: 1,
          is_cess_on_qty_applicable: false,
          master_data: {
            price_with_tax: selectedData.actual_price_with_tax,
            unit_price: selectedData.unit_price,
            purchase_price: selectedData.purchase_price,
            purchase_unit_price: selectedData.purchase_unit_price,
            tax: selectedData.tax,
            cess: selectedData.cess,
            discount: selectedData.discount,
          },
          product_discount: selectedData.discount,
          selling_price: selectedData.actual_price_with_tax,
        }

        if (selectedData.custom_column_values) {
          var custom_column_values = selectedData.custom_column_values.split('::::')
        } else {
          var custom_column_values = []
        }

        if (selectedData.custom_column_names) {
          var custom_column_names = selectedData.custom_column_names.split('::::')
        } else {
          var custom_column_names = []
        }

        custom_columns.forEach((item, index) => {
          if (item.default_value != '') {
            item.name in newData
              ? console.log('')
              : (newData[item.name] = selectedData[item.default_value])
          } else {
            if (custom_column_names.includes(item.name)) {
              newData[item.name] = custom_column_values[custom_column_names.indexOf(item.name)]
            } else {
              item.name in newData ? console.log('') : (newData[item.name] = '')
            }
          }
        })
        if (is_export == 1 || is_multi_currency == 1) {
          if (isNaN(parseFloat(conversion_factor))) {
            message.error('Enter conversion factor')
            return true
          }
          newData['export_unit_price'] = newData['unit_price'] / parseFloat(conversion_factor)
          newData['export_price_with_tax'] =
            newData['price_with_tax'] / parseFloat(conversion_factor)
        }
        newData = await updateInProductsTable(newData)
        setSelected(false)
        setSelectedData({})
        formRef?.current?.setFieldsValue({
          product_name: '',
          qty: '',
          price: '',
          price_with_tax: '',
          discount: '',
          discount_value: '',
          discount_net_value: '',
          discount_unit_price_value: '',
          discount_price_with_tax_value: '',
          description: '',
          value: '',
        })
        auto?.current?.focus()

        // onCustomChange(newData['qty'], newData['key'], qty_name, 'qty')

        if (index != -1) {
          message.warning('Same Product already in Table')
        }
      } else {
        formRef?.current?.setFieldsValue({
          product_name: '',
          value: '',
          qty: '',
        })
        productFormRef.onAdd(values.value)
      }
      setSearchText('')
      setPage(0)
      doSearch('')
    }

    const affix = x => {
      setSearchVisible(x)
    }

    const onInputFocus = e => {
      e.target.select()
    }

    const qtyOnChange = e => {
      const value = e
      if (isNaN(value) || value == '') {
        message.error('Enter correct Qty')
      } else {
        const data = selectedData
        data.qty = value
        formRef.current.setFieldsValue({
          ...data,
        })
      }
    }

    const doSearch = text => {
      // let timeout = null
      setSearchText(text)
      if (timeout) {
        clearTimeout(timeout)
      }

      setSearchTimeout(
        setTimeout(() => {
          onProductSearch(typeof text == 'string' ? text : text.target.value)
        }, 500),
      )
    }

    const queryClient = useQueryClient()

    const { data: queryProducts, fetchNextPage, refetch } = useInfiniteQuery({
      queryKey: [
        'product',
        'search',
        apiSearchText,
        selectedPriceList,
        num_records,
        selected_category,
        document_type,
        warehouse_id,
      ],
      queryFn: async ({ pageParam = 0 }) => {
        const req = {
          query: apiSearchText,
          selected_price_list: selectedPriceList,
          page: pageParam,
          num_records: num_records,
          category: selected_category,
          document_type: document_type,
          products_for_sale:
            document_type == 'invoice' || document_type == 'sales_return' ? true : false,
          warehouse_id: warehouse_id,
        }
        return getProducts(req)
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => lastPage.nextPage,
    })

    const onBarCodeScan = async (searchText, call_api, resData) => {
      const req = {
        query: searchText.replace('$', ''),
        selected_price_list: selectedPriceList,
        page: 0,
        num_records: num_records,
        category: selected_category,
      }

      if (call_api) {
        var resData = await getAPI('product', 'barcode', req)
      }

      if (resData && resData.success && resData.products) {
        const serviceCheck =
          resData.products?.['product_type'] != SERVICE ||
          document_settings.track_stock_for_services == 1
        const documentTypeCheck =
          document_type == INVOICE ||
          (document_settings.track_stock_for_delivery_challan && document_type == DELIVERY_CHALLAN)
        const hasNegetiveQty = checkNegetiveQty(resData.products)

        if (
          document_settings.allow_negative_qty == 0 &&
          hasNegetiveQty &&
          documentTypeCheck &&
          serviceCheck
        ) {
          message.error('Billing for this product is not allowed as the quantity is less than 0')
          auto?.current?.blur()
          auto?.current?.focus()
          setSearchText('')
          return
        }

        if (resData.products?.has_batches != 0 && document_type == 'subscription') {
          message.error(BATCHES_NOT_ALLOWED)
          setSearchText('')
          return
        }

        const index = data.findIndex(
          item =>
            item.id ==
            String(resData.products?.product_id) +
              '_' +
              String(resData.products?.variant_id == undefined ? 0 : resData.products?.variant_id),
        )
        if (index == -1) {
          // resData.products.tax = export_type?.includes('Deemed') ? 0.1 : resData.products?.tax

          let item = resData.products
          if (item && export_type?.includes('Deemed')) {
            item.tax = 0.1
          }
          const final_discount = document_type.includes('purchase')
            ? 0
            : total_discount != 0 && total_discount != undefined
            ? total_discount
            : customerDiscount == 0
            ? item.discount
            : customerDiscount
          item.actual_price_with_tax = item?.price_with_tax
          item.actual_unit_price = item?.unit_price

          if (
            document_type.includes('purchase') &&
            item.purchase_price != 0 &&
            selectedPriceList == 0
          ) {
            item.price = item.purchase_price / (1 + (item.tax + item.cess) / 100)
            item.unit_price = item.price
            item.price_with_tax = item.purchase_price
            item.is_price_with_tax = item.is_purchase_price_with_tax
          }

          item.master_data = {
            price_with_tax: item.actual_price_with_tax,
            unit_price: item.unit_price,
            discount: item.discount,
            cess: item.cess,
            tax: item.tax,
            purchase_price: item.purchase_price,
            purchase_unit_price: item.purchase_unit_price,
          }

          if (item.has_batches == 2 && document_type == 'purchase') {
            serialNumberRef.onAdd(item, true)
            return
          }

          if (item.match_reason == 'batch' && allowBatches(document_type)) {
            let serial_numbers = []
            item.batches.map(batch => {
              if (batch.batch_id == item.batch_id) {
                item.batch_no = batch.batch_no
                batch.added_qty = 1
                batch.changed = true
                serial_numbers.push(batch)
              }
            })
            item.serial_numbers = serial_numbers
          }
          if (item.match_reason != 'batch') {
            if (item.has_batches == 1 || (document_type != 'purchase' && item.has_batches == 2)) {
              productBatchesRef?.current.showModal(item, edit || convert || duplicate)
              return
            } else if (item.has_batches == 2 && document_type == 'purchase') {
              serialNumberRef.onAdd(item, true, true)
              return
            }
          }

          getItemSuggestionsRef?.current?.checkHitRate(item.product_id)

          const newData = {
            key: count + 1,
            ...item,
            qty: 1,
            id:
              String(item.product_id) +
              '_' +
              String(item.variant_id == undefined ? 0 : item.variant_id),
            value:
              item.product_name +
              (item.variant_name == '' || item.variant_name == null ? '' : ' ' + item.variant_name),
            editorState: EditorState.createWithContent(stateFromHTML(item.description)),
            expanded: true,
            cess_amount: item.unit_price * (item.cess / 100),
            qtyinstock: item.qty,
            discount: final_discount,
            discount_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
            conversion_rate:
              item.unit_id == 0 || item.unit_id == null
                ? 1
                : item.units
                ? item.units.filter(item => item.unit_id == item.unit_id)?.[0].conversion_rate
                : 1,
            unit:
              item.unit == '' || item.unit == null
                ? ''
                : item.units
                ? item.units.filter(item => item.unit_id == item.unit_id)?.[0].alternative_unit
                : item.unit,
            discount_price_with_tax_value: ((item.price_with_tax * final_discount) / 100).toFixed(
              2,
            ),
            discount_net_value: ((item.unit_price * final_discount) / 100).toFixed(2),
            discount_unit_price_value: ((item.unit_price * final_discount) / 100).toFixed(2),
            selected_discount_type: 'discount',
            is_discount_percent: 1,
            is_cess_on_qty_applicable: false,

            selling_price: item.actual_price_with_tax,
            product_discount: item.discount,
            master_data: {
              price_with_tax: item.actual_price_with_tax,
              unit_price: item.unit_price,
              discount: item.discount,
              cess: item.cess,
              tax: item.tax,
              purchase_price: item.purchase_price,
              purchase_unit_price: item.purchase_unit_price,
            },
          }

          if (item.custom_column_values) {
            var custom_column_values = item.custom_column_values.split('::::')
          } else {
            var custom_column_values = []
          }

          if (item.custom_column_names) {
            var custom_column_names = item.custom_column_names.split('::::')
          } else {
            var custom_column_names = []
          }

          custom_columns.forEach((item, index) => {
            if (item.default_value != '') {
              item.name in newData
                ? console.log('')
                : (newData[item.name] = newData[item.default_value])
            } else {
              if (custom_column_names.includes(item.name)) {
                newData[item.name] = custom_column_values[custom_column_names.indexOf(item.name)]
              } else {
                item.name in newData ? console.log('') : (newData[item.name] = '')
              }
            }
          })
          if (is_export == 1 || is_multi_currency == 1) {
            if (isNaN(parseFloat(conversion_factor))) {
              message.error('Enter conversion factor')
              return true
            }
            newData['export_unit_price'] = newData['unit_price'] / parseFloat(conversion_factor)
            newData['export_price_with_tax'] =
              newData['price_with_tax'] / parseFloat(conversion_factor)
          }

          updateInProductsTable(newData)
          setSelected(false)
          setSearchText('')
          auto?.current?.focus()
        } else {
          if (data[index].has_batches && allowBatches(document_type)) {
            data[index].batches.map(batch => {
              if (batch.batch_id == resData.products.batch_id) {
                batch.added_qty = (batch.added_qty ? batch.added_qty : 0) + 1
                batch.changed = true
              }
            })
            onFinishBatchesQty(data[index], data[index].batches)
          } else {
            onChange(data[index].qty + 1, data[index].key, 'qty')
          }

          setSelected(false)
          setSearchText('')
          auto?.current?.focus()
        }

        formRef.current.setFieldsValue({
          product_name: '',
          qty: '',
          price: '',
          price_with_tax: '',
          discount: '',
          discount_value: '',
          discount_net_value: '',
          discount_unit_price_value: '',
          discount_price_with_tax_value: '',
          description: '',
          value: '',
        })
      }
    }

    const getUnitDetails = (units, unit, unit_id) => {
      let final_unit = ''
      let conversion_rate = 1
      if (unit == null) {
        final_unit = ''
      } else {
        if (units.length > 0) {
          let unit_details = units.filter(item => item.unit_id == unit_id)
          if (unit_details.length > 0) {
            final_unit = unit_details[0].alternative_unit
          } else {
            final_unit = unit
          }
        }
      }

      if (unit_id == 0 || unit_id == null) {
        conversion_rate = 1
      } else {
        if (units.length > 0) {
          let unit_details = units.filter(item => item.unit_id == unit_id)
          if (unit_details.length > 0) {
            conversion_rate = unit_details[0].conversion_rate
          }
        }
      }

      return {
        conversion_rate: conversion_rate,
        unit: final_unit,
      }
    }
    const checkNegetiveQty = item =>
      item?.has_batches == 1 ? !item?.batches.some(val => val.qty > 0) : item?.qtyinstock <= 0

    const onProductSelect = (value, object) => {
      const serviceCheck =
        object['product_type'] != SERVICE || document_settings.track_stock_for_services == 1

      const documentTypeCheck =
        document_type == INVOICE ||
        (document_settings.track_stock_for_delivery_challan && document_type == DELIVERY_CHALLAN)
      const hasNegetiveQty = checkNegetiveQty(object)
      if (
        document_settings.allow_negative_qty == 0 &&
        hasNegetiveQty &&
        documentTypeCheck &&
        serviceCheck
      ) {
        message.error('Billing for this product is not allowed as the quantity is less than 0')
        auto?.current?.blur()
        auto?.current?.focus()
      } else {
        if (object.value === 'Add New Product') {
          queryClient.invalidateQueries({ queryKey: ['product'] })
          productFormRef.onAdd(object['product_name'])
        } else {
          if (object.key != 'null') {
            setSelected(true)
            setSearchText(object['product_name'])
            setProducts([])
            setSelectedData(object)

            if (object.has_batches != 0 && document_type == 'subscription') {
              message.error(BATCHES_NOT_ALLOWED)
              setSearchText('')
              return
            }

            if (object.has_batches != 0 && allowBatches(document_type)) {
              //check in data
              let product = data.find(
                item =>
                  item.product_id == object.product_id && item.variant_id == object.variant_id,
              )
              if (
                object.has_batches == 1 ||
                (document_type != 'purchase' && object.has_batches == 2)
              ) {
                if (product) {
                  if (product.has_batches == 2) {
                    let serial_numbers = product.serial_numbers.map(item => item.batch_id)
                    product.batches = product.batches.map(item => {
                      if (serial_numbers.includes(item.batch_id)) {
                        item.changed = true
                        item.added_qty = 1
                      }
                      return item
                    })
                  }
                  productBatchesRef.current.showModal(product, edit)
                } else {
                  productBatchesRef.current.showModal(object, edit)
                }
              }
              if (object.has_batches == 2 && document_type == 'purchase') {
                if (product) {
                  serialNumberRef.onAdd(product, true, true)
                } else {
                  serialNumberRef.onAdd(object, true)
                }
              }
              return
            }

            qtyInput?.current?.focus()
            formRef.current.setFieldsValue({
              ...object,
              qty: '',
            })
          } else {
            // console.log('here')
          }
        }
      }
    }

    const getProducts = async req => {
      const data = await getAPI('product', 'search', req)
      if (data) {
        let allData = data.products.map(value => {
          if (document_type.includes('purchase') && value.discount != 0 && selectedPriceList == 0) {
            value.discount = 0
          }
          value.actual_price_with_tax = value.price_with_tax
          value.actual_unit_price = value.unit_price
          if (
            document_type.includes('purchase') &&
            value.purchase_price != 0 &&
            selectedPriceList == 0
          ) {
            value.price = value.purchase_unit_price
            value.unit_price = value.price
            value.price_with_tax = value.purchase_price
            value.is_price_with_tax = value.is_purchase_price_with_tax
          }
          value.tax = export_type?.includes('Deemed') ? 0.1 : value.tax
          let unitobj = getUnitDetails(value.units, value.unit, value.unit_id)
          return {
            ...value,
            value:
              value.product_name +
              (value.variant_name == '' || value.variant_name == null
                ? ''
                : ' ' + value.variant_name),
            // id: value.product_id,
            id:
              String(value.product_id) +
              '_' +
              String(value.variant_id == undefined ? 0 : value.variant_id),
            description: value.description,
            tax: value.tax,
            qty: 1,
            qtyinstock: value.qty,
            product_name: value.product_name,
            discount: value.discount,
            discount_value: ((value.price_with_tax * value.discount) / 100).toFixed(2),
            discount_price_with_tax_value: ((value.price_with_tax * value.discount) / 100).toFixed(
              2,
            ),
            discount_net_value: ((value.price * value.discount) / 100).toFixed(2),
            discount_unit_price_value: ((value.price * value.discount) / 100).toFixed(2),
            barcode_id: value.barcode_id,
            variant_name: value.variant_name,
            variant_id: value.variant_id ? value.variant_id : 0,
            unit_id: value.unit_id ? value.unit_id : 0,
            unit: unitobj.unit,
            conversion_rate: unitobj.conversion_rate,
          }
        })
        allData = get_auto_complete_product_format(allData, document_settings)
        let nextPage = null
        if (data?.total && data?.total > req.num_records * (req.page + 1)) {
          nextPage = req.page + 1
        }
        return { products: [...allData], nextPage }
      }
      return { products: [], nextPage: null }
    }
    const handleScroll = e => {
      const bottom =
        parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1
      if (bottom) {
        fetchNextPage()
      }
    }

    const onProductSearch = async (searchText, item) => {
      if (typeof searchText != 'string') {
        formRef.current.setFieldsValue({
          product_name: '',
          value: '',
          qty: '',
        })
        return true
      }

      if (searchText.length > 0) {
        if (searchText.includes('$')) {
          onBarCodeScan(searchText, true, {})
        } else {
          const req = {
            query: searchText,
            selected_price_list: selectedPriceList,
            page: 0,
            num_records: num_records,
            category: selected_category,
            document_type: document_type,
            products_for_sale:
              document_type == 'invoice' || document_type == 'sales_return' ? true : false,
            warehouse_id: warehouse_id,
          }
          setSearchText(searchText)
          const resData = await getAPI('product', 'barcode', req)
          if (!('message' in Object(resData))) {
            onBarCodeScan(searchText, false, resData)
          } else {
            refetch()
          }
        }
      } else {
        const req = {
          query: searchText,
          selected_price_list: selectedPriceList,
          page: 0,
          num_records: num_records,
          category: selected_category,
          document_type: document_type,
          products_for_sale:
            document_type == 'invoice' || document_type == 'sales_return' ? true : false,
          warehouse_id: warehouse_id,
        }
        refetch()
      }
      setPage(0)
    }

    const beforeCrop = file => {
      if (file.type != 'application/pdf' && fileType != 'pdf') return true

      return false
    }

    const beforeUpload = file => {
      if (file.type == 'application/pdf') {
        if (files.length > 0) {
          message.error('Only single PDF file will be accepted')
          return false
        }
        if (fileType == 'image') {
          message.error('Select either multiple images or single PDF')
          return true
        }
        setFileType('pdf')
      } else {
        if (fileType == 'pdf') {
          message.error('Select either multiple images or single PDF')
          return true
        }
        setFileType('image')
      }

      const isLt3M = file.size / 1024 / 1024 < 25
      if (!isLt3M) {
        message.error('File must smaller than 25MB!')
        return false
      }
      setFiles(prev => [...prev, file])

      return isLt3M
    }

    const onRemove = file => {
      const newFiles = files.filter(file1 => file1.uid !== file.uid)
      setFiles(newFiles)
      if (newFiles.length == 0) {
        setFileType('')
      }
    }

    const handleAddToBill = () => {
      if (selectedData.has_batches == 0 || !allowBatches(document_type) || !selected) {
        formRef?.current.submit()
        setSearchText('')
      } else {
        if (
          selectedData.has_batches == 1 ||
          (document_type != 'purchase' && selectedData.has_batches == 2)
        ) {
          productBatchesRef?.current.showModal(selectedData, edit || convert || duplicate)
        } else if (selectedData.has_batches == 2 && document_type == 'purchase') {
          serialNumberRef.onAdd(selectedData, true, true)
        }
      }
    }

    const checkBatch = () => {
      if (hideUploadItems) {
        setBatchAIUpload(true)
        setBatchUploadFiles([])
      } else {
        setModalVisible(true)
        setFiles([])
      }
    }

    const batchHandleFileRemove = file => {
      setBatchUploadFiles(prevFiles => prevFiles.filter(f => f.uid !== file.uid))
    }

    const batchHandleBeforeUpload = file => {
      setBatchUploadFiles(prevFiles => [...prevFiles, file])
      return false
    }

    const batchHandleUploadSubmit = async () => {
      if (batchUploadFiles.length === 0) {
        return
      }

      const formData = new FormData()

      if (batchUploadFiles.length === 0) {
        return
      }

      for (let i = 0; i < batchUploadFiles.length; i++) {
        formData.append('files', batchUploadFiles[i])
      }
      formData.append('feature', 'document_upload')
      const data = await getMultipartAPI('swipe_ai', 'parse', formData)
      if (data) {
        if (data?.success) {
          message.success('Thank you for your help!')
        }
      }
      setBatchUploadFiles([])
    }

    return (
      <Affix offsetTop={64} onChange={affix}>
        <Row
          type="flex"
          justify="around"
          align="middle"
          className={
            !searchVisible ? 'bg-card-azure' : 'bg-card-azure shadow-sm px-4 pt-2 rounded-bottom'
          }
        >
          <div className="flex items-center justify-between select-product">
            <Form
              className="center mb-2"
              layout="inline"
              onFinish={handleSubmit}
              size="default"
              ref={formRef}
            >
              <div className="">
                <Form.Item name="value" rules={[{ required: true, message: 'Fill Product Name' }]}>
                  <span className="flex">
                    <span className="hidden sm:block">
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        // disabled={true}
                        showSearch
                        allowClear={true}
                        className="responsive-select product-autocomplete-select"
                        placeholder="All Categories"
                        optionFilterProp="children"
                        onChange={e => {
                          setSelectedCategory(e)
                          auto?.current?.focus()
                          // doSearch(formRef?.current.getFieldValue('value') || '')
                        }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        listHeight={200}
                        dropdownStyle={{ minWidth: '300px' }}
                      >
                        <Option value={'All'} key={0}>
                          {'All'}
                        </Option>
                        {product_categories.map(item => (
                          <Option value={item.category_name} key={item.category_name}>
                            {item.category_name}
                          </Option>
                        ))}
                      </Select>
                    </span>

                    <AutoComplete
                      ref={auto}
                      value={searchText}
                      // autoFocus
                      onSearch={doSearch}
                      onSelect={onProductSelect}
                      options={queryProducts?.pages?.map(page => page.products).flat()}
                      className="mr-2 pb-2 product-autocomplete"
                      style={{ width: '500px', height: '32px' }}
                      popupMatchSelectWidth={640}
                      dropdownMenuStyle={{ top: 18 }}
                      trigger="hover"
                      dropdownRender={menu => (
                        <>
                          {menu}
                          <div style={{ position: 'sticky', bottom: 0 }}>
                            <div className="bg-gray-75">
                              <Button
                                block
                                type="link-2"
                                className="font-weight-bolder text-left my-2 arrow-transition"
                                onMouseDown={e => e.preventDefault()}
                                onClick={() =>
                                  productFormRef.onAdd(formRef.current.getFieldValue('value'))
                                }
                              >
                                <i1 className="fa-solid fa-circle-plus mr-2"></i1>
                                Add New Product
                                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                      onPopupScroll={handleScroll}
                      id="addProduct"
                      onFocus={onInputFocus}
                    >
                      <Input
                        style={{ borderStartStartRadius: '0px', borderEndStartRadius: '0px' }}
                        prefix={<i className="fa-solid fa-magnifying-glass text-gray-300"></i>}
                        placeholder="Search or scan barcode for existing products"
                      />
                    </AutoComplete>
                  </span>
                </Form.Item>
              </div>

              <div className="">
                <Form.Item name="qty" className="mt-0 pt-0">
                  <InputNumber
                    placeholder="Qty"
                    min={0}
                    onChange={qtyOnChange}
                    onKeyDown={handleEnter}
                    style={{ width: 135 }}
                    ref={qtyInput}
                    enterButton={
                      <>
                        <Button
                          type="primary"
                          className="font-weight-bold"
                          onClick={handleAddToBill}
                        >
                          <i className="fa fa-plus mr-1" /> Add to Bill
                        </Button>
                      </>
                    }
                  />
                </Form.Item>
              </div>

              <div className="">
                <Form.Item>
                  <Button type="primary" className="font-weight-bold" onClick={handleAddToBill}>
                    <i className="fa fa-plus mr-1" />
                    <span className="hidden sm:inline">Add to Bill</span>
                  </Button>
                </Form.Item>
              </div>
            </Form>

            {!convert && !edit && document_type == 'purchase' && (
              <div className="float-right">
                <Tooltip
                  title={`Upload ${menu_name ?? ''} PDF to auto fill the items.`}
                  className="center"
                >
                  <div className="cursor-pointer mr-4 mb-2 " onClick={() => checkBatch()}>
                    <i class="fa-duotone text-primary fa-solid fa-sparkles mr-2"></i>
                    <span className="font-size-14 font-weight-medium text-primary italic">
                      Upload {menu_name ?? ''} with AI <BetaBadge />
                    </span>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </Row>

        <Modal
          title="Upload Files"
          open={isModalVisible}
          onOk={() => {
            onUploadItems(files)
            setFiles([])
            setFileType('')
            setModalVisible(false)
          }}
          onCancel={() => {
            setFiles([])
            setModalVisible(false)
            setFileType('')
          }}
          onClose={() => {
            setFiles([])
            setModalVisible(false)
            setFileType('')
          }}
          centered
          width={1000}
        >
          <ImgCrop rotationSlider aspectSlider beforeCrop={beforeCrop}>
            <Dragger
              customRequest={({ onsuccess, onError, file }) => {
                return
              }}
              multiple={true}
              maxCount={4}
              accept="application/pdf,image/png, image/jpeg"
              onRemove={onRemove}
              beforeUpload={beforeUpload}
              style={{ width: '100%' }}
              showUploadList
              fileList={files}
            >
              <>
                <div
                  className="flex flex-col justify-center items-center"
                  style={{ minHeight: '40vh' }}
                >
                  <p className="ant-upload-drag-icon">
                    <i className="fa-regular fa-sparkles fa-4x"></i>
                  </p>

                  <>
                    <p className="ant-upload-text"></p>
                    <p className="ant-upload-hint">
                      Click or Drag file to this area to upload.
                      <ul className="flex flex-col justify-start items-center mt-2">
                        <li>You can upload either multiple images or a single PDF file.</li>
                      </ul>
                    </p>
                  </>

                  <Button type="secondary" className="mt-3 mb-2 mr-3 font-weight-bold">
                    Select Files
                  </Button>
                </div>
              </>
            </Dragger>
          </ImgCrop>
        </Modal>
        <Modal
          title={"💡 Batching isn't supported yet - Let's change that together!"}
          open={batchAIUpload}
          onCancel={() => {
            setBatchAIUpload(false)
            setBatchUploadFiles([])
          }}
          width={640}
          centered
          closeIcon={<i className="fa-solid fa-xmark"></i>}
          footer={
            <>
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  batchHandleUploadSubmit()
                  setBatchAIUpload(false)
                }}
              >
                Submit
              </Button>
              <Button
                key="back"
                onClick={() => {
                  setBatchAIUpload(false)
                  setBatchUploadFiles([])
                }}
              >
                Close
              </Button>
            </>
          }
        >
          <>
            <span>
              Share a few invoices with us to help launch "Upload with AI" for batching products.
              <br />
              Your data will remain private and secure.
            </span>
            <div className="mt-4">
              <Dragger
                customRequest={() => {}}
                multiple={true}
                maxCount={4}
                accept="application/pdf,image/png,image/jpeg"
                onRemove={batchHandleFileRemove}
                beforeUpload={batchHandleBeforeUpload}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '16px',
                  border: '1px dashed #d9d9d9',
                }}
                showUploadList
                fileList={batchUploadFiles}
              >
                <>
                  <div
                    className="flex flex-col justify-center items-center"
                    style={{ minHeight: '10vh' }}
                  >
                    <p className="ant-upload-drag-icon">
                      <i className="fa-regular fa-upload fa-2x"></i>
                    </p>
                    <p className="ant-upload-text">
                      Drag and drop files here or click to select files.
                    </p>
                    <p className="ant-upload-hint">
                      Supported formats: PDFs, PNGs, JPEGs (max 4 files).
                    </p>
                  </div>
                </>
              </Dragger>
            </div>
          </>
        </Modal>
      </Affix>
    )
  },
)

export default SelectItem
