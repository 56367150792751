import React from 'react'
import { Text, Image, View } from '@react-pdf/renderer'
import { EnhancedQRCode } from 'components/other/qrCodeFile'
import { export_terms } from '../export_condition'
import RichText from '../RichText'
import { styles as getStyles } from './styles'

const InvoiceNotes = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  var FONT_FACTOR = invoice.invoice_settings.font_size

  return (
    <View
      style={[
        styles.invoiceNotes_container,
        invoice.invoice_settings.full_page == false && styles.globalContainer,
        styles.invoiceBankDetails_borderBottom,
        // { flex: 1 },
      ]}
    >
      <View style={[styles.invoiceBankDetails_blockC]}>
        {/*{ flex: 1 } */}
        <View style={[styles.invoiceBankDetails_blockC45, styles.invoiceBankDetails_borderRight]}>
          {invoice.rcm == 1 && (
            <View style={styles.global_mb}>
              <Text style={styles.invoiceBankDetails_label}>
                *Amount of Tax subject to Reverse Charge
              </Text>
            </View>
          )}
          {invoice.immovable_tax_type == 1 && (
            <View style={styles.global_mb}>
              <Text style={styles.invoiceBankDetails_label}>
                *Place of Supply is determined u/s 12(3) of the IGST Act 2017.
              </Text>
            </View>
          )}
          {invoice.is_export == 1 && invoice.company.gstin.length == 15 && (
            <View style={styles.global_mb}>
              <Text style={styles.invoiceBankDetails_label}>
                *{export_terms[invoice.export_details[0].export_type]}
              </Text>
            </View>
          )}
          {invoice.notes != '' && (
            <View style={[styles.invoiceBankDetails_block, styles.global_mb]}>
              <Text style={styles.invoiceBankDetails_label}>Notes:</Text>
              <Text style={styles.invoiceBankDetails_text}>{invoice.notes}</Text>
            </View>
          )}

          {invoice.invoice_settings.show_receivers_signature == 1 && (
            <View style={styles.invoiceNotes_receiveSignBlock} wrap={false}>
              <Text
                style={{
                  ...styles.invoiceNotes_receiveSignText,
                }}
              >
                Receiver's Signature{' '}
              </Text>
            </View>
          )}
        </View>
        <View style={[styles.invoiceBankDetails_blockC55]}>
          {invoice.terms != '' && type != 'Delivery Challan' && (
            <View style={styles.invoiceBankDetails_block}>
              <Text style={styles.invoiceBankDetails_label}>Terms and Conditions:</Text>
              <Text style={styles.invoiceBankDetails_text_small}>{invoice.terms}</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default InvoiceNotes
