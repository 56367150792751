import { Modal, Table } from 'antd'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useSelector } from 'react-redux'

const PurchaseConfirm = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false)
  const [columns, setColumns] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const custom_columns = useSelector(state => state.document.custom_column_data.custom_columns)
  const user = useSelector(state => state.user)

  const showModal = () => {
    let tempColumns = [
      {
        title: 'Product',
        dataIndex: 'name',
        key: 'id',
        width: 200,
        render: (text, record) => {
          return (
            <div className="flex items-center">
              <div>
                <div className="font-weight-bold font-size-18">{record.product_name}</div>
              </div>
            </div>
          )
        },
        hidden: false,
      },
      {
        title: 'Selling Price',
        dataIndex: 'unit_price',
        key: 'id',
        width: 100,
        render: (text, record) => {
          return (
            <div className="flex items-center">
              <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
              <span className="font-weight-bold">{record.selling_price}</span>

              {record.master_data.price_with_tax != record.selling_price && (
                <span
                  style={{ textDecoration: 'line-through' }}
                  className="text-gray-500 font-size-14 ml-2"
                >
                  {record.master_data.price_with_tax?.toFixed(2)}
                </span>
              )}
            </div>
          )
        },
        hidden: !props.purchase_update_columns.includes('price_with_tax'),
      },
      {
        title: 'Purchase Price',
        dataIndex: 'purchase_price',
        key: 'id',
        width: 100,

        // width: '20%',
        render: (text, record) => {
          return (
            <div className="flex items-center">
              <span className="mr-1">{user?.selectedCompany?.currency_symbol}</span>
              <span className="font-weight-bold">{record.price_with_tax}</span>

              {record.master_data?.purchase_price != record.price_with_tax && (
                <span
                  style={{ textDecoration: 'line-through' }}
                  className="text-gray-500 font-size-14 ml-2"
                >
                  {record.master_data.purchase_price?.toFixed(2)}
                </span>
              )}
            </div>
          )
        },
        hidden:
          !props.purchase_update_columns.includes('purchase_price') &&
          !props.showPurchasePriceColumn,
      },
      {
        title: 'Discount',
        dataIndex: 'product_discount',
        key: 'id',
        width: 100,

        // width: '20%',
        render: (text, record) => {
          return (
            <div className="flex items-center">
              <span className="font-weight-bold">{record.product_discount}%</span>

              {record.master_data.discount != record.product_discount && (
                <span
                  style={{ textDecoration: 'line-through' }}
                  className="text-gray-500 font-size-14"
                >
                  {record.master_data.discount?.toFixed(2)}
                </span>
              )}
            </div>
          )
        },
        hidden: !props.purchase_update_columns.includes('discount'),
      },
    ]
    if (props.purchase_update_columns.includes('custom_columns')) {
      custom_columns.map(column => {
        if (column.is_active == 1 && column.default_key == '') {
          tempColumns.push({
            title: column.name,
            dataIndex: column.name,
            key: column.name,
            width: 100,

            // width: '20%',
            render: (text, record) => {
              return (
                <div className="flex items-center">
                  <div className="font-weight-bold">{record[column.name]}</div>
                </div>
              )
            },
            hidden: false,
          })
        }
      })
    }
    tempColumns = tempColumns.filter(column => column.hidden == false)
    setSelectedRowKeys(props.products.map(product => product.id))
    setColumns(tempColumns)
    setOpen(true)
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    selectedRowKeys: selectedRowKeys,
  }

  return (
    <Modal
      maskClosable={true}
      title={
        <>
          <i
            className="fa-sharp fa-light fa-circle-exclamation font-size-22  mr-2 font-weight-bold"
            style={{ color: '#faad14' }}
          ></i>
          Product Update Confirmation
          <p className="text-gray-400 font-size-12">
            Selected products will be updated with the new values. Please confirm to proceed with
            the update.
          </p>
        </>
      }
      open={open}
      closable={false}
      onCancel={e => {
        setOpen(false)
        props.onPurchaseUpdateConfirm(selectedRowKeys)
      }}
      onClose={e => {
        setOpen(false)
      }}
      onOk={e => {
        setOpen(false)
        props.onPurchaseUpdateConfirm(selectedRowKeys)
      }}
      closeIcon={<i className="fa-solid fa-close" style={{ color: 'grey' }} />}
      okText="Confirm"
      cancelText="Cancel"
      style={{ minWidth: '80%' }}
    >
      <Table
        rowSelection={rowSelection}
        rowKey="id"
        scroll={{ x: 900 }}
        size="small"
        columns={columns}
        dataSource={props.products}
        pagination={false}
      />
    </Modal>
  )
})

export default PurchaseConfirm
