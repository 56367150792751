import { cloneDeep } from 'lodash'
import {
  SET_ALL_DOC_COUNTS,
  SET_API_LOADING,
  SET_BANK_DETAILS,
  SET_BATCH_SETTINGS,
  SET_BUSINESS_ANALYTICS,
  SET_CATEGORIES,
  SET_COUNTRIES,
  SET_CURRENCIES,
  SET_CUSTOM_COLUMNS,
  SET_CUSTOM_COLUMNS_SUGGESTION,
  SET_HSN_SUGGESTION,
  SET_INVOICE_SETTINGS,
  SET_PAYMENT_MODES,
  SET_PORT_CODES,
  SET_POS_SETTINGS,
  SET_PRODUCT_OPTIONS,
  SET_STATES,
  SET_TCS_SECTIONS,
  SET_TDS_SECTIONS,
  ADD_INVOICES_DATA,
  UPDATE_INVOICES_DATA,
  REMOVE_INVOICES_DATA,
  REMOVE_ALL_INVOICES_DATA,
  SET_UNITS,
  SET_TOP_CUSTOM_FIELDS,
  SET_NOTIFICATION_SETTINGS,
  SET_NOTIFICATION_COUNT,
  SET_PRICING_PLANS,
  UPDATE_NOTIFICATION_COUNT,
  SET_PREFIXES,
  SET_BARCODE_SETTINGS,
  SET_INVOICE_JUST_CREATED,
} from './types'
const initialState = {
  states: [],
  countries: [],
  countryLoading: false,
  tdsSections: [],
  tcsSections: [],
  categories: [],
  productCustomColumns: [],
  currencies: [],
  paymentModes: [],
  bankDetails: [],
  invoiceSettings: {},
  portCodes: [],
  hsnSuggestions: [],
  productCustomColumnsSuggestion: {},
  custom_column_data: {
    custom_columns_not_default: [],
    custom_columns_not_default_active: [],
    order: [],
    custom_columns: [],
    total_amount_name: 'Total Amount',
    net_amount_name: 'Net Amount',
    qty_name: 'Qty',
    is_column_linking_failed: true,
    column_id_names: [],
    failed_names: [],
  },
  price_lists: [],
  tax_rates: [],
  additional_cess_rates: [],
  product_types: [],
  batch_settings: {},
  posSettings: {},
  businessAnalytics: {},
  stateLoading: false,
  tdsLoading: false,
  tcsLoading: false,
  paymentModesLoading: false,
  hsnSuggestionLoading: false,
  invoiceSettingsLoading: false,
  posSettingsLoading: false,
  businessAnalyticsLoading: true,
  invoice_tabs: [],
  top_custom_fields: [],
  units: [],
  all_doc_counts_loading: false,
  pricing_details: {},
  pricing_details_loading: false,
  notification_count: 0,
  prefixes: [],
  barcode_settings: {},
  invoice_just_created: false,
}
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATES:
      return {
        ...state,
        states: action.payload,
        stateLoading: false,
      }
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        countryLoading: false,
      }
    case SET_API_LOADING:
      return {
        ...state,
        ...action.payload,
      }
    case SET_TDS_SECTIONS:
      return {
        ...state,
        tdsSections: action.payload,
        tdsLoading: false,
      }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case SET_CUSTOM_COLUMNS:
      let total_amount_name = ''
      let net_amount_name = ''
      let qty_name = ''
      let custom_columns = action.payload

      ;(custom_columns = custom_columns?.map(item => {
        return {
          ...item,
          is_active: item.default_key != '' ? false : item.is_active,
          show_in_pdf: item.default_key != '' ? false : item.show_in_pdf,
        }
      })),
        (custom_columns = custom_columns?.map(item => {
          if (item.default_key == 'total_amount') {
            total_amount_name = item.name
          } else if (item.default_key == 'net_amount') {
            net_amount_name = item.name
          } else if (item.default_key == 'qty') {
            qty_name = item.name
          }
          return item.default_key == 'qty'
            ? { ...item, id: 'qty' }
            : item.default_key == 'net_amount'
            ? { ...item, id: 'net_amount' }
            : item.default_key == 'total_amount'
            ? { ...item, id: 'total_amount' }
            : item
        }))
      let columns = cloneDeep(custom_columns)
      let remaining_columns = cloneDeep(custom_columns)
      let order = []
      let column_id_names = {}
      for (let i = 0; i < columns?.length; i++) {
        if (
          columns[i].field_type != 'formula' &&
          columns[i].id != 'total_amount' &&
          columns[i].id != 'net_amount'
        ) {
          order.push(String(columns[i].id))
        }
      }

      remaining_columns = remaining_columns?.filter(item => item.field_type == 'formula')

      let is_column_linking_failed = remaining_columns?.length == 0
      let failed_names = []
      if (!is_column_linking_failed) {
        while (remaining_columns?.length > 0) {
          let len = remaining_columns.length
          is_column_linking_failed = false

          for (let i = 0; i < remaining_columns.length; i++) {
            let expression = remaining_columns[i].expression
            let regx = /\{[^\}]+\}/g

            let matches = expression.match(regx)

            if (matches) {
              matches = matches.map(item => item.slice(1, -1))
            }
            matches = matches.filter(
              item =>
                !['net_amount', 'total_amount', 'unit_price', 'price_with_tax'].includes(item),
            )

            const result = matches.every(val => order.includes(val))
            if (result) {
              if (
                remaining_columns[i].id != 'net_amount' &&
                remaining_columns[i].id != 'total_amount'
              ) {
                order.push(String(remaining_columns[i].id))
              }
              remaining_columns = remaining_columns.filter(
                item => item.id != remaining_columns[i].id,
              )
            }
          }
          if (len == remaining_columns.length) {
            // failed_names = remaining_columns.map(item => item.name)
            // message.error('Error in Linking of Custom Columns: ' + names.join(', '))
            is_column_linking_failed = true
            break
          }
        }
      }

      order.push('net_amount')
      order.push('total_amount')

      columns?.map(item => {
        column_id_names[item.id] = item.name
      })
      order = order.map(item =>
        item != 'qty' && item != 'net_amount' && item != 'total_amount' ? parseInt(item) : item,
      )
      let custom_columns_not_default = custom_columns
        ? custom_columns.filter(item => item.default_key == '')
        : []
      let custom_columns_not_default_active = custom_columns_not_default.filter(
        item => item.is_active == 1,
      )
      let column_id_dict = {}
      custom_columns_not_default_active.map(item => {
        column_id_dict[item.name] = item.id
      })
      return {
        ...state,
        productCustomColumns: action.payload,
        custom_column_data: {
          order,
          custom_columns,
          total_amount_name,
          net_amount_name,
          qty_name,
          is_column_linking_failed,
          column_id_names,
          failed_names,
          custom_columns_not_default,
          custom_columns_not_default_active,
          column_id_dict,
        },
      }
    case SET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      }
    case SET_PAYMENT_MODES:
      return {
        ...state,
        paymentModes: action.payload,
        paymentModesLoading: false,
      }
    case SET_BANK_DETAILS:
      return {
        ...state,
        bankDetails: action.payload,
      }
    case SET_INVOICE_SETTINGS:
      return {
        ...state,
        invoiceSettings: action.payload,
        invoiceSettingsLoading: false,
      }

    case SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: action.payload,
      }

    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notification_count: action.payload.notification_count,
      }
    case UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        notification_count: Math.max(
          state.notification_count + action.payload.notification_count,
          0,
        ),
      }

    case SET_PORT_CODES:
      return {
        ...state,
        portCodes: action.payload,
      }
    case SET_TCS_SECTIONS:
      return {
        ...state,
        tcsSections: action.payload,
        tcsLoading: false,
      }
    case SET_CUSTOM_COLUMNS_SUGGESTION:
      return {
        ...state,
        productCustomColumnsSuggestion: action.payload,
      }
    case SET_HSN_SUGGESTION:
      return {
        ...state,
        hsnSuggestions: action.payload,
        hsnSuggestionLoading: false,
      }
    case SET_PRODUCT_OPTIONS:
      return {
        ...state,
        product_options: action.payload,
        price_lists: action.payload.price_lists,
        additional_cess_rates: action.payload.additional_cess_rates,
        tax_rates: action.payload.taxes,
        zero_tax_types: action.payload.zero_tax_types,
        // categories: action.payload.categories.filter(item => item != ''),
        company_details: action.payload.company_details,
        product_types: action.payload.product_types,
      }
    case SET_ALL_DOC_COUNTS:
      return {
        ...state,
        all_doc_counts: action.payload,
        all_doc_counts_loading: false,
      }

    case SET_BATCH_SETTINGS:
      return {
        ...state,
        batch_settings: action.payload,
      }
    case SET_POS_SETTINGS:
      return {
        ...state,
        posSettingsLoading: false,
        posSettings: action.payload,
      }
    case SET_BUSINESS_ANALYTICS:
      return {
        ...state,
        businessAnalyticsLoading: false,
        businessAnalytics: action.payload,
      }
    case ADD_INVOICES_DATA:
      return {
        ...state,
        invoice_tabs: [
          ...state.invoice_tabs.map(tab => ({
            ...tab,
            data: {
              ...tab.data,
              items: action.payload.items,
            },
          })),
          { key: action.payload.key, data: action.payload.data },
        ],
      }

    case UPDATE_INVOICES_DATA:
      return {
        ...state,
        invoice_tabs: state.invoice_tabs.map(tab =>
          tab.key === action.payload.key ? { ...tab, data: action.payload.data } : tab,
        ),
      }
    case REMOVE_INVOICES_DATA:
      return {
        ...state,
        invoice_tabs: state.invoice_tabs.filter(tab => tab.key === action.payload.key),
      }
    case REMOVE_ALL_INVOICES_DATA:
      return {
        ...state,
        invoice_tabs: [],
      }

    case SET_UNITS:
      return {
        ...state,
        units: action.payload,
      }
    case SET_TOP_CUSTOM_FIELDS:
      return {
        ...state,
        top_custom_fields: action.payload,
      }
    case SET_PRICING_PLANS:
      return {
        ...state,
        pricing_details: action.payload,
        pricing_details_loading: false,
      }
    case SET_PREFIXES:
      return {
        ...state,
        prefixes: action.payload,
      }
    case SET_BARCODE_SETTINGS:
      return {
        ...state,
        barcode_settings: action.payload,
      }
    case SET_INVOICE_JUST_CREATED:
      return {
        ...state,
        invoice_just_created: action.payload,
      }

    default:
      return state
  }
}
