import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

interface Props {
  title?: string
  content?: string
  onConfirm: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
}

export default function ExitSaveModal({
  title = 'Unsaved changes',
  content = 'You have unsaved changes. Are you sure you want to exit without saving?',
  onConfirm,
  onCancel = () => {},
  okText = 'Save and Exit',
  cancelText = 'Exit Without Saving',
}: Props) {
  const showModal = () => {
    Modal.confirm({
      title,
      content,
      icon: <ExclamationCircleOutlined />,
      okText,
      cancelText,
      onOk: onConfirm,
      onCancel,
    })
  }

  return { showModal }
}
