import { Text, View } from '@react-pdf/renderer'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../utils'
import { styles as getStyles } from './styles'

const borderColor = '#276EF1'

const InvoiceTableBlankSpace = ({ rowsCount, invoice, type }) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  //var single_col = ((1 * 100) / (9 + invoice.custom_col_names.length)).toFixed(2)
  let no_of_default_cols = invoice.invoice_settings.hide_qty ? 9 : 10
  let no_of_default_cols2 = invoice.invoice_settings.hide_qty ? 5 : 6

  const hide_hsn = invoice.invoice_settings.hide_hsn
  no_of_default_cols = hide_hsn ? no_of_default_cols - 0.85 : no_of_default_cols

  //discount column
  const show_discount_col =
    invoice?.invoice_settings?.hide_discount === 0 &&
    invoice?.invoice_settings?.show_discount_col === 1
  no_of_default_cols = show_discount_col ? no_of_default_cols + 1 : no_of_default_cols
  no_of_default_cols2 = show_discount_col ? no_of_default_cols2 + 1 : no_of_default_cols2

  var single_col
  if (invoice.tax_type == 'CGST') {
    single_col = ((1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)).toFixed(2)
  } else {
    single_col = ((1 * 100) / (no_of_default_cols - 1 + invoice.custom_col_names.length)).toFixed(2)
  }

  no_of_default_cols2 = hide_hsn ? no_of_default_cols2 - 0.85 : no_of_default_cols2

  var dc_col =
    (1 * 100) / ((invoice.invoice_settings.hide_qty ? 2 : 3) + invoice.custom_col_names.length)

  var single_col2 = (1 * 100) / (no_of_default_cols2 + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 9)) / (10 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (9 * 15)) / (16 + invoice.custom_col_names.length)
  const blankRows = invoice.invoice_settings.full_page
    ? Array(1).fill(0)
    : rowsCount > 0
    ? Array(rowsCount).fill(0)
    : []
  const rows = blankRows.map((x, i) => (
    <Fragment key={i}>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View
            style={[
              styles.invoiceTableBlankSpace_row,
              invoice.invoice_settings.full_page == true && { flex: 1 },
            ]}
          >
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'left',
                paddingLeft: 4.5,
                width: `${0.2 * dc_col}%`,
              }}
            ></Text>
            <Text
              style={{
                ...styles.invoiceTableBlankSpace_cell,
                textAlign: 'left',
                width: `${1.8 * dc_col}%`,
              }}
            ></Text>
            {invoice.custom_col_names.map((item, index) => (
              <Text
                key={index}
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${dc_col}%`,
                }}
              ></Text>
            ))}
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  width: `${dc_col}%`,
                  borderRight: 'none',
                }}
              ></Text>
            )}
          </View>
        </>
      )}
      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View
              style={[
                styles.invoiceTableBlankSpace_row,
                invoice.invoice_settings.full_page == true && { flex: 1 },
              ]}
            >
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${0.2 * single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.95 * single_col2}%`,
                }}
              >
                {'  '}
              </Text>

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.85 * single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              ))}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${single_col2}%`,
                }}
              >
                {'  '}
              </Text>
              {show_discount_col && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col2}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  borderRight: 'none',
                  width: `${single_col2}%`,
                }}
              >
                {'  '}
              </Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View
              style={[
                styles.invoiceTableBlankSpace_row,
                invoice.invoice_settings.full_page == true && { flex: 1 },
              ]}
            >
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  width: `${0.4 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'left',
                  width: `${2.6 * single_col}%`,
                }}
              >
                {'  '}
              </Text>

              {invoice.custom_col_names.map((item, index) => (
                <Text
                  key={index}
                  style={{ ...styles.invoiceTableBlankSpace_cell, width: `${single_col}%` }}
                >
                  {'  '}
                </Text>
              ))}

              {!hide_hsn && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.85 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.15 * single_col}%`,
                }}
              >
                {'  '}
              </Text>
              {show_discount_col && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              {invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    textAlign: 'right',
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${single_col}%`,
                }}
              >
                {'  '}
              </Text>

              {/* <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  width: `${1.1 * single_col}%`,
                }}
              >
                {'  '}
              </Text> */}
              {invoice.tax_type == 'CGST' ? (
                <>
                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${1.05 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                  <Text
                    style={{
                      ...styles.invoiceTableBlankSpace_cell,
                      textAlign: 'right',
                      fontSize: font_size,
                      width: `${1.05 * single_col}%`,
                    }}
                  >
                    {'  '}
                  </Text>
                </>
              ) : (
                <Text
                  style={{
                    ...styles.invoiceTableBlankSpace_cell,
                    fontSize: font_size,
                    textAlign: 'right',
                    width: `${1.1 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}
              <Text
                style={{
                  ...styles.invoiceTableBlankSpace_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  borderRight: 'none',
                  width: `${single_col}%`,
                }}
              >
                {'  '}
              </Text>
            </View>
          )}
        </>
      )}
    </Fragment>
  ))
  return (
    <Fragment>
      <View
        style={[
          // invoice.invoice_settings.full_page == false && styles.globalContainer,
          invoice.invoice_settings.full_page == true && styles.invoiceTableBlankSpace_container,
        ]}
      >
        {rows}
      </View>
    </Fragment>
  )
}

export default InvoiceTableBlankSpace
